import { useContext } from 'react';
import { NmckHistoryContext } from '@/widgets/NmckHistory/context/NmckHistoryContext';

export const useNmckHistoryContext = () => {
  const context = useContext(NmckHistoryContext);

  if (!context) {
    throw new Error('NmckHistoryContext must be used within a NmckHistoryProvider');
  }

  return context;
};
