import { CharacteristicForFilter } from '@/api/okpdApi/okpdApiTypes';
import {
  Characteristic,
  ContractItemConsumer,
  ContractItemKtru,
  ContractItemOkpd,
  ContractItemSupplier,
} from '@/components/NmckGrid/NmckGridContracts/components/types';
import { TokenBasedPageableResponse } from '@/api/commonApi/commonApiTypes';

export enum VariationColor {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum NmckType {
  MARKET = 'MARKET',
  DRUGS = 'DRUGS',
  GUARD = 'SECURITY',
  MED_DEVICES = 'MED_DEVICES',
}

export enum CalculationPrecision {
  HIGH = 'HIGH',
  CENTS = 'CENTS',
}

export enum CalculationMethod {
  MIN = 'MIN',
  AVG = 'AVG',
  WEIGHED_AVG = 'WEIGHED_AVG',
}

export enum PropositionType {
  KP = 'KP',
  EIS = 'EIS',
}

export enum BadgeType {
  NO_BADGE = 'NO_BADGE',
  HAS_RESTRICTIONS = 'HAS_RESTRICTIONS',
  HAS_INCOMPATIBLE_CODES = 'HAS_INCOMPATIBLE_CODES',
}

export type NmckByIdResponse = {
  id: number;
  versionNumber: number;
  type: NmckType;
  created: string;
  modified: string;
  nmckTotal: number;
  name: string;
  generatedName: string;
  responsible: string;
  rows: NmckRow[];
  calculationPrecision: CalculationPrecision | null;
  calculationMethod: CalculationMethod;
  badge: BadgeType;
  recurrencyBadge: string | null;
  recurrency?: number;
};

export type NmckRow = {
  id: number;
  name: string;
  code: string | null;
  codeError: string | null;
  propositions: Proposition[];
  unit: string;
  unitCode?: number;
  variation: number;
  variationColor: VariationColor;
  amount: number;
  averagePrice: number;
  minPrice: number;
  nds: number;
  nmck: number;
  nkmiId: number | null;
};

export type Proposition = {
  columnNumber: number;
  type: PropositionType;
  price: number | null;
  description: string | null;
  contractId: number | null;
  contractNumber: string | null;
  forAllRows: boolean;
  warning: string | null;
};

export type UnitsMeasureResponse = UnitsMeasure[];
export type UnitsMeasure = {
  name: string;
  unit: number;
};
export type TreeSelectDataResponse = TreeSelectOption[];

export type TreeSelectOption = {
  id: number;
  name: string;
  children?: TreeSelectOption[];
};

export type NmckHistoryResponse = {
  results: NmckCommon[];
  count: number;
};

export type NmckCommon = {
  id: number;
  type: NmckType;
  created: string;
  modified: string;
  nmckTotal: number;
  name: string;
  generatedName: string;
  responsible: string;
  recurrencyBadge?: string;
  recurrency?: number;
};

export type ContractItemsResponse = TokenBasedPageableResponse<ContractItem> & {
  characteristics: CharacteristicForFilter[];
};

export type Contract = {
  reestrNumber: string;
  price: number;
  updateDate: string;
  conclusionDate: string;
  executionDate: string;
  purchaseMethod?: TreeSelectOption;
  supplier?: ContractItemSupplier;
  consumer?: ContractItemConsumer;
};

export type ContractItem = {
  eisId: number;
  name: string;
  idWithinContract: number;
  sumPrice: number;
  nds: number;
  smp: boolean;
  unitPrice: number;
  priceWithAppliedKoeff: number;
  count: number;
  unitMeasure: string;
  contract: Contract;
  ktru?: ContractItemKtru;
  maxIncreasePricePeriodRatioCoeff: number;
  maxIncreasePriceRatioCoeff: number;
  okpd?: ContractItemOkpd;
  characteristics: Characteristic[];
};

export type UpdatePropositionDTO = Pick<
  Proposition,
  'type' | 'description' | 'forAllRows' | 'price' | 'contractId'
>;
