'use client';

import { memo, useEffect } from 'react';
import { NmckGridProvider } from '@/components/NmckGrid/context/NmckGridContext';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { NmckAnalysisPageContent } from '@/widgets/NmckAnalysis/NmckAnalysisPageContent';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckAnalysisPage = memo(({ nmckId }: Props) => {
  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.NMCK_OPEN,
    });
  }, []);

  return (
    <NmckGridProvider>
      <NmckAnalysisPageContent nmckId={nmckId} />
    </NmckGridProvider>
  );
});

NmckAnalysisPage.displayName = 'NmckAnalysisPage';
