import { memo, useCallback, useState } from 'react';
import { ExportFileProps } from '@/components/ExportFile/ExportFile.types';
import { Button, Flex, Select, SelectProps } from 'antd';
import styles from './ExportFile.module.scss';
import Export from 'public/icons/export.svg';
import VIcon from 'public/icons/v.svg';
import { DEFAULT_EXPORT_FILE_SELECT_OPTIONS } from '@/components/ExportFile/ExportFile.constants';
import { DownloadFileExtension } from '@/api/commonApi/commonApiTypes';

export const ExportFile = memo(
  ({
    onExportButtonClick,
    buttonText = 'Сформировать отчёт',
    selectLabel = 'Тип файла',
    selectOptions = DEFAULT_EXPORT_FILE_SELECT_OPTIONS,
    allowFileTypeChange = false,
    flexGap = 10,
  }: ExportFileProps) => {
    const [isReportButtonLoading, setIsReportButtonLoading] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState<DownloadFileExtension>(
      selectOptions[0].value,
    );

    const handleFileTypeChange = useCallback((value: DownloadFileExtension) => {
      setSelectedFileType(value);
    }, []);

    const handleExportButtonClick = useCallback(async () => {
      setIsReportButtonLoading(true);

      try {
        await onExportButtonClick(selectedFileType);
      } catch (err) {
        console.error(err);
      } finally {
        setIsReportButtonLoading(false);
      }
    }, [onExportButtonClick, selectedFileType]);

    const labelRender = useCallback<Required<SelectProps>['labelRender']>(
      ({ label }) => (
        <Flex
          align="center"
          gap={4}
        >
          <div className={styles.text}>{selectLabel}:</div>
          <div>{label}</div>
        </Flex>
      ),
      [selectLabel],
    );

    return (
      <Flex
        align="center"
        gap={flexGap}
      >
        <Select<DownloadFileExtension>
          labelRender={labelRender}
          value={selectedFileType}
          options={selectOptions}
          variant="borderless"
          size="small"
          open={allowFileTypeChange ? undefined : false}
          suffixIcon={allowFileTypeChange ? <VIcon className="v-icon" /> : null}
          onChange={handleFileTypeChange}
        />
        <Button
          type="primary"
          icon={<Export />}
          className="button-with-icon"
          loading={isReportButtonLoading}
          onClick={handleExportButtonClick}
        >
          {buttonText}
        </Button>
      </Flex>
    );
  },
);

ExportFile.displayName = 'ExportFile';
