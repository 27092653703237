import { useContext } from 'react';
import { DocsActionsContext } from '@/widgets/Docs/context/DocsContext';

export const useDocsActionsContext = () => {
  const context = useContext(DocsActionsContext);

  if (!context) {
    throw new Error('DocsActionsContext must be used within a DocsContextProvider');
  }

  return context;
};
