import { Tabs, TabsProps } from 'antd';
import { OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import styles from './OkpdDetailTabs.module.scss';
import { Badge } from '@/components';
import { memo, useMemo } from 'react';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { getNPACount } from '@/components/OkpdKtruDetail/utils';
import { OkpdKtruDetailTabsKtruList } from '@/components/OkpdKtruDetail/OkpdKtruDetailTabsKtruList/OkpdKtruDetailTabsKtruList';
import { OkpdKtruDetailNPAList } from '@/components/OkpdKtruDetail/OkpdKtruDetailNPAList/OkpdKtruDetailNPAList';
import { OkpdKtruSuppliers } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruDetailSuppliers';
import { OkpdKtruSuppliersProvider } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/OkpdKtruSuppliersContext';

type Props = {
  okpd: OkpdDetail;
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

const getTabs = (
  okpd: OkpdDetail,
  code: string,
  setModalPageParams?: SetOkpdKtruModalPageParamsType,
): TabsProps['items'] => {
  const npaCount = getNPACount(okpd.npa);

  return [
    {
      key: '1',
      label: 'Запреты и ограничения',
      icon: (
        <Badge
          count={npaCount}
          className={styles.badge}
        />
      ),
      disabled: !npaCount,
      children: (
        <OkpdKtruDetailNPAList
          npa={okpd.npa}
          okpdCode={okpd.code}
          okpdName={okpd.name}
        />
      ),
    },
    {
      key: '2',
      label: 'Позиции КТРУ',
      icon: (
        <Badge
          count={okpd.ktruCount}
          className={styles.badge}
        />
      ),
      disabled: !okpd.ktruCount,
      children: (
        <OkpdKtruDetailTabsKtruList
          code={code}
          setModalPageParams={setModalPageParams}
        />
      ),
    },
    {
      key: '3',
      label: 'Поиск поставщиков по коду',
      children: (
        <OkpdKtruSuppliersProvider okpdCode={code}>
          <OkpdKtruSuppliers
            okpdCode={code}
            okpdKtruDetailData={okpd}
          />
        </OkpdKtruSuppliersProvider>
      ),
    },
  ];
};

export const OkpdDetailTabs = memo(({ okpd, code, setModalPageParams }: Props) => {
  const tabs = useMemo(
    () => getTabs(okpd, code, setModalPageParams),
    [code, okpd, setModalPageParams],
  );
  const activeTab = useMemo(() => tabs!.find((item) => !item.disabled)!.key, [tabs]);

  return (
    <div className={styles.wrapper}>
      <Tabs
        items={tabs}
        className={styles.tabs}
        defaultActiveKey={activeTab}
      />
    </div>
  );
});

OkpdDetailTabs.displayName = 'OkpdDetailTabs';
