import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterPrice.module.scss';
import { Flex } from 'antd';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';

export const ContractsFilterPrice = memo(() => {
  const {
    filter: { priceFrom, priceUpTo },
  } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handlePriceFromChange = useCallback(
    (value: DebounceInputNumberValueType) => {
      setFilter((prev) => ({ ...prev, priceFrom: value }));
    },
    [setFilter],
  );

  const handlePriceToChange = useCallback(
    (value: DebounceInputNumberValueType) => {
      setFilter((prev) => ({ ...prev, priceUpTo: value }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Цена, ₽"
      field={
        <Flex gap={16}>
          <DebounceInputNumber
            min={0}
            debounceMs={800}
            value={priceFrom}
            placeholder="Цена, от"
            className={styles.input}
            onDebouncedChange={handlePriceFromChange}
          />

          <DebounceInputNumber
            min={0}
            debounceMs={800}
            value={priceUpTo}
            placeholder="Цена, до"
            className={styles.input}
            onDebouncedChange={handlePriceToChange}
          />
        </Flex>
      }
    />
  );
});

ContractsFilterPrice.displayName = 'ContractsFilterPrice';
