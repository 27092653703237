import { useState, useEffect } from 'react';
import { isClientRender } from '@/utils/isClientRender';

const breakpoints = {
  sm: 1280,
  md: 1440,
  lg: 1600,
  xl: 1920,
};

export const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState({
    maxSM: isClientRender() && window.innerWidth <= breakpoints.sm,
    maxMD: isClientRender() && window.innerWidth <= breakpoints.md,
    maxLG: isClientRender() && window.innerWidth <= breakpoints.lg,
    maxXL: isClientRender() && window.innerWidth <= breakpoints.xl,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        maxSM: window.innerWidth <= breakpoints.sm,
        maxMD: window.innerWidth <= breakpoints.md,
        maxLG: window.innerWidth <= breakpoints.lg,
        maxXL: window.innerWidth <= breakpoints.xl,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};
