import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import styles from './KtruDetailTabsCharacteristicsRowValuesGroup.module.scss';
import { Button, Checkbox, Flex, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { KtruCharacteristicSelectionType } from '@/api/okpdApi/okpdApiTypes';

type Props = {
  row: KtruCharacteristicRow;
  setList: Dispatch<SetStateAction<KtruCharacteristicRow[]>>;
};

export const KtruDetailTabsCharacteristicsRowValuesGroup = memo(({ row, setList }: Props) => {
  const isSingleSelection = row.selection === KtruCharacteristicSelectionType.SINGLE;

  const [isExpand, setIsExpand] = useState(false);

  const values = useMemo(
    () => (row.values.length <= 4 || isExpand ? row.values : row.values.slice(0, 4)),
    [isExpand, row.values],
  );
  const labelToCheckedChangeHandlerMap = useMemo(
    () =>
      values.reduce(
        (acc, { id }) => ({
          ...acc,
          [id]: (e: CheckboxChangeEvent) => {
            sendEventGTM(eventsGTM.checkedCharacteristic, { value: id });

            setList((prev) =>
              prev.map((item) => {
                if (item.name === row.name) {
                  if (isSingleSelection) {
                    return {
                      ...item,
                      values: item.values.map((value) =>
                        value.id === id
                          ? { ...value, checked: e.target.checked }
                          : { ...value, checked: false },
                      ),
                    };
                  }
                  return {
                    ...item,
                    values: item.values.map((value) =>
                      value.id === id ? { ...value, checked: e.target.checked } : value,
                    ),
                  };
                }

                return item;
              }),
            );
          },
        }),
        {} as Record<string, (e: CheckboxChangeEvent) => void>,
      ),
    [isSingleSelection, row.name, setList, values],
  );
  const renderCheckboxes = useMemo(
    () =>
      values.map(({ label, checked, id }) => (
        <Checkbox
          key={id}
          checked={checked}
          onChange={labelToCheckedChangeHandlerMap[id]}
          disabled={row.selection === KtruCharacteristicSelectionType.ALWAYS_SELECTED}
        >
          {label}
        </Checkbox>
      )),
    [labelToCheckedChangeHandlerMap, row, values],
  );
  const renderRadioButtons = useMemo(() => {
    return values.map(({ id, label, checked }) => (
      <Radio
        key={id}
        checked={checked}
        onChange={labelToCheckedChangeHandlerMap[id]}
      >
        {label}
      </Radio>
    ));
  }, [labelToCheckedChangeHandlerMap, values]);

  const handleAllButtonClick = useCallback(() => setIsExpand(true), []);

  return (
    <Flex
      wrap
      className={styles.values}
    >
      {isSingleSelection ? renderRadioButtons : renderCheckboxes}
      {row.values.length !== values.length && (
        <Button
          type="link"
          size="small"
          onClick={handleAllButtonClick}
          className="button-dashed"
        >
          Все
        </Button>
      )}
    </Flex>
  );
});

KtruDetailTabsCharacteristicsRowValuesGroup.displayName =
  'KtruDetailTabsCharacteristicsRowValuesGroup';
