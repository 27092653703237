import { memo, useCallback, useMemo, useState } from 'react';
import { NPAItem, NPAType } from '@/api/okpdApi/okpdApiTypes';
import { NPACard } from '@/widgets/OkpdKtru/components';
import styles from './OkpdKtruDetailNPACard.module.scss';
import { Button, Flex, Tooltip, TooltipProps } from 'antd';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';
import { OkpdKtruDetailNPAModal } from '@/components/OkpdKtruDetail/OkpdKtruDetailNPAModal/OkpdKtruDetailNPAModal';
import { deleteTags } from '@/utils/deleteTags';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { NPAChips } from '@/widgets/OkpdKtru/components/NPACard/components/NPAChips/NPAChips';

type OkpdDetailTabsNPACardProps = {
  npa: NPAItem[];
  npaType: NPAType;
  okpdCode: string;
  okpdName: string;
};

const getCroppedNote = (note: string) => (note.length > 85 ? note.slice(0, 85) + '...' : note);
const tooltipProps: TooltipProps = {
  overlayInnerStyle: { maxHeight: '70vh', overflowY: 'auto' },
};

export const OkpdKtruDetailNPACard = memo(
  ({ npa, npaType, okpdName, okpdCode }: OkpdDetailTabsNPACardProps) => {
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [modalData, setModalData] = useState<NPAItem>();

    const applicableNPAList = useMemo(
      () => npa.filter(({ applicable }: NPAItem) => applicable),
      [npa],
    );

    const handleOpenModal = useCallback((npaItem: NPAItem) => {
      sendEventGTM(eventsGTM.npaModalOpen, { value: npaItem.code });
      setIsModalOpened(true);
      setModalData(npaItem);
    }, []);

    const handleCloseModal = useCallback(() => {
      setIsModalOpened(false);
    }, []);

    return (
      <Flex
        gap={4}
        vertical
        className={styles.wrapper}
      >
        <div className={styles.card}>
          <NPACard
            npaItems={npa}
            npaType={npaType}
          />
        </div>
        {applicableNPAList.map((item) => (
          <Flex
            key={item.code}
            vertical
            gap={8}
            className={styles.codeCard}
          >
            <Flex
              align="center"
              justify="space-between"
            >
              <NPAChips npa={item} />
            </Flex>
            {item.caption && <div className={styles.title}>{item.caption}</div>}
            {item.note && (
              <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: item.note }} />}
                placement="right"
                {...tooltipProps}
              >
                <div className={styles.note}>{getCroppedNote(deleteTags(item.note))}</div>
              </Tooltip>
            )}
            <Flex
              align="center"
              gap={32}
            >
              <Button
                type="link"
                className={styles.button}
                onClick={() => handleOpenModal(item)}
              >
                Подробнее
              </Button>
              {item.garantLink && (
                <Button
                  icon={<ExportDiagonalIcon />}
                  type="link"
                  className={styles.button}
                  href={item.garantLink}
                  target="_blank"
                >
                  Открыть НПА
                </Button>
              )}
            </Flex>
          </Flex>
        ))}
        <OkpdKtruDetailNPAModal
          isOpen={isModalOpened}
          okpdCode={okpdCode}
          okpdName={okpdName}
          npa={modalData}
          onClose={handleCloseModal}
        />
      </Flex>
    );
  },
);

OkpdKtruDetailNPACard.displayName = 'OkpdKtruDetailNPACard';
