'use client';

import { memo, useCallback, useState } from 'react';
import { OldVersionReportButton } from '@/features/OldVersionReport/components/OldVersionReportButton/OldVersionReportButton';
import { OldVersionReportModal } from '@/features/OldVersionReport/components/OldVersionReportModal/OldVersionReportModal';
import { useGetUserInfo } from '@/api/userApi/userApi';
import { Flex } from 'antd';
import { redirectToOldVersion } from '@/features/OldVersionReport/OldVersionReport.utils';

export const OldVersionReport = memo(() => {
  const { data } = useGetUserInfo();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (data?.oldVersionTransitionFeedbackRequired) {
      setIsModalOpen(true);
    } else {
      redirectToOldVersion();
    }
  }, [data?.oldVersionTransitionFeedbackRequired]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    !!data?.oldVersionAvailable && (
      <Flex vertical>
        <OldVersionReportButton onClick={handleButtonClick} />
        <OldVersionReportModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      </Flex>
    )
  );
});

OldVersionReport.displayName = 'OldVersionReport';
