import CopyIcon from 'public/icons/copy.svg';
import styles from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/OkpdKtruSuppliersTable.module.scss';

export const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <CopyIcon
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};
