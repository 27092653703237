import { memo } from 'react';
import { DeadlineCalculation } from '@/api/skpApi/skpApiTypes';
import styles from './PrintContent.module.scss';
import { formatDate } from '@/utils';

type PrintContentProps = {
  currentCalculation: DeadlineCalculation;
};

export const PrintContent = memo(({ currentCalculation }: PrintContentProps) => {
  return (
    <div className={styles.printContent}>
      <h3 className={styles.printTitle}>{currentCalculation.name}</h3>

      <table className={styles.printTable}>
        <thead>
          <tr>
            <th className={styles.printTableHeader}>
              <div className={styles.stage}>Этап</div>
            </th>
            <th className={styles.printTableHeader}>
              <div className={styles.date}>Дата</div>
            </th>
            <th className={styles.printTableHeader}>Норма закона</th>
          </tr>
        </thead>

        <tbody>
          {currentCalculation.stages.map(
            (stage) =>
              stage.visibility && (
                <tr
                  key={stage.id}
                  className={styles.printTableRow}
                >
                  <td className={styles.printTableCell}>{stage.name}</td>
                  <td className={styles.printTableCell}>
                    {stage.date.date ? formatDate(stage.date.date) : ''}
                  </td>
                  <td className={styles.printTableCell}>{stage.law}</td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  );
});
PrintContent.displayName = 'PrintContent';
