'use client';

import { memo, useEffect, useMemo, useState } from 'react';
import { Flex, Tabs, TabsProps } from 'antd';
import moment from 'moment';
import {
  ContractsTable,
  PlannedTable,
  ProcessTable,
  TabTitle,
} from '@/widgets/CurrentPage/components';
import {
  useGetContractsExecution,
  useGetPurchasePlanItems,
  useGetPurchasesInProcess,
} from '@/api/currentApi/currentApi';

import styles from './CurrentPage.module.scss';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

export const CurrentPage = memo(() => {
  const [activeTab, setActiveTab] = useState('1');

  const { data: plannedItems } = useGetPurchasePlanItems();
  const { data: processItems } = useGetPurchasesInProcess();
  const { data: contractItems } = useGetContractsExecution();

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_OPEN,
    });
  }, []);

  const tabsItems = useMemo((): TabsProps['items'] => {
    return [
      {
        key: '1',
        label: (
          <TabTitle
            title={`Запланированые до конца ${moment().year()}`}
            count={plannedItems?.count}
          />
        ),
      },
      {
        key: '2',
        label: (
          <TabTitle
            title="Проводятся"
            count={processItems?.count}
          />
        ),
      },
      {
        key: '3',
        label: (
          <TabTitle
            title="Контракты на исполнении"
            count={contractItems?.count}
          />
        ),
      },
    ];
  }, [contractItems?.count, plannedItems?.count, processItems?.count]);

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={64}
        align="center"
        className={styles.header}
      >
        <div className={styles.title}>Текущие закупки</div>
        <Tabs
          activeKey={activeTab}
          items={tabsItems}
          onChange={setActiveTab}
          className={styles.tab}
        />
      </Flex>
      <div className={styles.main}>
        <>
          {activeTab === '1' && <PlannedTable />}
          {activeTab === '2' && <ProcessTable />}
          {activeTab === '3' && <ContractsTable />}
        </>
      </div>
    </div>
  );
});

CurrentPage.displayName = 'CurrentPage';
