import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { DocsFilter } from '@/widgets/Docs/DocsPage.types';
import { SWRResponse } from 'swr';
import { ContractDocumentsResponse } from '@/api/docsApi/docsApiTypes';
import { useGetContractDocuments } from '@/api/docsApi/docsApi';
import { useSearchParams } from 'next/navigation';

type DocsContextType = {
  filter: DocsFilter;
  docsResponse: SWRResponse<ContractDocumentsResponse>;
};

type DocsActionsContextType = {
  setFilter: Dispatch<SetStateAction<DocsFilter>>;
};

export const DocsContext = createContext<DocsContextType | null>(null);
export const DocsActionsContext = createContext<DocsActionsContextType | null>(null);

export const DocsProvider = ({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams();

  const [filter, setFilter] = useState<DocsFilter>({
    search: searchParams.get('code') ?? '',
    publishedFromDate: undefined,
    publishedToDate: undefined,
    regionIds: [],
    purchaseMethodIds: [],
  });

  const docsResponse = useGetContractDocuments({ filter });

  const values = useMemo(() => ({ filter, docsResponse }), [docsResponse, filter]);
  const actions = useMemo(() => ({ setFilter }), [setFilter]);

  return (
    <DocsContext.Provider value={values}>
      <DocsActionsContext.Provider value={actions}>{children}</DocsActionsContext.Provider>
    </DocsContext.Provider>
  );
};
