import { memo, useEffect } from 'react';
import { Table } from 'antd';
import styles from './ProcessTable.module.scss';
import { useGetPurchasesInProcess } from '@/api/currentApi/currentApi';
import { currentProcessTableColumns } from '@/widgets/CurrentPage/components/ProcessTable/ProcessTable.contants';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

export const ProcessTable = memo(() => {
  const { data, isLoading } = useGetPurchasesInProcess();

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_IN_PROGRESS,
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey="regNum"
        loading={isLoading}
        columns={currentProcessTableColumns}
        dataSource={data?.results}
        pagination={false}
      />
    </div>
  );
});

ProcessTable.displayName = 'ProcessTable';
