import GPBLogo from '/public/images/gpbLogo.svg';
import ROSLogoLight from '/public/images/rosLogoLight.svg';
import PIRSONLogo from '/public/images/pirsonLogo.svg';
import { Navigation } from '@/components/Navigation/Navigation';
import styles from './Header.module.scss';
import { VendorType } from '@/widgets/Header/types';
import cx from 'classnames';
import { Divider, Flex } from 'antd';
import { layoutHeaderId } from '@/components/Navigation/constants';
import { BugReport, OldVersionReport } from '@/features';

type Props = {
  vendor: VendorType;
};

export const Header = ({ vendor }: Props) => {
  const isGPB = vendor === VendorType.GPB;

  const LogoImage = {
    [VendorType.GPB]: GPBLogo,
    [VendorType.ROS]: ROSLogoLight,
    [VendorType.PIRSON]: PIRSONLogo,
  }[vendor];

  const headerWrapperClassName = {
    [VendorType.GPB]: styles.headerGPB,
    [VendorType.ROS]: styles.headerPIRSON,
    [VendorType.PIRSON]: styles.headerPIRSON,
  }[vendor];

  return (
    <div
      className={styles.wrapper}
      id={layoutHeaderId}
    >
      <div className={cx(styles.header, headerWrapperClassName)}>
        {LogoImage && <LogoImage className={styles.logo} />}
        {isGPB && (
          <Divider
            type="vertical"
            className={styles.divider}
          />
        )}
        <Navigation
          className={styles.navigation}
          vendor={vendor}
        />
        <Flex
          vertical
          className={styles.actionsWrapper}
          gap={8}
        >
          <OldVersionReport />
          <BugReport />
        </Flex>
      </div>
    </div>
  );
};
