'use client';

import { memo } from 'react';
import { DocsHeader } from '@/widgets/Docs/components/DocsHeader/DocsHeader';
import { DocsTable } from '@/widgets/Docs/components/DocsTable/DocsTable';

export const DocsPage = memo(() => {
  return (
    <>
      <DocsHeader />
      <DocsTable />
    </>
  );
});

DocsPage.displayName = 'DocsPage';
