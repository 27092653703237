'use client';

import { memo, useCallback } from 'react';
import { KtruInfosProps } from '@/features/KtruInfos/KtruInfos.types';
import styles from './KtruInfos.module.scss';
import { Flex } from 'antd';
import { KtruInfosLineItem } from '@/features/KtruInfos/components/KtruInfosLineItem/KtruInfosLineItem';
import cx from 'classnames';
import { KtruInfo } from '@/api/okpdApi/okpdApiTypes';

export const KtruInfos = memo(({ infos, inCard, setModalPageParams }: KtruInfosProps) => {
  const renderInTab = useCallback(
    (info: KtruInfo) => (
      <Flex
        vertical
        gap={4}
      >
        {info.lines.map((line, index) => (
          <span key={index}>
            {line.lineItems.map((lineItem, index) => (
              <KtruInfosLineItem
                key={index}
                lineItem={lineItem}
                setModalPageParams={setModalPageParams}
              />
            ))}
          </span>
        ))}
      </Flex>
    ),
    [setModalPageParams],
  );

  const renderInCard = useCallback(
    (info: KtruInfo) => (
      <span>
        {info.lines.map((line, lineIndex) => (
          <span key={lineIndex}>
            {line.lineItems.map((lineItem, lineItemIndex) => (
              <span key={lineItemIndex}>
                <KtruInfosLineItem
                  lineItem={lineItem}
                  setModalPageParams={setModalPageParams}
                />
              </span>
            ))}
            {lineIndex !== info.lines.length - 1 && '; '}
          </span>
        ))}
      </span>
    ),
    [setModalPageParams],
  );

  return (
    <Flex
      vertical
      gap={8}
      className={cx({
        [styles.wrapper]: !inCard,
        [styles.wrapperInCard]: inCard,
      })}
    >
      {infos.map((info) => (
        <Flex
          key={info.name}
          gap={8}
          className={cx(inCard && styles.row)}
        >
          <div className={styles.label}>{info.name}:&nbsp;</div>
          {inCard && renderInCard(info)}
          {!inCard && renderInTab(info)}
        </Flex>
      ))}
    </Flex>
  );
});

KtruInfos.displayName = 'KtruInfos';
