import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import styles from './KtruDetailTabsCharacteristicsRow.module.scss';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import { Button, Flex } from 'antd';
import commonStyles from '../KtruDetailTabsCharacteristics.module.scss';
import cx from 'classnames';
import CopyIcon from '/public/icons/copy.svg';
import { copyKtruCharacteristics } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/utils';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { KtruDetailTabsCharacteristicsRowValuesGroup } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/KtruDetailTabsCharacteristicsRow/components/KtruDetailTabsCharacteristicsRowValuesGroup/KtruDetailTabsCharacteristicsRowValuesGroup';

type Props = {
  row: KtruCharacteristicRow;
  setList: Dispatch<SetStateAction<KtruCharacteristicRow[]>>;
  setActiveTab: (tab: string) => void;
  ktruListTabDisabled: boolean;
};

export const KtruDetailTabsCharacteristicsRow = memo(
  ({ row, setList, setActiveTab, ktruListTabDisabled }: Props) => {
    const isCopyButtonDisabled = useMemo(() => row.values.every(({ checked }) => !checked), [row]);

    const handleSelectByValueClick = useCallback(() => setActiveTab('3'), [setActiveTab]);

    const copyRow = useCallback(() => {
      sendEventGTM(eventsGTM.copyCharacteristicsRow);
      copyKtruCharacteristics(row, true);
    }, [row]);

    return (
      <div className={styles.row}>
        <div className={cx(styles.columns, commonStyles.columnGrid)}>
          <div className={styles.column}>
            <span className={styles.bold}>{row.name}</span>
          </div>
          <div className={styles.column}>
            <span className={styles.gray}>{row.kind}</span>
          </div>
          <div className={styles.column}>
            <span className={styles.gray}>{row.type}</span>
          </div>
          <div className={styles.column}>
            <KtruDetailTabsCharacteristicsRowValuesGroup
              row={row}
              setList={setList}
            />
          </div>
        </div>
        <Flex
          align="center"
          gap={30}
        >
          <Button
            type="link"
            className="button-dashed"
            size="small"
            onClick={handleSelectByValueClick}
            disabled={ktruListTabDisabled}
          >
            Подобрать по значению
          </Button>
          <Button
            type="link"
            className="button-with-icon"
            size="small"
            icon={<CopyIcon />}
            disabled={isCopyButtonDisabled}
            onClick={copyRow}
          />
        </Flex>
      </div>
    );
  },
);

KtruDetailTabsCharacteristicsRow.displayName = 'KtruDetailTabsCharacteristicsRow';
