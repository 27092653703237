import { Moment } from 'moment/moment';
import { NmckContractsCountry } from '@/components/NmckGrid/NmckGridContracts/types';

export type MailBodyParams = {
  inn: string;
  kpp: string;
  orgShortName: string;
  code: string;
  name: string;
  unit: string;
  count: number;
};

export enum SuppliersSourceEnum {
  GISP = 'GISP',
  EIS = 'EIS',
}

export type SuppliersFilter = {
  source: SuppliersSourceEnum;
  region: number[];
  contractDateFrom?: Moment;
  contractDateTo?: Moment;
  smp: boolean;
  hasPenalty: boolean;
  country?: NmckContractsCountry;
  okpdCode?: string;
  ktruCode?: string;
};

export type SuppliersRequestParams = {
  filter: SuppliersFilter;
  pageSize: number;
};
