import { memo } from 'react';
import styles from './NmckHistoryHeader.module.scss';
import { Flex } from 'antd';
import { DebounceInput } from '@/components';
import cx from 'classnames';
import { useNmckHistoryActionsContext } from '@/widgets/NmckHistory/context/useNmckHistoryActionsContext';

export const NmckHistoryHeader = memo(() => {
  const { setSearch } = useNmckHistoryActionsContext();

  return (
    <div className={styles.header}>
      <Flex
        align="center"
        gap={32}
      >
        <h1 className={styles.title}>История расчётов НМЦК</h1>
        <DebounceInput
          className={cx(styles.searchInput, 'input-line')}
          placeholder="Поиск расчёта"
          variant="borderless"
          onDebouncedChange={setSearch}
        />
      </Flex>
    </div>
  );
});

NmckHistoryHeader.displayName = 'NmckHistoryHeader';
