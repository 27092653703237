import { downloadFile, fetcher, postData } from '../commonApi/commonApi';
import { SmpReport, SmpReportEdited } from './smpReportApiTypes';

export const calculateReport = async (
  year: string,
  excludeMed: boolean,
  body: SmpReportEdited,
): Promise<SmpReport> => {
  const params = new URLSearchParams();
  params.append('year', year);
  params.append('excludeMed', String(excludeMed));

  return postData(`/eis-smp-report?${params}`, body);
};

export const getReportXlsx = async () => {
  const response = await fetcher('/eis-smp-report/xlsx');
  await downloadFile(response, 'Отчёт СМП');
};
