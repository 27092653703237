import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  SuppliersFilter,
  SuppliersRequestParams,
  SuppliersSourceEnum,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';
import { getCurrentDate, getYearAgoDate } from '@/components/DatePeriodPicker/utils';
import { PageSizeChangeCallbackType } from '@/components/Table/TablePagination/types';
import { DEFAULT_PAGE_SIZE } from '@/components/Table/TablePagination/constants';

type OkpdKtruSuppliersContextType = {
  filter: SuppliersFilter;
  pageSize: number;
  requestParams: SuppliersRequestParams;
};

type OkpdKtruSuppliersActionsContextType = {
  setFilter: Dispatch<SetStateAction<SuppliersFilter>>;
  changePageSize: PageSizeChangeCallbackType;
};

export const OkpdKtruSuppliersContext = createContext<OkpdKtruSuppliersContextType | null>(null);
export const OkpdKtruSuppliersActionsContext =
  createContext<OkpdKtruSuppliersActionsContextType | null>(null);

type Props = {
  okpdCode?: string;
  ktruCode?: string;
} & PropsWithChildren;

export const OkpdKtruSuppliersProvider = ({ children, okpdCode, ktruCode }: Props) => {
  const [filter, setFilter] = useState<SuppliersFilter>({
    source: SuppliersSourceEnum.EIS,
    region: [],
    contractDateFrom: getYearAgoDate(),
    contractDateTo: getCurrentDate(),
    smp: false,
    hasPenalty: true,
    okpdCode,
    ktruCode,
  });
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const changePageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);

  const requestParams = useMemo(
    (): SuppliersRequestParams => ({
      filter,
      pageSize,
    }),
    [filter, pageSize],
  );

  const values = useMemo<OkpdKtruSuppliersContextType>(
    () => ({
      filter,
      pageSize,
      requestParams,
    }),
    [filter, pageSize, requestParams],
  );

  const actions = useMemo<OkpdKtruSuppliersActionsContextType>(
    () => ({
      setFilter,
      changePageSize,
    }),
    [changePageSize],
  );

  return (
    <OkpdKtruSuppliersContext.Provider value={values}>
      <OkpdKtruSuppliersActionsContext.Provider value={actions}>
        {children}
      </OkpdKtruSuppliersActionsContext.Provider>
    </OkpdKtruSuppliersContext.Provider>
  );
};
