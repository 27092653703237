import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { NmckHistoryResponse } from '@/api/nmckApi/nmckApiTypes';
import { useGetNmcks } from '@/api/nmckApi/nmckApi';
import { SWRResponse } from 'swr';

type NmckHistoryContextType = {
  search: string;
  historyResponse: SWRResponse<NmckHistoryResponse>;
};
type NmckHistoryActionsContextType = {
  setSearch: Dispatch<SetStateAction<string>>;
};

export const NmckHistoryContext = createContext<NmckHistoryContextType | null>(null);
export const NmckHistoryActionsContext = createContext<NmckHistoryActionsContextType | null>(null);

export const NmckHistoryProvider = ({ children }: PropsWithChildren) => {
  const [search, setSearch] = useState('');

  const response = useGetNmcks({
    searchString: search,
  });

  const values = useMemo(
    (): NmckHistoryContextType => ({
      search,
      historyResponse: response,
    }),
    [response, search],
  );
  const actions = useMemo(
    () => ({
      setSearch,
    }),
    [],
  );

  return (
    <NmckHistoryContext.Provider value={values}>
      <NmckHistoryActionsContext.Provider value={actions}>
        {children}
      </NmckHistoryActionsContext.Provider>
    </NmckHistoryContext.Provider>
  );
};
