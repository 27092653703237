import { MailBodyParams } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';

export const openMailTo = (
  sender: string,
  receivers: string[],
  mailTemplateParams: MailBodyParams,
) => {
  const subject = encodeURIComponent(`Запрос КП от ${mailTemplateParams.orgShortName}`);
  const body = encodeURIComponent(getMailBody(mailTemplateParams));
  const mailLink = getMailLinkByEmailDomain(sender, { emails: receivers, body, subject });

  window.open(mailLink, '_blank');
};

const getMailLinkByEmailDomain = (
  sender: string,
  { emails, body, subject }: { emails: string[]; subject: string; body: string },
) => {
  if (!emails.length) {
    return;
  }

  const domain = sender.split('@')[1].toLowerCase();
  const { mailto, bccQuery } = getFormattedReceivers(emails);

  if (domain.includes('yandex') || domain.includes('ya')) {
    return `https://mail.yandex.com/compose?to=${mailto}&${bccQuery}&subject=${subject}&body=${body}`;
  }
  if (
    domain.includes('mail') ||
    domain.includes('bk') ||
    domain.includes('inbox') ||
    domain.includes('list') ||
    domain.includes('internet')
  ) {
    return `https://e.mail.ru/compose?mailto=mailto:${mailto}&${bccQuery}&subject=${subject}&body=${body}`;
  }

  return `mailto:${mailto}?${bccQuery}&subject=${subject}&body=${body}`;
};

const getMailBody = ({ inn, kpp, unit, name, code, count }: MailBodyParams) =>
  `Добрый день!

Прошу предоставить цены на следующие товары/работы/услуги для нужд заказчика:

ИНН/КПП: ${inn} / ${kpp}

Наименование: ${name} 
ОКПД2/КТРУ: ${code}
Ед. изм.: ${unit}
Кол-во: ${count}`;

const getFormattedReceivers = (emails: string[]) => {
  const mailto = emails[0];
  const hiddenCopies = emails.slice(1);

  const bccQuery = hiddenCopies.length ? `bcc=${hiddenCopies.join(',')}` : '';

  return {
    mailto,
    bccQuery,
  };
};
