import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { Tabs } from 'antd';
import { staticTabItems } from './CompatibilityFilter.constants';
import styles from './CompatibilityFilter.module.scss';
import { uniq } from 'lodash';
import { CompatibilityRow } from '@/api/compatibilityApi/compatibilityApiTypes';

type Props = {
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  rows: CompatibilityRow[];
  className?: string;
};

const getUniquePurchases = (rows: CompatibilityRow[]): number[] => {
  return uniq(rows.map((r) => r.purchaseNumber));
};

export const CompatibilityFilter = memo(({ activeKey, setActiveKey, rows, className }: Props) => {
  const tabItems = useMemo(
    () => [
      ...staticTabItems!,
      ...getUniquePurchases(rows).map((item) => {
        return { key: item.toString(), label: `Закупка ${item}` };
      }),
    ],
    [rows],
  );

  return (
    <div className={className}>
      <Tabs
        items={tabItems}
        activeKey={activeKey}
        onChange={setActiveKey}
        size="small"
        className={styles.tabs}
      />
    </div>
  );
});

CompatibilityFilter.displayName = 'CompatibilityFilter';
