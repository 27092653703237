import { Button, ButtonProps } from 'antd';
import React, { useState, MouseEvent } from 'react';

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: ((e: MouseEvent) => Promise<void>) | (() => Promise<void>);
};

export const AsyncButton = ({ onClick, ...props }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: MouseEvent) => {
    setLoading(true);

    try {
      await onClick(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      {...props}
      loading={loading}
      onClick={handleClick}
    />
  );
};
