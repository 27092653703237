import { CSSProperties, memo, ReactNode, useMemo } from 'react';
import { Flex, FlexProps } from 'antd';
import styles from './FormFieldRow.module.scss';

type Props = {
  label: ReactNode;
  hint?: ReactNode;
  field: ReactNode;
  labelMinWidth?: CSSProperties['minWidth'];

  rowHeight?: CSSProperties['height'];
  rowAlign?: FlexProps['align'];
};

export const FormFieldRow = memo(
  ({ label, labelMinWidth = 150, rowHeight = 'auto', rowAlign = 'center', field, hint }: Props) => {
    const wrapperStyles = useMemo<CSSProperties>(
      () => ({
        height: rowHeight,
      }),
      [rowHeight],
    );
    const labelStyles = useMemo<CSSProperties>(
      () => ({
        minWidth: labelMinWidth,
      }),
      [labelMinWidth],
    );

    return (
      <Flex
        align={rowAlign}
        style={wrapperStyles}
        gap={8}
      >
        <Flex
          component="label"
          align="center"
          className={styles.label}
          style={labelStyles}
        >
          <span className={styles.label}>{label}</span>
          <span className={styles.colon}>:</span>
          {hint && <span className={styles.hint}>{hint}</span>}
        </Flex>
        {field}
      </Flex>
    );
  },
);

FormFieldRow.displayName = 'FormFieldRow';
