import { useContext } from 'react';
import { DocsContext } from '@/widgets/Docs/context/DocsContext';

export const useDocsContext = () => {
  const context = useContext(DocsContext);

  if (!context) {
    throw new Error('DocsContext must be used within a DocsContextProvider');
  }

  return context;
};
