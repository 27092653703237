import { TableProps } from 'antd';
import { memo, useMemo } from 'react';
import styles from './DocsTable.module.scss';
import { UiEmpty } from '@/components/Ui/UiEmpty/UiEmpty';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';
import { useDocsContext } from '@/widgets/Docs/context/useDocsContext';
import { docsTableColumns } from '@/widgets/Docs/components/DocsTable/DocsTable.constants';

export const DocsTable = memo(() => {
  const {
    docsResponse: { data, isLoading },
    filter,
  } = useDocsContext();

  const tableLocal = useMemo<TableProps['locale']>(
    () => ({
      emptyText: (
        <UiEmpty
          description={
            filter.search
              ? undefined
              : 'Введите код ОКПД2/КТРУ для поиска документов в опубликованных на ЕИС закупках'
          }
        />
      ),
    }),
    [filter.search],
  );

  return (
    <div className={styles.wrapper}>
      <AutoScrollTable
        rowKey="eisId"
        loading={isLoading}
        columns={docsTableColumns}
        dataSource={data}
        className={styles.table}
        pagination={false}
        rowClassName={styles.row}
        locale={tableLocal}
      />
    </div>
  );
});

DocsTable.displayName = 'DocsTable';
