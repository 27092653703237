import { memo, useCallback } from 'react';
import { ContractDocument } from '@/api/docsApi/docsApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';
import { Typography } from 'antd';
import Link from 'next/link';
import styles from './DocsTableEisLink.module.scss';

type Props = {
  document: ContractDocument;
};

export const DocsTableEisLink = memo(({ document: { itemName, contractHref } }: Props) => {
  const handleLinkClick = useCallback(() => {
    postBillingEvent({
      action: BillingEventType.DOCS_DOWNLOAD_DOC,
      value: itemName,
    });
  }, [itemName]);

  return (
    <Typography.Paragraph
      onClick={handleLinkClick}
      ellipsis={{ rows: 2 }}
    >
      <Link
        href={contractHref}
        target="_blank"
        className={styles.link}
      >
        {itemName}
      </Link>
    </Typography.Paragraph>
  );
});

DocsTableEisLink.displayName = 'DocsTableEisLink';
