import { memo, useCallback } from 'react';
import { KtruInfoLineItem } from '@/api/okpdApi/okpdApiTypes';
import ExportDiagonalIcon from 'public/icons/exportDiagonal.svg';
import Link from 'next/link';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import styles from './KtruInfosLineItem.module.scss';

type Props = {
  lineItem: KtruInfoLineItem;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruInfosLineItem = memo(({ lineItem, setModalPageParams }: Props) => {
  const { link, text } = lineItem;
  const router = useRouter();
  const isLink = !!link;
  const isExternalLink = isLink && link.startsWith('http');
  const isInternalLink = isLink && !link.startsWith('http');
  const formattedText = text || '—';

  const handleInternalLinkClick = useCallback(() => {
    if (!link) {
      return;
    }

    const isOkpdLink = link.startsWith(pageRoutes.okpd2);
    const isKtruLink = link.startsWith(pageRoutes.ktru);
    const code = link.split('/').at(-1);

    if ((isOkpdLink || isKtruLink) && code) {
      if (setModalPageParams) {
        return setModalPageParams((prev) => ({
          ...prev,
          page: isOkpdLink ? OkpdKtruPageTypes.OKPD : OkpdKtruPageTypes.KTRU,
          code,
        }));
      }
    }

    return router.push(link);
  }, [link, router, setModalPageParams]);

  if (isExternalLink) {
    return (
      <Link
        href={link}
        target="_blank"
        className={styles.externalLink}
      >
        <span>{formattedText}</span>
        <ExportDiagonalIcon />
      </Link>
    );
  }

  if (isInternalLink) {
    return (
      <OkpdLink
        isLink={!setModalPageParams}
        href={link}
        onClick={handleInternalLinkClick}
      >
        {formattedText}&nbsp;
      </OkpdLink>
    );
  }

  return <span>{formattedText}</span>;
});

KtruInfosLineItem.displayName = 'KtruInfosLineItem';
