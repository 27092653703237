import { TableProps, Typography } from 'antd';
import { ContractDocument } from '@/api/docsApi/docsApiTypes';
import { DocsTableFacComplaintLink } from '@/widgets/Docs/components/DocsTable/components/DocsTableFacComplaintLink/DocsTableFacComplaintLink';
import { DocsTableEisLink } from '@/widgets/Docs/components/DocsTable/components/DocsTableEisLink/DocsTableEisLink';
import styles from '@/widgets/Docs/components/DocsTable/DocsTable.module.scss';
import { DocsTableDocumentCell } from '@/widgets/Docs/components/DocsTable/components/DocsTableDocumentCell/DocsTableDocumentCell';
import moment from 'moment/moment';
import { formatPrice } from '@/utils/formatPrice';

export const docsTableColumns: TableProps<ContractDocument>['columns'] = [
  {
    title: 'Жалоба ФАС',
    dataIndex: 'hasFasComplaint',
    key: 'hasFasComplaint',
    render: (_, document: ContractDocument, index) => (
      <DocsTableFacComplaintLink
        key={index}
        document={document}
      />
    ),
  },
  {
    title: 'Закупка в ЕИС',
    dataIndex: 'itemName',
    key: 'itemName',
    width: 552,
    render: (_, document, index) => (
      <DocsTableEisLink
        key={index}
        document={document}
      />
    ),
  },
  {
    title: 'Ед. изм.',
    dataIndex: 'measurementUnits',
    key: 'measurementUnits',
    render: (_, { measurementUnit }) => <p>{measurementUnit}</p>,
  },
  {
    title: 'ОКПД2/КТРУ',
    dataIndex: 'okpdKtruCode',
    key: 'okpdKtruCode',
  },
  {
    title: 'Документация',
    dataIndex: 'docs',
    key: 'docs',
    width: 320,
    className: styles.documentation,
    render: (_, { docs }) => <DocsTableDocumentCell items={docs} />,
  },
  {
    title: 'Дата',
    dataIndex: 'publicationDate',
    key: 'publicationDate',
    render: (_, { publicationDate }) => <p>{moment(publicationDate).format('DD.MM.YYYY')}</p>,
  },
  {
    title: 'Цена за ед., ₽',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    render: (_, { unitPrice }) => formatPrice(unitPrice),
  },
  {
    title: 'Регион',
    dataIndex: 'customerRegion',
    key: 'customerRegion',
    className: styles.region,
  },
  {
    title: 'Способ закупки',
    dataIndex: 'purchaseMethod',
    key: 'purchaseMethod',
    width: 170,
    render: (_, { purchaseMethod }) => (
      <Typography.Paragraph ellipsis={{ rows: 2, tooltip: purchaseMethod }}>
        {purchaseMethod}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Нацрежим и преф.',
    dataIndex: 'nationalRegime',
    key: 'nationalRegime',
    width: 200,
    render: (_, { preferencesAndRestrictions }) => (
      <Typography.Paragraph ellipsis={{ rows: 2, tooltip: preferencesAndRestrictions || '—' }}>
        {preferencesAndRestrictions || '—'}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Заказчики',
    dataIndex: 'customerName',
    key: 'customerName',
    width: 240,
    render: (_, { customerName }) => (
      <Typography.Paragraph ellipsis={{ rows: 2, tooltip: customerName || '—' }}>
        {customerName || '—'}
      </Typography.Paragraph>
    ),
  },
];
