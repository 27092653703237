import { memo, MouseEvent, useCallback, useState } from 'react';
import styles from './NmckHistoryCard.module.scss';
import { Button, Flex, Popconfirm, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { formatDate } from '@/utils';
import { Tag } from '@/components';
import { formatNumber } from '@/utils/formatNumber';
import Copy from 'public/icons/copy.svg';
import Export from 'public/icons/export.svg';
import Repeat from 'public/icons/new.svg';
import Delete from 'public/icons/trash.svg';
import { NmckCommon, NmckType } from '@/api/nmckApi/nmckApiTypes';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import { useRouter } from 'next/navigation';
import { nmckData } from '@/widgets/NmckHistory/constants';
import {
  getNmckDrugReportXlsx,
  postNewNmckDrugs,
  postRepeatedDrugCalculation,
} from '@/api/nmckDrugsApi/nmckDrugsApi';
import { deleteNmck, getNmckXlsx, postNewNmck } from '@/api/nmckApi/nmckApi';
import { applyCardElementTemporaryHighlight } from '@/widgets/NmckHistory/utils';
import { NmckDrugsByIdResponse } from '@/api/nmckDrugsApi/nmckDrugsApiTypes';
import {
  failedCreateRecurrencyNotification,
  successCreateRecurrencyNotification,
} from '@/widgets/NmckDrugs/components/DrugsGridHeader/utils';
import { useNmckHistoryContext } from '@/widgets/NmckHistory/context/useNmckHistoryContext';

type Props = {
  nmck: NmckCommon;
};

export const NmckHistoryCard = memo(({ nmck }: Props) => {
  const {
    historyResponse: { mutate },
  } = useNmckHistoryContext();
  const router = useRouter();
  const [copyLoading, setCopyLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [recurrenceLoading, setRecurrenceLoading] = useState(false);

  const handleCopyButtonClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      setCopyLoading(true);

      if (nmck.type === NmckType.DRUGS) {
        const createdNmck = await postNewNmckDrugs({ copyFromId: nmck.id });
        await mutate();
        applyCardElementTemporaryHighlight(createdNmck.id);
      } else {
        const createdNmck = await postNewNmck({ copyFromId: nmck.id });
        await mutate();
        applyCardElementTemporaryHighlight(createdNmck.id);
      }

      setCopyLoading(false);
    },
    [mutate, nmck.id, nmck.type],
  );

  const handleDrugRepeatedCalculationButtonClick = useCallback(
    async (event: MouseEvent) => {
      setRecurrenceLoading(true);
      event.stopPropagation();

      try {
        const newNmck: NmckDrugsByIdResponse = await postRepeatedDrugCalculation(nmck.id);
        await mutate();
        successCreateRecurrencyNotification(newNmck.recurrencyBadge);
        applyCardElementTemporaryHighlight(newNmck.id);
      } catch (e) {
        failedCreateRecurrencyNotification();
      }
      setRecurrenceLoading(false);
    },
    [mutate, nmck.id],
  );

  const handleExportButtonClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      setExportLoading(true);

      if (nmck.type === NmckType.DRUGS) {
        await getNmckDrugReportXlsx(nmck.id, nmck.name);
      } else {
        await getNmckXlsx(nmck.id, nmck.name);
      }
      setExportLoading(false);
    },
    [nmck.id, nmck.name, nmck.type],
  );

  const handleDelete = useCallback(async () => {
    await deleteNmck(nmck.id);
    await mutate();
  }, [mutate, nmck.id]);

  const handleCardClick = useCallback(() => {
    router.push(`${nmckData[nmck.type].route}/${nmck.id}`);
  }, [nmck.id, nmck.type, router]);

  return (
    <Flex
      align="center"
      gap={16}
      onClick={handleCardClick}
      className={styles.card}
      id={`nmck-${nmck.id}`}
    >
      <Flex gap={16}>
        <div className={cx(styles.cardDate, styles.columnDate)}>{formatDate(nmck.modified)}</div>

        <Tooltip
          placement="bottom"
          arrow={false}
          title={nmck.generatedName ? `${nmck.name} (${nmck.generatedName})` : `${nmck.name}`}
        >
          <div className={cx(styles.cardName, styles.columnName)}>
            {nmck.generatedName ? `${nmck.name} (${nmck.generatedName})` : `${nmck.name}`}
          </div>
        </Tooltip>

        <Flex
          className={cx(styles.cardMethod, styles.columnMethod)}
          align="center"
          gap={16}
        >
          <span>{nmckData[nmck.type].label}</span>
          {nmck.recurrencyBadge && <Tag label={nmck.recurrencyBadge} />}
        </Flex>

        <div className={cx(styles.cardTotal, styles.columnPrice)}>
          {formatNumber(nmck.nmckTotal)} ₽
        </div>
      </Flex>

      <Flex
        gap={16}
        justify="space-between"
        className={styles.cardActions}
      >
        <Flex
          gap={16}
          align="center"
        >
          <Button
            type="link"
            size="small"
            onClick={handleCopyButtonClick}
            icon={copyLoading ? <Spin size="small" /> : <Copy />}
          />

          <Button
            className="button-with-icon"
            type="link"
            size="small"
            onClick={handleExportButtonClick}
            icon={exportLoading ? <Spin size="small" /> : <Export />}
          >
            Excel
          </Button>

          {nmck.type === NmckType.DRUGS && (
            <Button
              type="link"
              size="small"
              icon={recurrenceLoading ? <Spin size="small" /> : <Repeat />}
              className="button-with-icon"
              disabled={nmck.recurrency === 3}
              onClick={handleDrugRepeatedCalculationButtonClick}
            >
              Повторный расчёт по приказу 1064н п.12
            </Button>
          )}
        </Flex>

        <Popconfirm
          title="Удаление расчёта"
          description="Вы уверены что хотите удалить этот расчёт?"
          okText="Да"
          cancelText="Нет"
          onConfirm={handleDelete}
          onPopupClick={preventEventPropagation}
        >
          <Button
            icon={<Delete />}
            type="link"
            className={styles.cardDelete}
            onClick={preventEventPropagation}
          />
        </Popconfirm>
      </Flex>
    </Flex>
  );
});

NmckHistoryCard.displayName = 'NmckHistoryCard';
