import { memo, useCallback, useState } from 'react';
import { BadgeType } from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridCompatibilityWarning.module.scss';
import { NmckCompatibilityModal } from '@/components/NmckGrid/NmckGridCompatibilityWarning/NmckCompatibilityModal/NmckCompatibilityModal';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { splitNmckById, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { pageRoutes } from '@/app/pageRoutes';
import { useRouter } from 'next/navigation';
import { Button } from 'antd';
import { CompatibilityWarning } from '@/components/Compatibility/CompatibilityWarning/CompatibilityWarning';
import { AsyncButton } from '@/components';

export const NmckGridCompatibilityWarning = memo(() => {
  const { nmckId } = useNmckGridContext();
  const { data } = useGetNmckById(nmckId);
  const router = useRouter();

  const [incompatibleModalOpen, setIncompatibleModalOpen] = useState(false);

  const hasIncompatibleCode = data?.badge === BadgeType.HAS_INCOMPATIBLE_CODES;
  const warningText = hasIncompatibleCode
    ? 'Несовместимые коды ОКПД2/КТРУ.'
    : 'В закупке содержатся коды ОКПД2/КТРУ, которые попадают под ограничения и запреты.';

  const handleModal = useCallback(() => {
    setIncompatibleModalOpen(!incompatibleModalOpen);
  }, [incompatibleModalOpen]);

  const handleSplit = useCallback(async () => {
    await splitNmckById(nmckId);
    router.push(pageRoutes.nmckHistory);
  }, [nmckId, router]);

  return (
    data &&
    data.badge !== BadgeType.NO_BADGE && (
      <>
        <CompatibilityWarning
          hasCompatibility={data.badge === BadgeType.HAS_INCOMPATIBLE_CODES}
          hasRestrictions={data.badge === BadgeType.HAS_RESTRICTIONS}
          content={warningText}
          onlyVerticalBorder
          extraContent={
            <>
              <Button
                type="link"
                size="small"
                className={styles.button}
                onClick={handleModal}
              >
                Подробнее
              </Button>

              {hasIncompatibleCode && (
                <AsyncButton
                  type="link"
                  size="small"
                  className={styles.button}
                  onClick={handleSplit}
                >
                  Разделить закупку
                </AsyncButton>
              )}
            </>
          }
        />
        <NmckCompatibilityModal
          nmckId={nmckId}
          open={incompatibleModalOpen}
          name={data.name}
          onCancel={handleModal}
        />
      </>
    )
  );
});

NmckGridCompatibilityWarning.displayName = 'NmckGridCompatibilityWarning';
