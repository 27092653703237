import { getData } from '@/api/commonApi/commonApi';
import {
  ContractExecutionResponse,
  PurchasePlanResponse,
  PurchasesInProcessResponse,
} from './currentApiTypes';
import useSWRImmutable from 'swr/immutable';

export const useGetPurchasePlanItems = () => {
  return useSWRImmutable('/purchase-plan-items', getData<PurchasePlanResponse>);
};

export const useGetPurchasesInProcess = () => {
  return useSWRImmutable('/purchases-in-process', getData<PurchasesInProcessResponse>);
};

export const useGetContractsExecution = () => {
  return useSWRImmutable('/current-contract-execution', getData<ContractExecutionResponse>);
};
