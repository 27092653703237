import { memo, useEffect } from 'react';
import { Table } from 'antd';
import styles from './ContractsTable.module.scss';
import { useGetContractsExecution } from '@/api/currentApi/currentApi';
import { currentContractsTableColumns } from '@/widgets/CurrentPage/components/ContractsTable/ContractsTable.constants';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

export const ContractsTable = memo(() => {
  const { data, isLoading } = useGetContractsExecution();

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_CONTRACTS_IN_PROGRESS,
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey="regnum"
        loading={isLoading}
        columns={currentContractsTableColumns}
        dataSource={data?.results}
        pagination={false}
      />
    </div>
  );
});

ContractsTable.displayName = 'ContractsTable';
