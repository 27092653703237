import { Flex, Spin } from 'antd';
import { OkpdCard, Pagination } from '@/widgets/OkpdKtru/components';
import listStyles from '../List.module.scss';
import { memo, useCallback } from 'react';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { useInfiniteList } from '@/hooks';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { useGetOkpdListInfinite } from '@/api/okpdApi/okpdApi';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  filter: OkpdKtruListFilter;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdList = memo(({ filter, setModalPageParams }: Props) => {
  const { list, isLoading, isMoreValidating, loadMore, hasMoreResults } = useInfiniteList<
    OkpdKtruListFilter,
    OkpdResult
  >(useGetOkpdListInfinite, filter);

  const handleOkpdCodeClick = useCallback(
    ({ code }: OkpdResult) => {
      postBillingEvent({
        action: BillingEventType.TRU_SEARCH_RESULT_CODE_CLICK,
        value: code,
        value2: filter.searchString,
      });
    },
    [filter.searchString],
  );

  return (
    <div>
      <Flex
        justify="space-between"
        align="center"
        className={listStyles.wrapper}
      >
        <Flex
          gap={16}
          align="center"
        >
          <h2 className={listStyles.title}>ОКПД 2</h2>
          {isLoading && (
            <Flex justify="center">
              <Spin />
            </Flex>
          )}
        </Flex>
      </Flex>
      {!!list?.length && (
        <>
          <Flex
            vertical={true}
            gap={16}
          >
            {list.map((item) => (
              <OkpdCard
                key={item.code + item.id}
                okpd={item}
                setModalPageParams={setModalPageParams}
                onCodeClick={handleOkpdCodeClick}
              />
            ))}
          </Flex>
          <Pagination
            isMoreLoading={isMoreValidating}
            hasMoreResults={hasMoreResults}
            onShowMore={loadMore}
          />
        </>
      )}

      {!isLoading && list && !list.length && <div className={listStyles.noData}>Не найдено</div>}
    </div>
  );
});

OkpdList.displayName = 'OkpdList';
