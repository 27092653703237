import { useContext } from 'react';
import { OkpdKtruSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/OkpdKtruSuppliersContext';

export const useSuppliersContext = () => {
  const context = useContext(OkpdKtruSuppliersContext);

  if (!context) {
    throw new Error('OkpdKtruSuppliersContext must be used within a OkpdKtruSuppliersProvider');
  }

  return context;
};
