import { Flex, Spin } from 'antd';
import { KtruCard, Pagination } from '@/widgets/OkpdKtru/components';
import listStyles from '../List.module.scss';
import { memo, useCallback } from 'react';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { useInfiniteList } from '@/hooks';
import { useGetKtruListInfinite } from '@/api/okpdApi/okpdApi';
import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type KtruListProps = {
  filter: OkpdKtruListFilter;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruList = memo(({ filter, setModalPageParams }: KtruListProps) => {
  const { list, isLoading, isMoreValidating, loadMore, hasMoreResults } = useInfiniteList<
    OkpdKtruListFilter,
    KtruResult
  >(useGetKtruListInfinite, filter);

  const handleKtruCodeClick = useCallback(
    ({ code }: KtruResult) => {
      postBillingEvent({
        action: BillingEventType.TRU_SEARCH_RESULT_CODE_CLICK,
        value: code,
        value2: filter.searchString,
      });
    },
    [filter.searchString],
  );

  return (
    <div>
      <Flex
        justify="space-between"
        align="center"
        className={listStyles.wrapper}
      >
        <Flex
          gap={16}
          align="center"
        >
          <h2 className={listStyles.title}>КТРУ</h2>
          {isLoading && (
            <Flex justify="center">
              <Spin />
            </Flex>
          )}
        </Flex>
      </Flex>
      {!!list?.length && (
        <>
          <Flex
            vertical={true}
            gap={16}
          >
            {list.map((item, index) => (
              <KtruCard
                key={item.code + index}
                ktru={item}
                setModalPageParams={setModalPageParams}
                onCodeClick={handleKtruCodeClick}
              />
            ))}
          </Flex>
          <Pagination
            isMoreLoading={isMoreValidating}
            hasMoreResults={hasMoreResults}
            onShowMore={loadMore}
          />
        </>
      )}

      {!isLoading && list && !list.length && (
        <div className={listStyles.noData}>Отсутствует в КТРУ</div>
      )}
    </div>
  );
});

KtruList.displayName = 'KtruList';
