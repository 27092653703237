import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { OkpdKtruPage } from '@/widgets/OkpdKtru/OkpdKtruPage';
import { OkpdKtruModalPageParams, OkpdKtruPageTypes } from '@/components/OkpdFlowModal/types';
import { OkpdDetailPage } from '@/widgets';
import { KtruDetailPage } from '@/widgets/KtruDetail/KtruDetailPage/KtruDetailPage';
import { OkpdLayout } from '@/layouts/OkpdLayout/OkpdLayout';
import { AsyncButton, UiModal } from '@/components';
import styles from './OkpdFlowModal.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchString: string;
  onAddToCalculation: (pageParams: OkpdKtruModalPageParams) => Promise<unknown>;
};

export const OkpdFlowModal = memo(
  ({ isOpen, setIsOpen, searchString, onAddToCalculation }: Props) => {
    const [pageParams, setPageParams] = useState<OkpdKtruModalPageParams>({
      page: OkpdKtruPageTypes.LISTS,
      searchString,
      code: '',
    });

    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const ComputedOkpdPage: ReactNode = useMemo(
      () =>
        ({
          [OkpdKtruPageTypes.LISTS]: (
            <OkpdKtruPage
              modalPageParams={pageParams}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.OKPD]: (
            <OkpdDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.KTRU]: (
            <KtruDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
        })[pageParams.page],
      [pageParams],
    );

    const handleAddToCalculationClick = useCallback(async () => {
      await onAddToCalculation(pageParams);
      handleClose();
    }, [handleClose, onAddToCalculation, pageParams]);

    useEffect(() => {
      setPageParams((prev) => ({
        ...prev,
        code: '',
        page: OkpdKtruPageTypes.LISTS,
        searchString,
      }));
    }, [searchString]);

    return (
      <UiModal
        open={isOpen}
        title="Выбор ОКПД"
        width="95%"
        bodyColor="white"
        className={styles.modal}
        classNames={{ body: styles.body }}
        footer={
          <AsyncButton
            type="primary"
            disabled={!pageParams.code}
            onClick={handleAddToCalculationClick}
          >
            Добавить код в расчёт
          </AsyncButton>
        }
        onCancel={handleClose}
      >
        <OkpdLayout
          modalPageParams={pageParams}
          setModalPageParams={setPageParams}
        >
          {ComputedOkpdPage}
        </OkpdLayout>
      </UiModal>
    );
  },
);

OkpdFlowModal.displayName = 'OkpdFlowModal';
