export type BillingEvent = {
  action: BillingEventType;
  value?: string | number | boolean;
  value2?: string | number | boolean;
};

export enum BillingEventType {
  NMCK_OPEN = 'NMCK_OPEN', // НМЦК: Открыть раздел
  NMCK_APPLY_INCREASE_COEFF = 'NMCK_APPLY_INCREASE_COEFF', // НМЦК: Применить повышающий коэффициент

  NMCK_LP_CHOOSE_DRUG = 'NMCK_LP_CHOOSE_DRUG', // НМЦК ЛП: Выбрать лекарство
  NMCK_LP_OPEN = 'NMCK_LP_OPEN', // НМЦК ЛП: Открыть раздел
  NMCK_LP_NEW_CALCULATION = 'NMCK_LP_NEW_CALCULATION', // НМЦК ЛП: Создать новый расчет

  NMCK_MED_OPEN = 'NMCK_MED_OPEN', // НМЦК МИ: Открыть раздел

  OKPD_OPEN = 'OKPD_OPEN', // ОКПД2: Открыть раздел
  OKPD_COPY_TEXT = 'OKPD_COPY_TEXT', // ОКПД2: Скопирован текст
  TRU_SEARCH_RESULT_CODE_CLICK = 'TRU_SEARCH_RESULT_CODE_CLICK', // Клик по коду в карточке ОКПД / КТРУ

  DOCS_CHOOSE_REGION = 'DOCS_CHOOSE_REGION', // ПД: Выбрать регион
  DOCS_OPEN = 'DOCS_OPEN', // ПД: Открыть раздел
  DOCS_DOWNLOAD_DOC = 'DOCS_DOWNLOAD_DOC', // ПД: Открыть ссылку на ЕИС/скачать ТЗ

  DEADLINES_CHANGE_PURCHASE_METHOD = 'DEADLINES_CHANGE_PURCHASE_METHOD', // СКП: Изменить способ
  DEADLINES_CHANGE_DATE = 'DEADLINES_CHANGE_DATE', // СКП: Изменить дату
  DEADLINES_VIEW = 'DEADLINES_VIEW', // СКП: Нажать печать/просмотр
  DEADLINES_OPEN = 'DEADLINES_OPEN', // СКП: Открыть раздел

  CURRENT_PURCHASES_OPEN = 'CURRENT_PURCHASES_OPEN', // ТЗ: Открыть раздел
  CURRENT_PURCHASES_GO_TO_EIS = 'CURRENT_PURCHASES_GO_TO_EIS', // ТЗ: Перейти на ЕИС
  CURRENT_PURCHASES_PLANNED = 'CURRENT_PURCHASES_PLANNED',
  CURRENT_PURCHASES_IN_PROGRESS = 'CURRENT_PURCHASES_IN_PROGRESS',
  CURRENT_PURCHASES_CONTRACTS_IN_PROGRESS = 'CURRENT_PURCHASES_CONTRACTS_IN_PROGRESS',
}
