import { memo, useCallback, useMemo, useState } from 'react';
import { Doc } from '@/api/docsApi/docsApiTypes';
import styles from './DocsTableDocumentCell.module.scss';
import { DocsDocumentCellLink } from '@/widgets/Docs/components/DocsTable/components/DocsTableDocumentCell/components/DocsDocumentCellLink/DocsDocumentCellLink';
import { Button, Flex } from 'antd';

type DocsRowProps = {
  items: Doc[];
};

export const DocsTableDocumentCell = memo(({ items }: DocsRowProps) => {
  const isExpandedButtonVisible = items.length > 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleDocs = useMemo(() => (isExpanded ? items : items.slice(0, 1)), [items, isExpanded]);

  const handleMoreButtonClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <Flex
      vertical
      gap={6}
      align="start"
    >
      {visibleDocs.map((doc, id) => {
        return (
          <DocsDocumentCellLink
            key={doc.documentationName + id}
            doc={doc}
          />
        );
      })}
      {isExpandedButtonVisible && (
        <Button
          type="link"
          size="small"
          className={styles.showMore}
          onClick={handleMoreButtonClick}
        >
          {isExpanded ? 'Свернуть' : 'Еще...'}
        </Button>
      )}
    </Flex>
  );
});

DocsTableDocumentCell.displayName = 'DocsTableDocumentCell';
