import { memo, useCallback, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button } from 'antd';
import PrintIcon from 'public/icons/print.svg';
import ExportIcon from 'public/icons/export.svg';
import { DeadlineCalculation } from '@/api/skpApi/skpApiTypes';
import { PrintContent } from '../PrintContent/PrintContent';
import { getDeadlineCalculationXlsx } from '@/api/skpApi/skpApi';
import { AsyncButton, UiModal } from '@/components';
import {
  printModalIFrameStyles,
  printModalStyleSheetTag,
} from '@/widgets/Skp/components/Procedures/components/PrintModal/PrintModal.constants';

type Props = {
  open: boolean;
  onCancel: () => void;
  currentCalculation: DeadlineCalculation;
};

export const PrintModal = memo(({ open, currentCalculation, onCancel }: Props) => {
  const printFrameRef = useRef<HTMLIFrameElement>(null);

  const htmlContent = useMemo(
    () => `
        <!DOCTYPE html>
        <html lang="ru">
          <head>
            <title>Расчёт сроков</title>
            ${printModalStyleSheetTag}
          </head>
          <body>
            ${ReactDOMServer.renderToString(<PrintContent currentCalculation={currentCalculation} />)}
          </body>
        </html>
      `,
    [currentCalculation],
  );

  const handlePrint = useCallback(() => {
    if (printFrameRef.current) {
      const printDocument =
        printFrameRef.current.contentDocument || printFrameRef.current.contentWindow?.document;
      if (printDocument) {
        printDocument.open();
        printDocument.write(htmlContent);
        printDocument.close();
        printFrameRef.current.contentWindow?.focus();
        printFrameRef.current.contentWindow?.print();
      }
    }
  }, [htmlContent]);

  const handleDownload = useCallback(async () => {
    await getDeadlineCalculationXlsx(currentCalculation);
  }, [currentCalculation]);

  const titleButtons = (
    <>
      <Button
        icon={<PrintIcon />}
        className="button-with-icon"
        onClick={handlePrint}
      >
        Распечатать
      </Button>
      <AsyncButton
        icon={<ExportIcon />}
        className="button-with-icon"
        onClick={handleDownload}
      >
        Скачать
      </AsyncButton>
    </>
  );

  return (
    <UiModal
      open={open}
      title="Предпросмотр СКП"
      titleButtons={titleButtons}
      bodyColor="white"
      width="95%"
      onCancel={onCancel}
    >
      <PrintContent currentCalculation={currentCalculation} />
      <iframe
        ref={printFrameRef}
        style={printModalIFrameStyles}
      />
    </UiModal>
  );
});
PrintModal.displayName = 'PrintModal';
