import { memo, useCallback, useMemo, useState } from 'react';
import { Radio } from 'antd';
import {
  CompatibilityCell,
  CompatibilityCellItem,
} from '@/api/compatibilityApi/compatibilityApiTypes';
import { CheckboxOptionType } from 'antd/es/checkbox/Group';
import styles from './CompatibilityTableExpandedRowRadioGroup.module.scss';
import { RadioChangeEvent } from 'antd/es/radio/interface';

type Props = {
  itemsList: CompatibilityCellItem[];
  expandableCell?: CompatibilityCell;
  updateCompatibilityCell: (selectedItemId: CompatibilityCellItem['id']) => Promise<void>;
};

export const CompatibilityTableExpandedRowRadioGroup = memo(
  ({ itemsList, expandableCell, updateCompatibilityCell }: Props) => {
    const selectedItem = useMemo(
      () => expandableCell?.items.find(({ isSelected }) => isSelected),
      [expandableCell?.items],
    );
    const [selectedItemId, setSelectedItemId] = useState<CompatibilityCellItem['id'] | undefined>(
      () => selectedItem?.id,
    );

    const options = useMemo<CheckboxOptionType<CompatibilityCellItem['id']>[]>(
      () =>
        itemsList.map(({ id, text }) => ({
          label: (
            <div
              dangerouslySetInnerHTML={{
                __html: text.replace(/\\n/g, '\n').replace(/\n/g, '<br />'),
              }}
            />
          ),
          value: id,
        })),
      [itemsList],
    );

    const handleRadioChange = useCallback(
      async ({ target: { value } }: RadioChangeEvent) => {
        setSelectedItemId(value);
        await updateCompatibilityCell(Number(value));
      },
      [updateCompatibilityCell],
    );

    return (
      <Radio.Group
        value={selectedItemId || selectedItem?.id}
        options={options}
        className={styles.group}
        onChange={handleRadioChange}
      />
    );
  },
);

CompatibilityTableExpandedRowRadioGroup.displayName = 'CompatibilityTableExpandedRowRadioGroup';
