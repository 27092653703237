import { memo, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './OkpdLayoutSearch.module.scss';
import { InputRef, Spin } from 'antd';
import { DebounceInput } from '@/components';
import { useOkpdRoute } from '@/widgets/OkpdKtru/hooks/useOkpdRoute';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruModalPageParams,
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { trim } from 'lodash';
import { eventsGTM, sendEventGTM } from '@/metrika';
import {
  isValidKtruCode,
  isValidOkpdCode,
} from '@/layouts/OkpdLayout/components/OkpdLayoutSearch/OkpdLayoutSearch.utils';
import { getKtruDetail, getOkpdDetail } from '@/api/okpdApi/okpdApi';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdLayoutSearch = memo(({ modalPageParams, setModalPageParams }: Props) => {
  const { code, searchValue } = useOkpdRoute({ modalPageParams });
  const router = useRouter();

  const inputRef = useRef<InputRef>(null);
  const [searchString, setSearchString] = useState(code || searchValue);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const navigateToListsPage = useCallback(
    (searchString: string = '') => {
      if (setModalPageParams) {
        setModalPageParams({
          page: OkpdKtruPageTypes.LISTS,
          searchString,
          code: '',
        });
      } else {
        const params = new URLSearchParams({
          search: searchString,
        });
        router.push(`${pageRoutes.okpd2}?${params}`, { scroll: false });
      }
    },
    [router, setModalPageParams],
  );

  const navigateToDetailPage = useCallback(
    (code: string, page: Exclude<OkpdKtruPageTypes, OkpdKtruPageTypes.LISTS>) => {
      if (setModalPageParams) {
        setModalPageParams({
          page: page,
          searchString: '',
          code: code,
        });
      } else {
        const pagePath = page === OkpdKtruPageTypes.OKPD ? pageRoutes.okpd2 : pageRoutes.ktru;
        router.push(`${pagePath}/${code}`);
      }
    },
    [router, setModalPageParams],
  );

  const searchOkpdByCode = useCallback(
    async (code: string) => {
      setIsSearchLoading(true);
      try {
        const okpd = await getOkpdDetail(code);
        navigateToDetailPage(okpd.code, OkpdKtruPageTypes.OKPD);
      } catch (err) {
        navigateToListsPage(code);
      } finally {
        setIsSearchLoading(false);
      }
    },
    [navigateToDetailPage, navigateToListsPage],
  );

  const searchKtruByCode = useCallback(
    async (code: string) => {
      setIsSearchLoading(true);
      try {
        const ktru = await getKtruDetail(code);
        navigateToDetailPage(ktru.code, OkpdKtruPageTypes.KTRU);
      } catch (err) {
        navigateToListsPage(code);
      } finally {
        setIsSearchLoading(false);
      }
    },
    [navigateToDetailPage, navigateToListsPage],
  );

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedValue = trim(value);
      const isOnlyWhitespaceChange = trimmedValue === trim(searchString) && value !== searchString;
      setSearchString(value);

      if (!isOnlyWhitespaceChange) {
        sendEventGTM(eventsGTM.okpdSearch, { value: trimmedValue });
        if (isValidOkpdCode(trimmedValue)) {
          return searchOkpdByCode(trimmedValue);
        }
        if (isValidKtruCode(trimmedValue)) {
          return searchKtruByCode(trimmedValue);
        }
        navigateToListsPage(trimmedValue);
      }
    },
    [navigateToListsPage, searchKtruByCode, searchOkpdByCode, searchString],
  );

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'end',
    });
  }, []);

  useEffect(() => {
    if (inputRef.current?.nativeElement !== document.activeElement) {
      setSearchString(code || searchValue);
    }
  }, [code, searchValue]);

  return (
    <DebounceInput
      ref={inputRef}
      value={searchString}
      onDebouncedChange={onSearchChange}
      className={cx('input-line', styles.input)}
      placeholder="Название / код"
      syncOnBlurOnly={false}
      variant="borderless"
      suffix={isSearchLoading ? <Spin size="small" /> : <span />}
      autoFocus
    />
  );
});

OkpdLayoutSearch.displayName = 'OkpdLayoutSearch';
