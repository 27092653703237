import { isClientRender } from '@/utils/isClientRender';

const STORAGE_USER_EMAIL_KEY = 'user-email-key';

export const saveUserEmailToStorage = (email: string) => {
  localStorage.setItem(STORAGE_USER_EMAIL_KEY, email);
};

export const getUserEmailFromStorage = (): string =>
  isClientRender() ? (localStorage.getItem(STORAGE_USER_EMAIL_KEY) ?? '') : '';
