import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterCountry.module.scss';
import { Select, Tooltip } from 'antd';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { NmckContractsCountry } from '@/components/NmckGrid/NmckGridContracts/types';
import { nmckContractsCountriesOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterCountry/constants';
import VIcon from 'public/icons/v.svg';
import InfoIcon from 'public/icons/questionRound.svg';
import { tooltipOverlayInnerStyleForShortTitle } from '@/constants/ui';

export const ContractsFilterCountry = memo(() => {
  const { filter } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleUnitChange = useCallback(
    (value?: NmckContractsCountry) => {
      setFilter((prev) => ({
        ...prev,
        country: value,
      }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Произведено"
      hint={
        <Tooltip
          title="Страна производства объекта закупки"
          overlayInnerStyle={tooltipOverlayInnerStyleForShortTitle}
        >
          <InfoIcon className="hint-icon" />
        </Tooltip>
      }
      field={
        <Select<NmckContractsCountry>
          value={filter.country}
          options={nmckContractsCountriesOptions}
          allowClear
          className={styles.select}
          filterOption={false}
          suffixIcon={<VIcon className="v-icon" />}
          placeholder="Не указано (все страны)"
          onChange={handleUnitChange}
        />
      }
    />
  );
});

ContractsFilterCountry.displayName = 'ContractsFilterCountry';
