import { memo } from 'react';
import {
  updatePurchaseCompatibilityCell,
  useGetCurrentPurchaseCompatibility,
} from '@/api/planningCurrentApi/planningCurrentApi';
import { useParams } from 'next/navigation';
import { Compatibility } from '@/components/Compatibility/Compatibility';

export const CompatibilityTab = memo(() => {
  const params = useParams();
  const id = Number(params.id);
  const { data, isLoading, mutate } = useGetCurrentPurchaseCompatibility(id);

  return (
    <Compatibility
      id={id}
      data={data}
      warning={false}
      isLoading={isLoading}
      refreshTableData={mutate}
      onUpdateCell={updatePurchaseCompatibilityCell}
    />
  );
});

CompatibilityTab.displayName = 'CompatibilityTab';
