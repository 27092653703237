import React, { memo, useCallback } from 'react';
import { Flex } from 'antd';
import { DebounceInput } from '@/components';
import CrossIcon from '/public/icons/cross.svg';
import styles from './DocsHeader.module.scss';
import cx from 'classnames';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';
import { DatePeriodPicker } from '@/components/DatePeriodPicker/DatePeriodPicker';
import { DatePeriodPickerType } from '@/components/DatePeriodPicker/types';
import { PurchaseMethodsTreeSelect } from '@/components/PurchaseMethodsTreeSelect/PurchaseMethodsTreeSelect';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';
import { useDocsContext } from '@/widgets/Docs/context/useDocsContext';
import { useDocsActionsContext } from '@/widgets/Docs/context/useDocsActionsContext';

const clearIcon = {
  clearIcon: <CrossIcon className={styles.inputIcon} />,
};

export const DocsHeader = memo(() => {
  const { filter } = useDocsContext();
  const { setFilter } = useDocsActionsContext();

  const handleOkpdInputChange = useCallback(
    (value: string) => {
      setFilter((prev) => ({
        ...prev,
        search: value,
      }));
    },
    [setFilter],
  );

  const handleRegionsChange = useCallback(
    (value: number[]) => {
      setFilter((prev) => ({
        ...prev,
        regionIds: value,
      }));
      postBillingEvent({
        action: BillingEventType.DOCS_CHOOSE_REGION,
        value: value.join(','),
      });
    },
    [setFilter],
  );

  const handlePurchaseMethodsChange = useCallback(
    (value: number[]) => {
      setFilter((prev) => ({
        ...prev,
        purchaseMethodIds: value,
      }));
    },
    [setFilter],
  );

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) => {
      setFilter((prev) => ({
        ...prev,
        publishedFromDate: startDate,
        publishedToDate: endDate,
      }));
    },
    [setFilter],
  );

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={32}
        className={styles.header}
      >
        <div className={styles.title}>Поиск документации</div>
        <DebounceInput
          value={filter.search}
          onDebouncedChange={handleOkpdInputChange}
          className={cx('input-line', styles.input)}
          variant="borderless"
          placeholder="ОКПД2/КТРУ"
          allowClear={clearIcon}
        />
      </Flex>
      <Flex
        gap={32}
        wrap
      >
        <FormFieldRow
          labelMinWidth="auto"
          label="Дата публикации"
          field={
            <DatePeriodPicker
              onDatesChange={handleDatesChange}
              startDate={filter.publishedFromDate}
              endDate={filter.publishedToDate}
              startDatePlaceholder="Дата от..."
              endDatePlaceholder="Дата до..."
            />
          }
        />
        <RegionsTreeSelect
          placeholder={<p className={styles.treePlaceholder}>Все регионы</p>}
          className={styles.treeSelect}
          onRegionsChange={handleRegionsChange}
        />
        <PurchaseMethodsTreeSelect
          placeholder={<p className={styles.treePlaceholder}>Все способы закупки</p>}
          className={styles.treeSelect}
          onPurchaseMethodsChange={handlePurchaseMethodsChange}
        />
      </Flex>
    </div>
  );
});

DocsHeader.displayName = 'DocsHeader';
