import { memo } from 'react';
import { Flex } from 'antd';
import { SuppliersFilterSourceField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterSourceField/SuppliersFilterSourceField';
import { SuppliersFilterRegionsField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterRegionsField/SuppliersFilterRegionsField';
import { SuppliersFilterSmpField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterSmpField/SuppliersFilterSmpField';
import styles from './OkpdKtruSuppliersFilter.module.scss';
import { SuppliersFilterCountryField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterCountryField/SuppliersFilterCountryField';
import { SuppliersFilterContractDatePeriodField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterContractDatePeriodField/SuppliersFilterContractDatePeriodField';
import { SuppliersFilterPenaltyField } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterPenaltyField/SuppliersFilterPenaltyField';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { SuppliersSourceEnum } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';

export const OkpdKtruSuppliersFilter = memo(() => {
  const { filter } = useSuppliersContext();
  const isEisSource = filter.source === SuppliersSourceEnum.EIS;

  return (
    <Flex
      gap={32}
      className={styles.filter}
    >
      <Flex
        vertical
        gap={24}
      >
        <SuppliersFilterSourceField />
        <SuppliersFilterRegionsField />
        <SuppliersFilterSmpField />
      </Flex>

      {isEisSource && (
        <>
          <div className={styles.divider} />
          <Flex
            vertical
            gap={24}
          >
            <SuppliersFilterCountryField />
            <SuppliersFilterContractDatePeriodField />
            <SuppliersFilterPenaltyField />
          </Flex>
        </>
      )}
    </Flex>
  );
});

OkpdKtruSuppliersFilter.displayName = 'OkpdKtruSuppliersFilter';
