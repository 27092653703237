'use client';

import { memo } from 'react';
import styles from './NmckHistory.module.scss';
import { NmckHistoryHeader } from '@/widgets/NmckHistory/components/NmckHistoryHeader/NmckHistoryHeader';
import { NmckHistoryList } from '@/widgets/NmckHistory/components/NmckHistoryList/NmckHistoryList';
import { NmckHistoryListColumns } from '@/widgets/NmckHistory/components/NmckHistoryListColumns/NmckHistoryListColumns';

export const NmckHistoryPageContent = memo(() => {
  return (
    <>
      <NmckHistoryHeader />

      <div className={styles.main}>
        <NmckHistoryListColumns />
        <NmckHistoryList />
      </div>
    </>
  );
});

NmckHistoryPageContent.displayName = 'NmckHistoryPageContent';
