'use client';

import { memo } from 'react';
import { ConfigProvider, ConfigProviderProps, Table, TableProps } from 'antd';
import { NmckGridFooter } from '@/components/NmckGrid/NmckGridFooter/NmckGridFooter';
import styles from './NmckAnalysisTable.module.scss';
import { useNmckGrid } from '@/components/NmckGrid/hooks/useNmckGrid/useNmckGrid';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';

const tableScroll: TableProps['scroll'] = { x: 1140 };

const theme: ConfigProviderProps['theme'] = {
  components: {
    Table: {
      cellPaddingInline: 5,
    },
  },
};

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckAnalysisTable = memo(({ nmckId }: Props) => {
  const { columns, list, isLoading, rowClassName, expandable } = useNmckGrid({
    nmckId,
  });

  return (
    <ConfigProvider theme={theme}>
      <Table
        rowKey="id"
        className={styles.table}
        columns={columns}
        rowClassName={rowClassName}
        scroll={tableScroll}
        tableLayout="auto"
        expandable={expandable}
        dataSource={list}
        pagination={false}
        loading={isLoading}
      />
      <NmckGridFooter nmckId={nmckId} />
    </ConfigProvider>
  );
});

NmckAnalysisTable.displayName = 'NmckAnalysisTable';
