import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { Flex } from 'antd';
import { Characteristics, NPAList, StandardContract } from '@/widgets/OkpdKtru/components';
import cardStyles from '../Card.module.scss';
import style from './KtruCard.module.scss';
import { memo, useCallback } from 'react';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import { eventsGTM, sendEventGTM } from '@/metrika';
import cx from 'classnames';
import { KtruInfos } from '@/features';
import { isBoolean } from 'lodash';

type KtruCardProps = {
  ktru: KtruResult;
  showFromBookBadge?: boolean;
  showNPAList?: boolean;
  showContracts?: boolean;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
  onCodeClick?: (ktru: KtruResult) => void;
};

export const KtruCard = memo(
  ({
    ktru,
    showFromBookBadge = false,
    showNPAList = true,
    showContracts = true,
    setModalPageParams,
    onCodeClick,
  }: KtruCardProps) => {
    const templateBadgeLabel = ktru.isTemplate ? 'Укрупнённая' : 'Неукрупнённая';
    const fromBookBadgeLabel = showFromBookBadge && ktru.isFromBook && 'ОКПД2 из справочно';

    const handleCodeLinkClick = useCallback(() => {
      onCodeClick?.(ktru);

      sendEventGTM(eventsGTM.ktruCardClick, {
        value: ktru.name,
        info: setModalPageParams ? 'modal' : 'page',
      });

      setModalPageParams &&
        setModalPageParams((prev) => ({
          ...prev,
          page: OkpdKtruPageTypes.KTRU,
          code: ktru.code,
          searchString: '',
        }));
    }, [ktru, onCodeClick, setModalPageParams]);

    return (
      <Flex
        vertical
        gap={16}
        className={cardStyles.card}
      >
        <Flex
          vertical
          gap={8}
        >
          <Flex
            align="center"
            wrap
            className={style.title}
          >
            <OkpdLink
              href={`${pageRoutes.ktru}/${ktru.code}`}
              isLink={!setModalPageParams}
              className={cardStyles.cardLink}
              onClick={handleCodeLinkClick}
            >
              {ktru.code}
            </OkpdLink>

            {isBoolean(ktru.isTemplate) && <div className={style.badge}>{templateBadgeLabel}</div>}
            {fromBookBadgeLabel && (
              <div className={cx(style.badge, style.badgeOrange)}>{fromBookBadgeLabel}</div>
            )}
          </Flex>
          <div className={cardStyles.cardName}>{ktru.name}</div>
        </Flex>
        {ktru.ktruInfos && (
          <KtruInfos
            infos={ktru.ktruInfos}
            inCard
            setModalPageParams={setModalPageParams}
          />
        )}
        <Characteristics characteristics={ktru.characteristics} />
        {showNPAList && <NPAList npa={ktru.npa} />}
        {showContracts && (
          <Flex
            justify="space-between"
            align="center"
          >
            <StandardContract standardContract={ktru.standardContracts} />
          </Flex>
        )}
      </Flex>
    );
  },
);

KtruCard.displayName = 'KtruCard';
