import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { memo, useCallback } from 'react';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { DebounceInput } from '@/components';

type NameInputProps = {
  record: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridNameInput = memo(({ record, nmckId }: NameInputProps) => {
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();

  const handleChange = useCallback(
    async (value: string) => {
      sendEventGTM(eventsGTM.nmckRowNameChange, { value });
      await updateNmckRowWithFallback(nmckId, { ...record, name: value });
    },
    [nmckId, record, updateNmckRowWithFallback],
  );

  return (
    <DebounceInput
      value={record.name}
      placeholder={record.name}
      truncate
      onDebouncedChange={handleChange}
    />
  );
});

NmckGridNameInput.displayName = 'NmckGridNameInput';
