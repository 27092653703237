import { memo, useCallback } from 'react';
import { reestrLink, regNumberLink } from '@/widgets/CurrentPage/CurrentPage.constants';
import styles from './PlannedTableLinkedPurchaseLink.module.scss';
import ExportDiagonalIcon from 'public/icons/exportDiagonal.svg';
import Link from 'next/link';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  regNumber: string;
  href: typeof regNumberLink | typeof reestrLink;
};

export const PlannedTableLinkedPurchaseLink = memo(({ regNumber, href }: Props) => {
  const handleLinkClick = useCallback(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_GO_TO_EIS,
      value: href + regNumber,
    });
  }, [href, regNumber]);

  return (
    <Link
      href={href + regNumber}
      target="_blank"
      className={styles.purchaseLink}
      onClick={handleLinkClick}
    >
      {regNumber}
      <ExportDiagonalIcon />
    </Link>
  );
});

PlannedTableLinkedPurchaseLink.displayName = 'PlannedTableLinkedPurchaseLink';
