import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Table, TableProps } from 'antd';
import { PurchasePlanItem } from '@/api/currentApi/currentApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import Vicon from 'public/icons/v.svg';
import styles from './PlanedTable.module.scss';
import cx from 'classnames';
import { PlannedTableExpandedRow } from '@/widgets/CurrentPage/components/PlannedTable/components/PlannedTableExpandedRow/PlannedTableExpandedRow';
import { useGetPurchasePlanItems } from '@/api/currentApi/currentApi';
import { PlannedTableIkzLink } from '@/widgets/CurrentPage/components/PlannedTable/components/PlannedTableIkzLink/PlannedTableIkzLink';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

const expandedRowClassName = () => styles.expandedRow;

const expandedRowRender = (record: PurchasePlanItem) => <PlannedTableExpandedRow row={record} />;

export const PlannedTable = memo(() => {
  const { data, isLoading } = useGetPurchasePlanItems();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpandRow = useCallback(({ positionNumber }: PurchasePlanItem) => {
    setExpandedRowKeys((prev) => {
      if (prev.includes(positionNumber)) {
        return prev.filter((key) => key !== positionNumber);
      }

      return [...prev, positionNumber];
    });
  }, []);

  const columns = useMemo(
    (): TableProps<PurchasePlanItem>['columns'] => [
      {
        title: 'Наименование объекта закупки',
        dataIndex: 'name',
        key: 'name',
        width: 525,
      },
      {
        title: '',
        dataIndex: '',
        key: 'expand',
        width: 80,
        render: (_, record) => {
          return (
            <>
              {record.linkedPurchase.length !== 0 && (
                <Button
                  className={cx('button-with-icon', styles.linkedButton)}
                  type="link"
                  iconPosition="end"
                  icon={<Vicon className={styles.viconActive} />}
                  onClick={() => handleExpandRow(record)}
                >
                  <span>Связанные</span>
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: 'ИКЗ',
        dataIndex: 'ikz',
        key: 'ikz',
        width: 354,
        className: styles.ikzRow,
        render: (_, plannedPurchase: PurchasePlanItem) => (
          <PlannedTableIkzLink plannedPurchase={plannedPurchase} />
        ),
      },
      {
        title: `Фин. обесп. на ${moment().year()}, ₽`,
        dataIndex: 'currentFiscalYearPaymentPlan',
        key: 'currentFiscalYearPaymentPlan',
        className: styles.priceRow,
        width: 195,
        render: (_, { currentFiscalYearPaymentPlan }) => {
          return formatPrice(currentFiscalYearPaymentPlan);
        },
      },
      {
        title: 'Остаток, ₽',
        dataIndex: 'remains',
        key: 'remains',
        width: 110,
        className: styles.priceRow,
        render: (_, { remains }) => {
          return <p className={styles.remains}>{formatPrice(remains)}</p>;
        },
      },
      {},
    ],
    [handleExpandRow],
  );

  const rowClassName: TableProps<PurchasePlanItem>['rowClassName'] = useCallback(
    (record: PurchasePlanItem) => (expandedRowKeys.includes(record.ikz) ? styles.expandedRow : ''),
    [expandedRowKeys],
  );

  const getExpandable = useMemo(
    (): TableProps<PurchasePlanItem>['expandable'] => ({
      expandedRowKeys,
      expandedRowRender,
      expandedRowClassName,
    }),
    [expandedRowKeys],
  );

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_PLANNED,
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey="positionNumber"
        expandable={getExpandable}
        loading={isLoading}
        columns={columns}
        dataSource={data?.results}
        className={styles.table}
        pagination={false}
        rowClassName={rowClassName}
      />
    </div>
  );
});

PlannedTable.displayName = 'PlannedTable';
