'use client';

import { memo } from 'react';
import { NmckHistoryProvider } from '@/widgets/NmckHistory/context/NmckHistoryContext';
import { NmckHistoryPageContent } from '@/widgets/NmckHistory/NmckHistoryPageContent';

export const NmckHistoryPage = memo(() => {
  return (
    <NmckHistoryProvider>
      <NmckHistoryPageContent />
    </NmckHistoryProvider>
  );
});

NmckHistoryPage.displayName = 'NmckHistoryPage';
