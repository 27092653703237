'use client';

import { memo, useCallback, useState } from 'react';
import { BugReportModal } from '@/features/BugReport/components/BugReportModal/BugReportModal';
import { BugReportButton } from '@/features/BugReport/components/BugReportButton/BugReportButton';

export const BugReport = memo(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div>
      <BugReportButton onClick={handleButtonClick} />
      <BugReportModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
      />
    </div>
  );
});

BugReport.displayName = 'BugReport';
