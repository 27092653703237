import { NmckByIdResponse, VariationColor } from '@/api/nmckApi/nmckApiTypes';
import {
  PageableResponseWithFilters,
  TokenBasedPageableResponse,
  TokenBasedPageableResponseWithFilters,
} from '@/api/commonApi/commonApiTypes';
import { DirectContract } from '@/api/reestrDirectApi/reestrDirectApiTypes';

export enum MethodUsedType {
  NOTHING = 'NOTHING',
  LIMITS = 'LIMITS',
  CONTRACTS = 'CONTRACTS',
}

export enum NmckDrugsCalculationMethod {
  MINIMAL = 'MINIMAL',
  AVERAGE = 'AVERAGE',
}

export type NmckDrugsRow = {
  id: number;
  rowNumber: number;
  smnnUUID: string | null;
  klpUUID?: string | null;
  mnn: string | null;
  tn: string | null;
  dosageFullForm: string | null;
  drugForm: string | null;
  interchangeSmnnUuids: unknown[];
  isZnvlp: boolean | null;
  unit: string; // Ед. измерения
  unitCode: number | null; // Ед. измерения (code)
  amount: number; // Количество
  priceLimits: LimitPrice[];
  contracts: ContractPrice[];
  averageContracts: AverageContractPrice[];
  directContracts: DirectContract[];
  kps: KPPrice[];
  esklpCode: string | null;
  nds: number;
  wholesaleAddition: number | null; // Опт. надбавка
  nmck: number;
  limitCalculationMethod: NmckDrugsCalculationMethod;
  contractCalculationMethod: NmckDrugsCalculationMethod;
  unitPrice: number;
  priceLimitMinPrice: number | null;
  priceLimitAvgPrice: number | null;
  contractsMinPrice: number | null;
  contractsAvgPrice: number | null;
  averagePrice: number | null;
  contractsPriceVariation: number;
  variationColor: VariationColor;
  methodUsed: MethodUsedType;

  limitPriceMessage: string | null;
  limitPriceDisabled: boolean;
  marketPriceMessage: string | null;
  marketPriceDisabled: boolean;
  avgPriceMessage: string | null;
  avgPriceDisabled: boolean;

  maxPriceLimit: LimitPrice | null;
};

export type NmckDrugsByIdResponse = Omit<NmckByIdResponse, 'rows'> & {
  rows: NmckDrugsRow[];
};

export type Drug = {
  smnnUUID: string;
  stdMnnName: string;
  stdLfName: string;
  stdDosage: string;
  smnnPe: string;
  isZnvlp: boolean;
  smnnCode: string;
  interchangeDrugs: InterchangeDrug[];
};

export type InterchangeDrug = {
  name: string;
  unit: string;
  drugs: EquivalentDrug[];
  uuids: string;
};

export type EquivalentDrug = {
  uuid: string;
  name: string;
  rate: number;
  mnName: string;
  lfName: string;
  dosage: string;
};

export type LimitPrice = {
  priceLimitId: number;
  klpCode: string;
  mnn: string;
  tn: string;
  extendedName: string;
  dosage: string;
  form: string;
  owner: string;
  consumerTotal: number;
  maxPrice: number;
  certificateNumber: string;
  priceRegistrationDate: string;
  priceRegistrationDecisionNumber: string;
  barcode: string;
  priceForCalculation: number;
  primaryPackageDrugFormsQuantity?: number;
  primaryPackageQuantity?: number;
  go: boolean;
  goLink: string;
};

export type ContractPrice = {
  id: number;
  eisId: number;
  externalSid?: string;
  procedureDrugId?: number;
  mnn?: string;
  tn?: string;
  dosageFullForm?: string;
  extendedName: string;
  drugForm?: string;
  primaryPackageName: string;
  primaryPackageQuantity: number;
  secondaryPackageQuantity: number;
  primaryPackageDrugFormsQuantity: number;
  contractDrugFormsQuantity: number;
  price: number;
  conclusionDate: string;
  packagePrice: number;
  packageQuantity: number;
  countPackaged: number;
  nds: number;
  supplierName: string;
  supplierRegion: string;
  consumerRegion: string;
  smp: boolean;
  purchaseMethod: string;
  manufacturer: string;
  reestrNumber: string;
  phoneNumber: string;
  email: string;
  wholesaleAddition: number;
  maxIncreasePriceRatioCoeff: number | null;
  maxIncreasePricePeriodRatioCoeff: number | null;
  dosageUnits: number;
  priceWithAppliedKoeff: number;
  priceForCalculation: number;
};

export type KPPrice = {
  nmckDrugsMarketKpId: number;
  supplier: string;
  pricePerPackage: number;
  priceForCalculation: number;
  nds: number;
  wholesaleAddition: number;
  amountInPackage: number;
  dosageUnits: number;
};

export type AverageContractPrice = {
  id: number;
  eisId: number;
  externalSid?: string;
  procedureDrugId?: number;
  directContractId?: number;
  mnn: string;
  tn: string;
  extendedName: string;
  drugForm: string;
  dosage: string;
  dosageFullForm: string;
  supplierName: string;
  supplierPhone: string;
  supplierEmail: string;
  supplierDate: string;
  contractNumber?: string;
  conclusionDate: string;
  executionDate: string;
  reestrNumber: string;
  manufacturer: string;
  packagePrice: number;
  packageQuantity: number;
  primaryPackageName: string;
  primaryPackageQuantity: number;
  secondaryPackageQuantity: number;
  primaryPackageDrugFormsQuantity: number;
  contractDrugFormsQuantity: number;
  updateDate: string;
  nds: number;
  wholesaleAddition: number;
  dosageUnits: number;
  priceForCalculation: number;
  price: number;
  countPackaged: number;
  smp: boolean;
  exactDosage: boolean;
  exactDrugForm: boolean;
  purchaseMethod: string;
};

export type LimitPriceResponse = PageableResponseWithFilters<LimitPrice>;
export type ContractPriceResponse = TokenBasedPageableResponseWithFilters<ContractPrice>;
export type AverageContractPriceResponse = TokenBasedPageableResponse<AverageContractPrice>;
