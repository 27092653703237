import { memo, useCallback } from 'react';
import styles from './NmckHistoryList.module.scss';
import { NmckHistoryCard } from '@/widgets/NmckHistory/components/NmckHistoryList/components/NmckHistoryCard/NmckHistoryCard';
import { List, ListProps } from 'antd';
import { useNmckHistoryContext } from '@/widgets/NmckHistory/context/useNmckHistoryContext';
import { NmckCommon } from '@/api/nmckApi/nmckApiTypes';

export const NmckHistoryList = memo(() => {
  const {
    historyResponse: { isLoading, data },
  } = useNmckHistoryContext();

  const renderItem: ListProps<NmckCommon>['renderItem'] = useCallback(
    (nmck: NmckCommon) => <NmckHistoryCard nmck={nmck} />,
    [],
  );

  return (
    <List
      loading={isLoading}
      className={styles.list}
      dataSource={data?.results}
      renderItem={renderItem}
    />
  );
});

NmckHistoryList.displayName = 'NmckHistoryList';
