'use client';

import { OkpdDetailBreadcrumbs, OkpdDetailTabs } from '@/widgets/OkpdDetail/components';
import styles from './OkpdDetailPage.module.scss';
import { Spin } from 'antd';
import { memo, useEffect } from 'react';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { useGetOkpdDetail } from '@/api/okpdApi/okpdApi';
import { OkpdKtruDetailInfo } from '@/components/OkpdKtruDetail/OkpdKtruDetailInfo/OkpdKtruDetailInfo';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type OkpdDetailPage = {
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdDetailPage = memo(({ code, setModalPageParams }: OkpdDetailPage) => {
  const { data, isLoading } = useGetOkpdDetail(code);

  useEffect(() => {
    sendEventGTM(eventsGTM.okpdDetailPageView, {
      value: code,
      info: setModalPageParams ? 'modal' : 'page',
    });
  }, [code, setModalPageParams]);

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.OKPD_OPEN,
      value: code,
    });
  }, [code]);

  if (!isLoading && !data?.id)
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperError}>ОКПД с таким кодом не существует</div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Spin />
        </div>
      )}
      {!isLoading && data && (
        <>
          <div className={styles.content}>
            <OkpdDetailBreadcrumbs
              parents={data.parents}
              setModalPageParams={setModalPageParams}
            />
            <OkpdKtruDetailInfo
              data={data}
              setModalPageParams={setModalPageParams}
            />
          </div>
          <OkpdDetailTabs
            okpd={data}
            code={code}
            setModalPageParams={setModalPageParams}
          />
        </>
      )}
    </div>
  );
});
OkpdDetailPage.displayName = 'OkpdDetailPage';
