import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterPurchaseMethod.module.scss';
import { PurchaseMethodsTreeSelect } from '@/components/PurchaseMethodsTreeSelect/PurchaseMethodsTreeSelect';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';

export const ContractsFilterPurchaseMethod = memo(() => {
  const { filter } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handlePurchaseMethodsChange = useCallback(
    (values: number[]) => {
      setFilter((prev) => ({ ...prev, purchaseMethodIds: values }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Способ закупки"
      field={
        <PurchaseMethodsTreeSelect
          value={filter.purchaseMethodIds}
          className={styles.select}
          onPurchaseMethodsChange={handlePurchaseMethodsChange}
        />
      }
    />
  );
});

ContractsFilterPurchaseMethod.displayName = 'ContractsFilterPurchaseMethod';
