import { memo } from 'react';
import { Flex } from 'antd';
import { CompatibilityWarning } from '@/components/Compatibility/CompatibilityWarning/CompatibilityWarning';
import { CompatibilityFilter } from '@/components/Compatibility/CompatibilityFilter/CompatibilityFilter';
import { CompatibilityTable } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable';
import { CompatibilityProps } from '@/components/Compatibility/Compatibility.types';
import { useCompatibility } from '@/components/Compatibility/useCompatibility';
import styles from './Compatibility.module.scss';

export const Compatibility = memo(
  ({
    id,
    data,
    isLoading,
    warning,
    warningText,
    isDrugs,

    onUpdateCell,
    refreshTableData,
    withVerticalScroll,
    onCreateMedDeviceSelection,
    onDeleteMedDeviceSelection,
    onDeleteRow,
  }: CompatibilityProps) => {
    const { rows, rawRows, activeTab, setActiveTab } = useCompatibility({
      data,
    });

    return (
      <Flex vertical>
        <CompatibilityWarning
          content={warningText}
          hasCompatibility={warning}
          className={styles.warning}
        />
        <CompatibilityFilter
          activeKey={activeTab}
          setActiveKey={setActiveTab}
          rows={rawRows}
        />
        <CompatibilityTable
          id={id}
          header={data?.header ?? []}
          rows={rows}
          isLoading={isLoading}
          isDrugs={isDrugs}
          withVerticalScroll={withVerticalScroll}
          refreshTableData={refreshTableData}
          onUpdateCell={onUpdateCell}
          onCreateMedDeviceSelection={onCreateMedDeviceSelection}
          onDeleteMedDeviceSelection={onDeleteMedDeviceSelection}
          onDeleteRow={onDeleteRow}
        />
      </Flex>
    );
  },
);

Compatibility.displayName = 'Compatibility';
