import type { TabsProps } from 'antd';
import { DeadlineType } from '@/api/skpApi/skpApiTypes';

export const skpPageTabs: TabsProps['items'] = [
  {
    key: DeadlineType.PROCEDURES44,
    label: 'Процедуры (44-Ф3)',
  },
  {
    key: DeadlineType.PROCEDURES223,
    label: 'Процедуры (223-Ф3)',
  },
  {
    key: DeadlineType.CONTRACTS44,
    label: 'Контракты (44-Ф3)',
  },
];
