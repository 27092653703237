import { memo, useMemo } from 'react';
import { Modal, ModalProps } from 'antd';
import { UiModalProps } from '@/components/Ui/UiModal/UiModal.types';
import { UiModalTitle } from '@/components/Ui/UiModal/components/UiModalTitle/UiModalTitle';
import styles from './UiModal.module.scss';
import cx from 'classnames';

export const UiModal = memo(
  ({
    title,
    titleButtons,
    bodyColor = 'default',
    centered = true,
    footer = null,
    children,
    className,
    classNames,
    ...props
  }: UiModalProps) => {
    const formattedClassNames = useMemo(
      (): ModalProps['classNames'] => ({
        content: cx(styles.content, classNames?.content, {
          [styles.contentGray]: bodyColor === 'default' && !footer,
        }),
        header: cx(styles.header, classNames?.header),
        body: cx(styles.body, classNames?.body, {
          [styles.bodyWhite]: bodyColor === 'white',
        }),
        footer: cx(styles.footer, classNames?.footer),
      }),
      [
        bodyColor,
        classNames?.body,
        classNames?.content,
        classNames?.footer,
        classNames?.header,
        footer,
      ],
    );

    return (
      <Modal
        title={
          <UiModalTitle
            title={title}
            titleButtons={titleButtons}
          />
        }
        classNames={formattedClassNames}
        className={cx(styles.modal, className)}
        centered={centered}
        footer={footer}
        {...props}
      >
        {children}
      </Modal>
    );
  },
);

UiModal.displayName = 'UiModal';
