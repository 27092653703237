import useSWR, { preload } from 'swr';
import { deleteData, downloadFile, fetcher, getData, postData } from '@/api/commonApi/commonApi';
import {
  CharacteristicForFilter,
  KtruResponse,
  Tru,
  TruCountResponse,
} from '@/api/okpdApi/okpdApiTypes';
import {
  AllNpa,
  KtruDetail,
  OkpdDetail,
  OkpdInitialList,
  OkpdResponse,
  SuppliersResponse,
} from './okpdApiTypes';
import { generateOkpdKtruRequestUrl, generateUrl } from '@/widgets/OkpdKtru/utils';
import { formatDate } from '@/utils';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import {
  SuppliersRequestParams,
  SuppliersSourceEnum,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import useSWRImmutable from 'swr/immutable';
import useSWRInfinite from 'swr/infinite';
import { getSWRInfinitePaginationToken } from '@/utils/getSWRInfinitePaginationToken';
import { DEFAULT_OKPD_KTRU_PAGINATION_COUNT } from '@/widgets/OkpdKtru/constants';
import { dateFormat } from '@/constants';
import {
  CompatibilityResponse,
  CompatibilityMedDeviceSelectionRequestParams,
  CompatibilityRow,
  CompatibilityUpdateBody,
} from '@/api/compatibilityApi/compatibilityApiTypes';

export const useGetOkpdInitialList = () => {
  return useSWR('/okpd-list', getData<OkpdInitialList>, {
    revalidateOnFocus: false,
  });
};

export const useGetAllNpa = () => {
  return useSWR('/all-npa', getData<AllNpa>, {
    revalidateOnFocus: false,
  });
};

export const useGetKtruCharacteristics = ({
  okpdCode,
  searchString,
}: {
  okpdCode?: string;
  searchString?: string;
}) => {
  return useSWR(
    () =>
      okpdCode || searchString
        ? generateUrl('/ktru-characteristics', {
            okpdCode,
            searchstring: searchString,
          })
        : null,
    getData<CharacteristicForFilter[]>,
    {
      revalidateOnFocus: false,
    },
  );
};

export const useGetSuppliersInfinite = ({ filter, pageSize }: SuppliersRequestParams) => {
  return useSWRInfinite<SuppliersResponse>(
    (index: number, previousPageData: SuppliersResponse) => {
      const isEisSource = filter.source === SuppliersSourceEnum.EIS;
      const params = new URLSearchParams();
      const token = getSWRInfinitePaginationToken<SuppliersResponse>(index, previousPageData);

      if (token) params.append('token', token);
      params.append('pageSize', String(pageSize));
      if (filter.okpdCode) params.append('okpdCode', filter.okpdCode);
      if (filter.ktruCode) params.append('ktruCode', filter.ktruCode);

      filter.region.forEach((item) => {
        params.append('regions', String(item));
      });
      params.append('smp', String(filter.smp));
      params.append('source', filter.source);

      if (isEisSource) {
        params.append('hasPenalty', String(filter.hasPenalty));
        if (filter.country) params.append('countries', filter.country);

        if (filter.contractDateFrom)
          params.append(
            'contractDateFrom',
            formatDate(filter.contractDateFrom, dateFormat.serverFormat),
          );

        if (filter.contractDateTo)
          params.append(
            'contractDateTo',
            formatDate(filter.contractDateTo, dateFormat.serverFormat),
          );
      }

      return `/suppliers?${params}`;
    },
    getData<SuppliersResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
      errorRetryCount: 1,
    },
  );
};

export const useGetOkpdList = (filter: OkpdKtruListFilter) => {
  return useSWRImmutable(generateOkpdKtruRequestUrl('/okpd', { filter }), getData<OkpdResponse>);
};

export const useGetOkpdListInfinite = (filter: OkpdKtruListFilter) => {
  return useSWRInfinite<OkpdResponse>(
    (index, previousPageData: OkpdResponse) => {
      const token = getSWRInfinitePaginationToken<OkpdResponse>(index, previousPageData);
      return generateOkpdKtruRequestUrl('/okpd', {
        filter,
        pagination: { token, count: DEFAULT_OKPD_KTRU_PAGINATION_COUNT },
      });
    },
    getData<OkpdResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
      errorRetryCount: 1,
    },
  );
};

export const useGetKtruListInfinite = (filter: OkpdKtruListFilter) => {
  return useSWRInfinite<KtruResponse>(
    (index, previousPageData: KtruResponse) => {
      const token = getSWRInfinitePaginationToken<KtruResponse>(index, previousPageData);
      return generateOkpdKtruRequestUrl('/ktru', {
        filter,
        pagination: { token, count: DEFAULT_OKPD_KTRU_PAGINATION_COUNT },
        endpointVersion: 'V2',
      });
    },
    getData<KtruResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
      errorRetryCount: 1,
    },
  );
};

export const useGetOkpdDetail = (code: string) => {
  return useSWRImmutable(code ? `/okpd/${code}` : null, getData<OkpdDetail>);
};

export const getOkpdDetail = (code: string) => {
  return preload(`/okpd/${code}`, getData<OkpdDetail>);
};

export const getKtruDetail = (code: string) => {
  return preload(`/ktru/${code}`, getData<KtruDetail>);
};

export const useGetKtruDetail = (code: string) => {
  return useSWRImmutable(code ? `/ktru/${code}` : null, getData<KtruDetail>);
};

export const useGetTruCount = () => {
  return useSWRImmutable('/tru-list/count', getData<TruCountResponse>);
};

export const useGetTruList = ({ searchString }: { searchString?: string }) => {
  const params = new URLSearchParams();
  searchString && params.append('searchstring', searchString);

  const url = searchString ? `/nmck-tru?${params}` : null;

  return useSWR(url, getData<Tru[]>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const useGetTruCompatibility = () => {
  const params = new URLSearchParams();
  params.append('v3', 'true');

  return useSWR(`/tru-list?${params}`, getData<CompatibilityResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const postOkpdKtruToTRUList = (
  body: Partial<{
    okpdCode: string;
    ktruCode: string;
    nkmiId: NmckRow['nkmiId'];
  }>,
) => postData('/tru-list', body);

export const createCalculationFromTru = () => {
  return postData<number[]>('/tru-list/nmck');
};

export const postTruCompatibilityMedDeviceSelection = ({
  rowId,
}: CompatibilityMedDeviceSelectionRequestParams) => {
  return postData<CompatibilityResponse>(`/tru-list/${rowId}/med-device-selection`);
};

export const deleteTruCompatibilityMedDeviceSelection = ({
  rowId,
}: CompatibilityMedDeviceSelectionRequestParams) => {
  return deleteData<CompatibilityResponse>(
    `/tru-list/${rowId}/med-device-selection`,
  ) as Promise<CompatibilityResponse>;
};

export const updateTruCompatibilityCell = (
  _: unknown,
  rowId: number,
  body: CompatibilityUpdateBody,
) => postData<void>(`/tru-list/${rowId}/compatibility-user-selection`, body);

export const getTruCompatibilityXlsx = async () => {
  const response = await fetcher('/tru-list/xlsx');
  await downloadFile(response, 'Список позиций ОКПД2/КТРУ');
};

export const deleteTruCompatibilityList = () => deleteData('/tru-list');

export const deleteTruCompatibilityRow = (rowId: CompatibilityRow['id']) =>
  deleteData(`/tru-list/${rowId}`);

export const getOkpdXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/okpd/${code}/xlsx`);
  await downloadFile(response, fileName);
};

export const getKtruXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/ktru/${code}/xlsx`);
  await downloadFile(response, fileName);
};
