import { memo, useCallback } from 'react';
import { ReportFormModal, ReportFormModalProps } from '@/features';
import { postUserFeedback } from '@/api/userApi/userApi';
import { notification } from 'antd';
import { BUG_REPORT_MODAL_CONFIG } from '@/features/BugReport/components/BugReportModal/BugReportModal.constants';

type Props = Pick<ReportFormModalProps, 'isOpen' | 'onClose'>;

export const BugReportModal = memo(({ isOpen, onClose }: Props) => {
  const handleSubmit = useCallback<ReportFormModalProps['onSubmit']>(
    async ({ email, feedback }) => {
      await postUserFeedback({
        email,
        feedback,
      });
      onClose();
      notification.success({
        message: 'Спасибо, ваше обращение отправлено',
      });
    },
    [onClose],
  );

  return (
    <ReportFormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      config={BUG_REPORT_MODAL_CONFIG}
    />
  );
});

BugReportModal.displayName = 'BugReportModal';
