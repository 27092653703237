import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, ConfigProvider, Flex, Table, TableProps } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import {
  columns,
  components,
  modifiers,
  scroll,
  theme,
} from '@/components/Table/TableColumnsSettings/constants';
import { OrderedColumnTypeMap } from '@/components/Table/TableColumnsSettings/types';
import { getOrderedColumns, getStringKey } from '@/components/Table/TableColumnsSettings/utils';
import styles from './TableColumnsSettings.module.scss';
import SettingsIcon from 'public/icons/settings.svg';
import { UiModal } from '@/components';

type Props<TRecord> = {
  orderedColumnMap: OrderedColumnTypeMap<TRecord>;
  setOrderedColumnMap: Dispatch<SetStateAction<OrderedColumnTypeMap<TRecord>>>;
  columnsData: ColumnType<TRecord>[];
};

const MIN_SELECTED_COLUMNS_COUNT = 3;

//eslint-disable-next-line comma-spacing
export const TableColumnsSettingsComponent = <TRecord,>({
  orderedColumnMap,
  setOrderedColumnMap,
  columnsData,
}: Props<TRecord>) => {
  const [isColumnSettingsModalOpen, setIsColumnSettingsModalOpen] = useState(false);
  const [localOrderedColumnMap, setLocalOrderedColumnMap] = useState<OrderedColumnTypeMap<TRecord>>(
    () => orderedColumnMap,
  );
  const localOrderedColumns = useMemo(
    () => getOrderedColumns(columnsData, localOrderedColumnMap),
    [columnsData, localOrderedColumnMap],
  );

  const selectedRowKeys = useMemo(
    () =>
      Object.values(localOrderedColumnMap)
        .filter(({ hidden }: ColumnType<TRecord>) => !hidden)
        .map(({ key }: ColumnType<TRecord>) => key!),
    [localOrderedColumnMap],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const itemIds: string[] = useMemo(
    () => localOrderedColumns.map((column: ColumnType<TRecord>) => getStringKey(column.key)),
    [localOrderedColumns],
  );

  const onSelectAllButtonClick = useCallback(() => {
    itemIds.forEach((key) => {
      setLocalOrderedColumnMap((prev) => {
        const currentKey = getStringKey(key);
        return {
          ...prev,
          [currentKey]: {
            ...prev[currentKey],
            hidden: false,
          },
        };
      });
    });
  }, [itemIds]);

  const onHideAllButtonClick = useCallback(() => {
    itemIds.forEach((key) => {
      setLocalOrderedColumnMap((prev) => {
        const currentKey = getStringKey(key);
        return {
          ...prev,
          [currentKey]: {
            ...prev[currentKey],
            hidden: true,
          },
        };
      });
    });
  }, [itemIds]);

  const onSaveButtonClick = useCallback(() => {
    setOrderedColumnMap(localOrderedColumnMap);
    setIsColumnSettingsModalOpen(false);
  }, [localOrderedColumnMap, setOrderedColumnMap]);

  const tableFooter: TableProps['footer'] = useCallback(
    () => (
      <Flex justify="space-between">
        <Button
          type="primary"
          onClick={onSaveButtonClick}
          disabled={selectedRowKeys.length < MIN_SELECTED_COLUMNS_COUNT}
        >
          Сохранить
        </Button>
        <Flex
          align="center"
          gap={24}
        >
          <Button
            onClick={onSelectAllButtonClick}
            disabled={selectedRowKeys.length === columnsData.length}
          >
            Выбрать все
          </Button>
          <Button
            onClick={onHideAllButtonClick}
            disabled={selectedRowKeys.length === 0}
          >
            Отменить все
          </Button>
        </Flex>
      </Flex>
    ),
    [
      columnsData.length,
      onHideAllButtonClick,
      onSaveButtonClick,
      onSelectAllButtonClick,
      selectedRowKeys.length,
    ],
  );

  const onRowSelect = useCallback(
    ({ key: columnKey }: ColumnType<TRecord>) => {
      setLocalOrderedColumnMap((prev) => {
        const key = getStringKey(columnKey);
        const selectedRow = localOrderedColumnMap[key];

        return {
          ...prev,
          [key]: {
            ...selectedRow,
            hidden: !selectedRow.hidden,
          },
        };
      });
    },
    [localOrderedColumnMap],
  );

  const rowSelection: TableProps<ColumnType<TRecord>>['rowSelection'] = useMemo(
    () => ({
      selectedRowKeys: selectedRowKeys,
      onSelect: onRowSelect,
      hideSelectAll: true,
    }),
    [selectedRowKeys, onRowSelect],
  );

  const handleColumnsSettingsClick = useCallback(() => {
    setIsColumnSettingsModalOpen(true);
  }, []);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      setLocalOrderedColumnMap((prev) => {
        const activeColumn = prev[active.id];
        const overColumn = prev[over.id];

        return {
          ...prev,
          [getStringKey(activeColumn.key)]: {
            ...activeColumn,
            order: overColumn.order,
          },
          [getStringKey(overColumn.key)]: {
            ...overColumn,
            order: activeColumn.order,
          },
        };
      });
    }
  }, []);

  const handleCancel = useCallback(() => setIsColumnSettingsModalOpen(false), []);

  return (
    <>
      <Button
        icon={<SettingsIcon />}
        iconPosition="start"
        className="button-with-icon"
        onClick={handleColumnsSettingsClick}
      >
        Настроить колонки
      </Button>
      <UiModal
        open={isColumnSettingsModalOpen}
        title="Настройка таблицы"
        bodyColor="white"
        onCancel={handleCancel}
      >
        <ConfigProvider theme={theme}>
          <DndContext
            sensors={sensors}
            modifiers={modifiers}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={itemIds}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={components}
                rowKey="key"
                scroll={scroll}
                pagination={false}
                rowSelection={rowSelection}
                dataSource={localOrderedColumns}
                columns={columns}
                footer={tableFooter}
                className={styles.table}
              />
            </SortableContext>
          </DndContext>
        </ConfigProvider>
      </UiModal>
    </>
  );
};

export const TableColumnsSettings = memo(TableColumnsSettingsComponent) as <TRecord>(
  props: Props<TRecord>,
) => ReactNode;
