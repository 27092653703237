import { memo, useCallback } from 'react';
import { DatePeriodPicker } from '@/components/DatePeriodPicker/DatePeriodPicker';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';
import { DatePeriodPickerType } from '@/components/DatePeriodPicker/types';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';

export const SuppliersFilterContractDatePeriodField = memo(() => {
  const { filter } = useSuppliersContext();
  const { setFilter } = useSuppliersActionsContext();

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) => {
      setFilter((prev) => ({
        ...prev,
        contractDateFrom: startDate,
        contractDateTo: endDate,
      }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Контракт заключён"
      rowHeight={40}
      field={
        <DatePeriodPicker
          startDate={filter.contractDateFrom}
          endDate={filter.contractDateTo}
          onDatesChange={handleDatesChange}
        />
      }
    />
  );
});

SuppliersFilterContractDatePeriodField.displayName = 'SuppliersFilterContractDatePeriodField';
