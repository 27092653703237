import { memo } from 'react';
import { UiModalProps } from '@/components/Ui/UiModal';
import { Flex } from 'antd';
import styles from './UiModalTitle.module.scss';

type Props = Pick<UiModalProps, 'title' | 'titleButtons'>;

export const UiModalTitle = memo(({ title, titleButtons }: Props) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      gap={32}
    >
      <h3 className={styles.title}>{title}</h3>
      {titleButtons && (
        <Flex
          align="center"
          gap={16}
        >
          {titleButtons}
        </Flex>
      )}
    </Flex>
  );
});

UiModalTitle.displayName = 'UiModalTitle';
