import { InputNumberProps } from 'antd';
import {
  FocusEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { InputNumberRef } from 'rc-input-number';
import { UiInputNumber } from '@/components';

export type DebounceInputNumberValueType = number | null;

export type DebounceInputNumberUpdatedOptions = {
  field: string;
};

type DebounceInputProps = {
  field?: string;
  debounceMs?: number;
  onDebouncedChange: (
    value: DebounceInputNumberValueType,
    options: DebounceInputNumberUpdatedOptions,
  ) => void;
  syncOnBlurOnly?: boolean;
} & InputNumberProps<number>;

export const DebounceInputNumber = memo(
  forwardRef<InputNumberRef, DebounceInputProps>(
    (
      {
        value,
        field = '',
        debounceMs = 500,
        syncOnBlurOnly = true,
        onDebouncedChange,
        onBlur,
        onFocus,
        ...inputProps
      }: DebounceInputProps,
      ref,
    ) => {
      const [inputValue, setInputValue] = useState(value);
      const isFocusedRef = useRef(false);

      const debouncedChangeValue = useMemo(
        () =>
          debounce((value: DebounceInputNumberValueType) => {
            onDebouncedChange(value, { field });
          }, debounceMs),
        [debounceMs, onDebouncedChange, field],
      );

      const handleInputChange = useCallback(
        (value: DebounceInputNumberValueType) => {
          setInputValue(value);
          debouncedChangeValue(value);
        },
        [debouncedChangeValue],
      );

      const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          isFocusedRef.current = true;
          onFocus?.(event);
        },
        [onFocus],
      );

      const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          isFocusedRef.current = false;
          setInputValue(value);
          onBlur?.(event);
        },
        [onBlur, value],
      );

      useEffect(() => {
        if (!syncOnBlurOnly || !isFocusedRef.current) {
          setInputValue(value);
        }
      }, [value, syncOnBlurOnly]);

      return (
        <UiInputNumber
          {...inputProps}
          ref={ref}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      );
    },
  ),
);

DebounceInputNumber.displayName = 'DebounceInputNumber';
