import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Flex, Form, FormItemProps, Input, Tooltip } from 'antd';
import MailIcon from '/public/icons/mail.svg';
import CopyIcon from '/public/icons/copy.svg';
import styles from './OkpdKtruRequestKP.module.scss';
import { openMailTo } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/utils';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { useGetUserInfo } from '@/api/userApi/userApi';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { tooltipOverlayInnerStyleForShortTitle } from '@/constants/ui';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { getUserEmailFromStorage, saveUserEmailToStorage } from '@/utils';

type Props = {
  receivers: string[];
  okpdKtruDetailData: OkpdDetail | KtruDetail;
  onRequestKP: () => void;
};

const emailRules: FormItemProps['rules'] = [
  {
    required: true,
    message: '',
  },
  {
    type: 'email',
    message: 'Некорректный формат email',
  },
];

const formInitialValues = { email: getUserEmailFromStorage() };

export const OkpdKtruRequestKP = memo(({ receivers, okpdKtruDetailData, onRequestKP }: Props) => {
  const { data: userData } = useGetUserInfo();
  const [form] = Form.useForm();
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(true);
  const isRequestKpButtonDisabled = isEmailIncorrect || !receivers.length;
  const isCopyButtonDisabled = !receivers.length;
  const values = Form.useWatch([], form);

  const handleRequestKpButtonClick = useCallback(() => {
    const email = form.getFieldValue('email');

    sendEventGTM(eventsGTM.requestKP, { value: email });
    saveUserEmailToStorage(email);
    openMailTo(email, receivers, {
      name: okpdKtruDetailData.name,
      code: okpdKtruDetailData.code,
      inn: userData ? userData.inn : '—',
      kpp: userData ? userData.kpp : '—',
      orgShortName: userData?.shortName ?? '',
      count: 1,
      unit: okpdKtruDetailData.unit ?? '—',
    });
    onRequestKP();
  }, [
    form,
    receivers,
    okpdKtruDetailData.name,
    okpdKtruDetailData.code,
    okpdKtruDetailData.unit,
    userData,
    onRequestKP,
  ]);

  const handleCopyButtonClick = useCallback(() => {
    copyToClipboard(receivers.join(', '));
  }, [receivers]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsEmailIncorrect(false))
      .catch(() => setIsEmailIncorrect(true));
  }, [form, values, receivers]);

  return (
    <Form
      form={form}
      initialValues={formInitialValues}
    >
      <Flex
        gap={30}
        align="center"
      >
        <Form.Item
          name="email"
          rules={emailRules}
          className={styles.formItem}
        >
          <Input
            className={styles.input}
            placeholder="Адрес электронной почты"
          />
        </Form.Item>
        <Tooltip
          placement="top"
          title={isRequestKpButtonDisabled && 'Выберите поставщиков и заполните почту'}
          overlayInnerStyle={tooltipOverlayInnerStyleForShortTitle}
        >
          <Button
            className="button-with-icon"
            type="primary"
            icon={<MailIcon />}
            disabled={isRequestKpButtonDisabled}
            onClick={handleRequestKpButtonClick}
          >
            Запросить КП
          </Button>
        </Tooltip>
        <Button
          className="button-with-icon"
          type="link"
          icon={<CopyIcon />}
          disabled={isCopyButtonDisabled}
          onClick={handleCopyButtonClick}
        >
          Скопировать все адреса э/почты
        </Button>
      </Flex>
    </Form>
  );
});

OkpdKtruRequestKP.displayName = 'OkpdKtruRequestKP';
