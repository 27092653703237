'use client';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { InitialPage, KtruList, OkpdList } from '@/widgets/OkpdKtru/components';
import styles from './OkpdKtruPage.module.scss';
import { CharacteristicsFilter } from '@/widgets';
import { CharacteristicsParam } from '@/types/types';
import { Flex } from 'antd';
import {
  OkpdKtruModalPageParams,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { useGetKtruCharacteristics } from '@/api/okpdApi/okpdApi';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { useOkpdRoute } from '@/widgets/OkpdKtru/hooks/useOkpdRoute';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdKtruPage = memo(({ modalPageParams, setModalPageParams }: Props) => {
  const { searchValue } = useOkpdRoute({ modalPageParams });
  const [characteristicsParams, setCharacteristicsParams] = useState<CharacteristicsParam[]>([]);

  const { data: characteristicsOptions = [], isLoading: isCharacteristicsOptionsLoading } =
    useGetKtruCharacteristics({
      searchString: searchValue,
    });

  const onCharacteristicsChange = useCallback(
    (newChars: CharacteristicsParam[]) => setCharacteristicsParams(newChars),
    [],
  );

  const filter = useMemo<OkpdKtruListFilter>(
    () => ({
      searchString: searchValue,
      characteristics: characteristicsParams,
    }),
    [characteristicsParams, searchValue],
  );

  useEffect(() => {
    sendEventGTM(eventsGTM.okpdCommonPageView, { info: setModalPageParams ? 'modal' : 'page' });
  }, [setModalPageParams]);

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.OKPD_OPEN,
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Flex
        className={styles.filters}
        vertical
        gap={44}
      >
        {searchValue && (
          <CharacteristicsFilter
            list={characteristicsOptions}
            isListLoading={isCharacteristicsOptionsLoading}
            characteristicsFilter={characteristicsParams}
            titleText="Уточнить характеристики"
            onCharacteristicsChange={onCharacteristicsChange}
          />
        )}
      </Flex>

      {searchValue ? (
        <div className={styles.content}>
          <OkpdList
            filter={filter}
            setModalPageParams={setModalPageParams}
          />
          <KtruList
            filter={filter}
            setModalPageParams={setModalPageParams}
          />
        </div>
      ) : (
        <InitialPage setModalPageParams={setModalPageParams} />
      )}
    </div>
  );
});

OkpdKtruPage.displayName = 'OkpdKtruPage';
