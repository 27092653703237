export type DeadlineCalculationResponse = DeadlineCalculation[];

export enum DeadlineType {
  PROCEDURES44 = 'procedures44',
  PROCEDURES223 = 'procedures223',
  CONTRACTS44 = 'contracts44',
}

export type DeadlineCalculation = {
  name: string;
  stages: Stage[];
};

export type Stage = {
  id: number;
  dateLessThenDateFrom: boolean;
  dateGreaterThenDateTo: boolean;
  dateFromGreaterThenDateTo: boolean;
  name: string;
  law: string;
  errorText?: string;
  errorFact: boolean;
  visibility: boolean;
  date: Date;
  dateFrom: Date;
  dateTo: Date;
  dateFromGreaterThenDateToErrorMessage: string;
};

export type Date = {
  date: string | null;
  isEditable?: boolean;
  canBeCleared?: boolean;
  impactingStageId?: number;
  impactingStageMessage?: string;
  calculationFrom?: number;
};

export type DeadlineCalculationBody = {
  changedStage: Stage;
  currentCalculation: DeadlineCalculation;
};

export type DeadlineCalculationContracts = {
  stageNumber: string;
  steps: Step[];
  executionFinish: string;
};

export interface Step {
  id: number;
  name: string;
  law: string;
  dateCalculateMethodChoices: string[];
  dateCalculateMethod: string;
  termInDaysCount: number;
  executionDate: string;
  previousStepId: number | null;
}
