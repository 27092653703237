import {
  NmckGuardByIdResponse,
  NmckGuardCreateResponse,
  NmckGuardUpdateBody,
} from './nmckGuardApiTypes';
import { downloadFile, fetcher, getData, postData, putData } from '../commonApi/commonApi';
import useSWRImmutable from 'swr/immutable';
import { pick } from 'lodash';
import { NMCK_GUARD_BY_ID_FALLBACK } from '@/widgets/NmckGuard/constants';
import { getNmckGuardByIdKey } from '@/widgets/NmckGuard/utilts';

export const postNmckGuard = async (): Promise<NmckGuardCreateResponse> => {
  return postData<NmckGuardCreateResponse>('/nmck/guard');
};

export const putNmckGuard = async ({
  id,
  ...nmck
}: NmckGuardByIdResponse): Promise<NmckGuardByIdResponse> => {
  const url = `/nmck/guard/${id}`;
  const mappedBody: NmckGuardUpdateBody = {
    ...pick(nmck, ['name', 'szj', 'stso', 'mrot', 'startDate', 'endDate', 'okpdCode']),
    regionalCoefficient: nmck.mrotCoeff,
    posts: nmck.nmckGuardRawRequest.posts,
    localHolidays: nmck.nmckGuardRawRequest.localHolidays,
    regionalHolidays: nmck.generalDataByYears.map(({ year, regionalHolidaysAmount }) => ({
      year,
      holidaysAmount: regionalHolidaysAmount,
    })),
  };

  return putData<NmckGuardByIdResponse, NmckGuardUpdateBody>(url, mappedBody);
};

export const useGetNmckGuard = (nmckId: NmckGuardByIdResponse['id']) => {
  const url = nmckId ? getNmckGuardByIdKey(nmckId) : null;
  const fallbackData = nmckId ? undefined : NMCK_GUARD_BY_ID_FALLBACK;

  return useSWRImmutable(url, getData<NmckGuardByIdResponse>, {
    fallbackData,
  });
};

export const getNmckGuardXlsx = async (
  nmckId: NmckGuardByIdResponse['id'],
  nmckName: NmckGuardByIdResponse['name'],
) => {
  const response = await fetcher(`/nmck/guard/${nmckId}/xlsx`);

  const fileName = 'НМЦК_Новый расчет услуги охраны';
  await downloadFile(response, nmckName ? `${fileName} ${nmckName}` : fileName);
};
