import { TableProps } from 'antd';
import {
  ContractItem,
  NmckRow,
  Proposition,
  PropositionType,
  UpdatePropositionDTO,
} from '@/api/nmckApi/nmckApiTypes';
import styles from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable.module.scss';
import Copy from 'public/icons/copy.svg';

export const getParagraphEllipsis = (tooltip?: string) => ({
  rows: 2,
  tooltip,
});

// TODO: Вынести в отдельный компонент и им оборачивать места, где нужно копирование
const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <Copy
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

export const getCopyableWithText = (text: string) => ({ ...copyable, text });

export const rowClassName: TableProps['rowClassName'] = () => {
  return styles.row;
};

export const getNewPropositionUpdateBody = (
  { eisId, unitPrice, contract }: ContractItem,
  { forAllRows }: Proposition,
): UpdatePropositionDTO => ({
  forAllRows: forAllRows,
  contractId: eisId,
  description: `ЕИС ${contract.reestrNumber}`,
  price: unitPrice,
  type: PropositionType.EIS,
});

export const getEmptyPropositionUpdateBody = (): UpdatePropositionDTO => ({
  forAllRows: false,
  contractId: null,
  price: null,
  description: '',
  type: PropositionType.KP,
});

export const getFirstEmptyProposition = (row?: NmckRow): Proposition | undefined =>
  row?.propositions.find(({ price, contractId }) => price === null && contractId === null);
