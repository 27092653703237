import { SuppliersSourceEnum } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';
import { AbstractCheckboxGroupProps } from 'antd/es/checkbox/Group';

export const sourceFieldRadioOptions: AbstractCheckboxGroupProps<SuppliersSourceEnum>['options'] = [
  {
    label: 'Реестр ГИСП',
    value: SuppliersSourceEnum.GISP,
  },
  {
    label: 'Контракты из ЕИС',
    value: SuppliersSourceEnum.EIS,
  },
];
