import { PopoverProps } from 'antd';
import styles from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable.module.scss';
import Link from 'next/link';

export const increasingFactorInfoContentPopOver: PopoverProps['content'] = (
  <div className={styles.contentPopOver}>
    Заказчик имеет право применить повышающие коэффициенты к ценам контрактов из ЕИС (
    <Link
      href="https://normativ.kontur.ru/document?moduleId=1&documentId=220720&rangeId=6001267"
      target="_blank"
    >
      рекомендации, утвержденные п.3.16, п.3.18 Приказа №567
    </Link>
    )
  </div>
);
