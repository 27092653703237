import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Spin } from 'antd';
import { KtruCard, Pagination } from '@/widgets/OkpdKtru/components';
import styles from './OkpdKtruDetailTabsKtruList.module.scss';
import { CharacteristicsParam } from '@/types/types';
import { CharacteristicsFilter } from '@/widgets';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { useGetKtruCharacteristics, useGetKtruListInfinite } from '@/api/okpdApi/okpdApi';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { useInfiniteList } from '@/hooks';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdKtruDetailTabsKtruList = memo(({ code, setModalPageParams }: Props) => {
  const [characteristics, setCharacteristics] = useState<CharacteristicsParam[]>([]);
  const filter = useMemo<OkpdKtruListFilter>(
    () => ({
      code,
      characteristics,
    }),
    [characteristics, code],
  );
  const {
    list,
    isLoading: isListLoading,
    loadMore,
    hasMoreResults,
    isMoreValidating,
  } = useInfiniteList<OkpdKtruListFilter, KtruResult>(useGetKtruListInfinite, filter);
  const { data = [], isLoading: isCharsLoading } = useGetKtruCharacteristics({
    okpdCode: code,
  });

  const handleCharacteristicsChange = useCallback(
    (newChars: CharacteristicsParam[]) => setCharacteristics(newChars),
    [],
  );

  const handleKtruCodeClick = useCallback(
    ({ code }: KtruResult) => {
      postBillingEvent({
        action: BillingEventType.TRU_SEARCH_RESULT_CODE_CLICK,
        value: code,
        value2: filter.code,
      });
    },
    [filter.code],
  );

  useEffect(() => {
    sendEventGTM(eventsGTM.ktruTabView, { value: code });
  }, [code]);

  return (
    <Flex
      vertical
      gap={28}
    >
      <CharacteristicsFilter
        list={data}
        characteristicsFilter={characteristics}
        isListLoading={isCharsLoading}
        onCharacteristicsChange={handleCharacteristicsChange}
      />

      {isListLoading && (
        <Flex>
          <Spin />
        </Flex>
      )}
      {!isListLoading && !!list?.length && (
        <Flex
          vertical
          className={styles.list}
          gap={16}
        >
          {list.map((item) => (
            <KtruCard
              key={item.code}
              ktru={item}
              showFromBookBadge={true}
              showNPAList={false}
              showContracts={false}
              setModalPageParams={setModalPageParams}
              onCodeClick={handleKtruCodeClick}
            />
          ))}
          <Pagination
            isMoreLoading={isMoreValidating}
            hasMoreResults={hasMoreResults}
            onShowMore={loadMore}
          />
        </Flex>
      )}
    </Flex>
  );
});

OkpdKtruDetailTabsKtruList.displayName = 'OkpdKtruDetailTabsKtruList';
