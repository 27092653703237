import { memo, useCallback, useMemo, useState } from 'react';
import { Flex, TableProps, Typography } from 'antd';
import styles from './OkpdKtruSuppliersTable.module.scss';
import { OkpdKtruRequestKP } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruRequestKP/OkpdKtruRequestKP';
import { KtruDetail, OkpdDetail, SupplierResult } from '@/api/okpdApi/okpdApiTypes';
import { useGetSuppliersInfinite } from '@/api/okpdApi/okpdApi';
import { useInfiniteList } from '@/hooks';
import cx from 'classnames';
import { copyable } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/constants';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import {
  SuppliersRequestParams,
  SuppliersSourceEnum,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';
import { TableFooter, TableFooterProps } from '@/components/Table/TableFooter/TableFooter';
import { formatPrice } from '@/utils/formatPrice';
import Link from 'next/link';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import ExportDiagonalIcon from 'public/icons/exportDiagonal.svg';

type OkpdKtruSuppliersTable = {
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

export const OkpdKtruSuppliersTable = memo(({ okpdKtruDetailData }: OkpdKtruSuppliersTable) => {
  const { requestParams, pageSize, filter } = useSuppliersContext();
  const { changePageSize } = useSuppliersActionsContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<SupplierResult['id'][]>([]);
  const isGispSource = filter.source === SuppliersSourceEnum.GISP;

  const { list, isLoading, isMoreValidating, loadMore, hasMoreResults } = useInfiniteList<
    SuppliersRequestParams,
    SupplierResult
  >(useGetSuppliersInfinite, requestParams);

  const tableColumns: TableProps<SupplierResult>['columns'] = useMemo(
    () =>
      [
        {
          title: 'Поставщик',
          key: 'name',
          width: '480px',
          render: (_: unknown, { name, inn, kpp, address }: SupplierResult) => (
            <Flex
              vertical
              gap={4}
            >
              <div className={styles.name}>{name}</div>
              <Flex
                align="center"
                gap={4}
                wrap
              >
                <div className={styles.gray}>ИНН {inn}</div>
                {kpp && <div className={styles.gray}>КПП {kpp}</div>}
                <div className={styles.gray}>{address}</div>
              </Flex>
            </Flex>
          ),
        },
        {
          title: 'Исполнено всего',
          key: 'contractsFinished',
          width: '200px',
          align: 'center' as const,
          render: (_: unknown, { contractsFinished }: SupplierResult) =>
            isGispSource && !contractsFinished ? '—' : contractsFinished,
          ellipsis: true,
        },
        {
          title: 'Последний исполненный контракт',
          key: 'price',
          width: '300px',
          render: (_: unknown, { price, lastContractRegNum, contractsFinished }: SupplierResult) =>
            isGispSource && !contractsFinished ? (
              '—'
            ) : (
              <Flex
                gap={16}
                align="center"
              >
                <span>{formatPrice(price)}</span>
                <Link
                  href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${lastContractRegNum}`}
                  target="_blank"
                  className={styles.linkRegNum}
                  onClick={preventEventPropagation}
                >
                  <ExportDiagonalIcon />
                  <span>Последний исп. контракт</span>
                </Link>
              </Flex>
            ),
        },
        {
          title: 'ОКПД',
          key: 'okpd',
          render: (_: unknown, { okpd }: SupplierResult) => okpd ?? '—',
        },
        {
          title: 'E-mail',
          key: 'email',
          width: '260px',
          render: (email: string) => (
            <Typography.Text
              copyable={email ? copyable : undefined}
              className={styles.copyable}
              onClick={preventEventPropagation}
            >
              {email || '—'}
            </Typography.Text>
          ),
        },
        {
          title: 'Телефон',
          key: 'phone',
          render: (phone: string) => (
            <Typography.Text
              copyable={phone ? copyable : undefined}
              className={styles.copyable}
              onClick={preventEventPropagation}
            >
              {phone || '—'}
            </Typography.Text>
          ),
        },
      ].map((c) => ({ ...c, dataIndex: c.key })),
    [isGispSource],
  );

  const selectedEmails = useMemo(
    () =>
      selectedRowKeys
        .map((key) => list?.find((item) => item.id === key)?.email ?? '')
        .filter(Boolean),
    [selectedRowKeys, list],
  );

  const handleRequestKP = useCallback(() => setSelectedRowKeys([]), []);

  const rowSelection: TableProps['rowSelection'] = useMemo(
    () => ({
      columnWidth: '64px',
      selectedRowKeys,
      onChange: (newSelectedRowKeys) =>
        setSelectedRowKeys(newSelectedRowKeys as SupplierResult['id'][]),
    }),
    [selectedRowKeys],
  );

  const tokenBasedPaginationProps = useMemo(
    (): TableFooterProps<SupplierResult>['tokenBasedPagination'] => ({
      pageSize: pageSize,
      hasMoreResults: hasMoreResults,
      isDataLoading: isMoreValidating,
      onPageSizeChange: changePageSize,
      onLoadMore: loadMore,
    }),
    [changePageSize, hasMoreResults, isMoreValidating, loadMore, pageSize],
  );

  const onRow: TableProps['onRow'] = useCallback(
    ({ id }: SupplierResult) => ({
      onClick: () => {
        if (selectedRowKeys.includes(id)) {
          setSelectedRowKeys((prev) => prev.filter((key) => key !== id));
        } else {
          setSelectedRowKeys((prev) => [...prev, id]);
        }
      },
    }),
    [selectedRowKeys],
  );

  const footer: TableProps['footer'] = useCallback(
    () => (
      <Flex
        align="center"
        justify="space-between"
      >
        <OkpdKtruRequestKP
          receivers={selectedEmails}
          okpdKtruDetailData={okpdKtruDetailData}
          onRequestKP={handleRequestKP}
        />
        <TableFooter tokenBasedPagination={tokenBasedPaginationProps} />
      </Flex>
    ),
    [handleRequestKP, okpdKtruDetailData, selectedEmails, tokenBasedPaginationProps],
  );

  return (
    <AutoScrollTable
      rowKey="id"
      columns={tableColumns}
      dataSource={list}
      loading={isLoading}
      className={cx(styles.table, 'table-with-sticky-footer')}
      rowSelection={rowSelection}
      onRow={onRow}
      pagination={false}
      footer={footer}
    />
  );
});

OkpdKtruSuppliersTable.displayName = 'OkpdKtruSuppliersTable';
