import { ConfigProvider, Tabs, TabsProps } from 'antd';
import { KtruDetail } from '@/api/okpdApi/okpdApiTypes';
import styles from './KtruDetailTabs.module.scss';
import { Badge } from '@/components';
import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';
import { getNPACount } from '@/components/OkpdKtruDetail/utils';
import { OkpdKtruDetailTabsKtruList } from '@/components/OkpdKtruDetail/OkpdKtruDetailTabsKtruList/OkpdKtruDetailTabsKtruList';
import { OkpdKtruDetailNPAList } from '@/components/OkpdKtruDetail/OkpdKtruDetailNPAList/OkpdKtruDetailNPAList';
import { OkpdKtruSuppliers } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruDetailSuppliers';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { KtruDetailTabsCharacteristics } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/KtruDetailTabsCharacteristics';
import { KtruDetailTabsGeneralInfo } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsGeneralInfo/KtruDetailTabsGeneralInfo';
import { OkpdKtruSuppliersProvider } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/OkpdKtruSuppliersContext';

type Props = {
  ktru: KtruDetail;
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

const theme = {
  components: {
    Tabs: {
      itemSelectedColor: styles.black,
      itemColor: styles.light_blue,
    },
  },
};

const getTabs = (
  ktru: KtruDetail,
  code: string,
  setActiveTab: Dispatch<SetStateAction<string>>,
  setModalPageParams?: SetOkpdKtruModalPageParamsType,
): TabsProps['items'] => {
  const npaCount = getNPACount(ktru.npa);

  return [
    {
      key: '1',
      label: 'Общая информация',
      children: (
        <KtruDetailTabsGeneralInfo
          ktru={ktru}
          setModalPageParams={setModalPageParams}
        />
      ),
    },
    {
      key: '2',
      label: 'Характеристики позиции',
      children: (
        <KtruDetailTabsCharacteristics
          characteristics={ktru.characteristics}
          setActiveTab={setActiveTab}
          ktruListTabDisabled={!ktru.ktruCount}
        />
      ),
      disabled: !ktru.characteristics?.length,
    },
    {
      key: '3',
      label: 'Похожие позиции',
      icon: (
        <Badge
          count={ktru.ktruCount ?? 0}
          className={styles.badge}
        />
      ),
      disabled: !ktru.ktruCount,
      children: (
        <OkpdKtruDetailTabsKtruList
          code={code}
          setModalPageParams={setModalPageParams}
        />
      ),
    },
    {
      key: '4',
      label: 'Запреты и ограничения',
      icon: (
        <Badge
          count={npaCount}
          className={styles.badge}
        />
      ),
      disabled: !npaCount,
      children: (
        <OkpdKtruDetailNPAList
          npa={ktru.npa}
          okpdCode={ktru.code}
          okpdName={ktru.name}
        />
      ),
    },
    {
      key: '5',
      label: 'Поиск поставщиков по коду',
      children: (
        <OkpdKtruSuppliersProvider ktruCode={code}>
          <OkpdKtruSuppliers
            ktruCode={code}
            okpdKtruDetailData={ktru}
          />
        </OkpdKtruSuppliersProvider>
      ),
    },
  ];
};

export const KtruDetailTabs = memo(({ ktru, code, setModalPageParams }: Props) => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const tabs = useMemo(
    () => getTabs(ktru, code, setActiveTab, setModalPageParams),
    [code, ktru, setModalPageParams],
  );

  return (
    <div className={styles.wrapper}>
      <ConfigProvider theme={theme}>
        <Tabs
          items={tabs}
          className={styles.tabs}
          activeKey={activeTab}
          onChange={setActiveTab}
        />
      </ConfigProvider>
    </div>
  );
});

KtruDetailTabs.displayName = 'KtruDetailTabs';
