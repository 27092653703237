import { memo } from 'react';
import { Flex, Tooltip } from 'antd';
import styles from './NPACard.module.scss';
import { NPAItem, NPAType } from '@/api/okpdApi/okpdApiTypes';
import { NPAChips } from '@/widgets/OkpdKtru/components/NPACard/components/NPAChips/NPAChips';

type NPACardProps = {
  npaItems: NPAItem[];
  npaType: NPAType;
};

const NPATitlesMap = {
  [NPAType.NATIONAL]: 'Нацрежим (ПП 1875)',
  [NPAType.PREF]: 'Преференции',
  [NPAType.OTHER]: 'Прочее',
};

export const NPACard = memo(({ npaItems, npaType }: NPACardProps) => {
  return (
    <>
      <Tooltip title={NPATitlesMap[npaType]}>
        <div className={styles.title}>{NPATitlesMap[npaType]}</div>
      </Tooltip>
      <Flex
        wrap
        className={styles.chipsList}
      >
        {npaItems.map((npa, index) => (
          <NPAChips
            key={npa.code + index}
            npa={npa}
          />
        ))}
      </Flex>
    </>
  );
});

NPACard.displayName = 'NPACard';
