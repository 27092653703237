import { memo, useCallback } from 'react';
import { Flex, Tooltip } from 'antd';
import styles from './DirectCosts.module.scss';
import Question from 'public/icons/questionRound.svg';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { inputNumberControls } from '@/constants/ui';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';

export const DirectCosts = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const handleMrotChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, mrot: value ?? 0 });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  const handleMrotCoeffChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, mrotCoeff: value ?? 0 });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  return (
    <Flex
      vertical
      gap={24}
    >
      <div className={styles.title}>Прямые затраты</div>
      <Flex
        gap={32}
        align="center"
      >
        <Flex
          gap={16}
          align="center"
        >
          <div className={styles.label}>МРОТ, ₽:</div>
          <DebounceInputNumber
            value={data?.mrot}
            min={0}
            className={styles.inputMrot}
            controls={inputNumberControls}
            onDebouncedChange={handleMrotChange}
          />
          <Tooltip
            arrow={false}
            title="Минимальный размер оплаты труда."
            placement="bottom"
          >
            <Question className="hint-icon" />
          </Tooltip>
        </Flex>

        <Flex
          gap={16}
          align="center"
        >
          <div className={styles.label}>Районный коэффициент:</div>
          <DebounceInputNumber
            value={data?.mrotCoeff}
            className={styles.inputNumber}
            min={1}
            max={2}
            step={0.01}
            controls={inputNumberControls}
            onDebouncedChange={handleMrotCoeffChange}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});

DirectCosts.displayName = 'DirectCosts';
