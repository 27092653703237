import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';

export enum CompatibilityNPACellType {
  EMPTY = 'EMPTY',
  APPLIES = 'APPLIES',
  SELECT = 'SELECT',
}

export type CompatibilityHeader = {
  caption: string;
};

export type CompatibilityRow = {
  id: number;
  purchaseNumber: number;
  calculationMethod: string;
  code: string;
  nkmi?: string;
  name?: string;
  possiblyMedDevice: boolean;
  selectedMedDevice: boolean;
  cells: CompatibilityCell[];

  isGroup?: boolean; // UI поле
};

export type CompatibilityCell = {
  type: CompatibilityNPACellType;
  textLine1?: string;
  textLine2?: string;
  items: CompatibilityCellItem[];
  otherNpas: string[];

  columnCode: string; // UI поле
};

export type CompatibilityCellItem = {
  id: number;
  text: string;
  isSelected: boolean;
};

export type CompatibilityResponse = {
  header: CompatibilityHeader[];
  rows: CompatibilityRow[];
  result: boolean;
  hasRestrictions: boolean;
};

export type CompatibilityUpdateBody = {
  selectedId: CompatibilityCellItem['id'];
};

export type CompatibilityMedDeviceSelectionRequestParams = {
  id?: NmckByIdResponse['id'];
  rowId: CompatibilityRow['id'];
};
