import { memo, useCallback, useState } from 'react';
import { Checkbox } from 'antd';
import { CompatibilityRow } from '@/api/compatibilityApi/compatibilityApiTypes';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CompatibilityTableProps } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable.types';

type Props = Pick<
  CompatibilityTableProps,
  'id' | 'onCreateMedDeviceSelection' | 'onDeleteMedDeviceSelection' | 'refreshTableData'
> & {
  row: CompatibilityRow;
};

export const CompatibilityTableMedDeviceCheckboxCell = memo(
  ({
    id,
    row,
    onCreateMedDeviceSelection,
    onDeleteMedDeviceSelection,
    refreshTableData,
  }: Props) => {
    const [isCheckboxLoading, setIsCheckboxLoading] = useState(false);

    const handleCreateSelection = useCallback(async () => {
      if (onCreateMedDeviceSelection) {
        await onCreateMedDeviceSelection({
          id,
          rowId: row.id,
        });
      }
    }, [id, onCreateMedDeviceSelection, row.id]);

    const handleDeleteSelection = useCallback(async () => {
      if (onDeleteMedDeviceSelection) {
        await onDeleteMedDeviceSelection({
          id,
          rowId: row.id,
        });
      }
    }, [id, onDeleteMedDeviceSelection, row.id]);

    const handleCheckboxValueChange = useCallback(
      async ({ target: { checked } }: CheckboxChangeEvent) => {
        setIsCheckboxLoading(true);
        try {
          if (checked) {
            await handleCreateSelection();
          } else {
            await handleDeleteSelection();
          }
          await refreshTableData();
        } finally {
          setIsCheckboxLoading(false);
        }
      },
      [handleCreateSelection, handleDeleteSelection, refreshTableData],
    );

    return (
      row.possiblyMedDevice && (
        <Checkbox
          checked={row.selectedMedDevice}
          disabled={isCheckboxLoading}
          onChange={handleCheckboxValueChange}
        />
      )
    );
  },
);

CompatibilityTableMedDeviceCheckboxCell.displayName = 'CompatibilityTableMedDeviceCheckboxCell';
