import { memo, useCallback } from 'react';
import Link from 'next/link';
import { ContractDocument } from '@/api/docsApi/docsApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  document: ContractDocument;
};

export const DocsTableFacComplaintLink = memo(
  ({ document: { fasComplaintHref, hasFasComplaint } }: Props) => {
    const handleLinkClick = useCallback(() => {
      postBillingEvent({
        action: BillingEventType.DOCS_DOWNLOAD_DOC,
        value: fasComplaintHref,
      });
    }, [fasComplaintHref]);

    return (
      <Link
        href={fasComplaintHref}
        target="_blank"
        onClick={handleLinkClick}
      >
        {hasFasComplaint ? 'Да' : 'Нет'}
      </Link>
    );
  },
);

DocsTableFacComplaintLink.displayName = 'DocsTableFacComplaintLink';
