import { memo } from 'react';
import styles from './PlannedTableExpandedRow.module.scss';
import { reestrLink, regNumberLink } from '@/widgets/CurrentPage/CurrentPage.constants';
import { formatPrice } from '@/utils/formatPrice';
import { PurchasePlanItem } from '@/api/currentApi/currentApiTypes';
import { Flex } from 'antd';
import { PlannedTableLinkedPurchaseLink } from '@/widgets/CurrentPage/components/PlannedTable/components/PlannedTableExpandedRow/components/PlannedTableLinkedPurchaseLink/PlannedTableLinkedPurchaseLink';

type Props = {
  row: PurchasePlanItem;
};

export const PlannedTableExpandedRow = memo(({ row }: Props) => {
  return (
    row.linkedPurchase.length !== 0 && (
      <div className={styles.linkedPurchase}>
        <Flex
          align="center"
          gap={8}
        >
          Извeщeниe №
          <ul>
            {row.linkedPurchase.map(({ puchaseRegNumber }, id) => {
              return puchaseRegNumber ? (
                <li key={id}>
                  <PlannedTableLinkedPurchaseLink
                    regNumber={puchaseRegNumber}
                    href={regNumberLink}
                  />
                </li>
              ) : (
                '—'
              );
            })}
          </ul>
        </Flex>
        <Flex
          align="center"
          gap={8}
        >
          Контракт №
          <ul>
            {row.linkedPurchase.map(({ contractReestrNumber }, id) => {
              return contractReestrNumber ? (
                <li key={id}>
                  <PlannedTableLinkedPurchaseLink
                    regNumber={contractReestrNumber}
                    href={reestrLink}
                  />
                </li>
              ) : (
                '—'
              );
            })}
          </ul>
        </Flex>

        <ul>
          {row.linkedPurchase.map(({ sum }, id) => {
            return (
              <li
                key={id}
                className={styles.purchaseSum}
              >
                {sum ? `-${formatPrice(sum)}` : '—'}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
});

PlannedTableExpandedRow.displayName = 'PlannedTableExpandedRow';
