import { useContext } from 'react';
import { OkpdKtruSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/OkpdKtruSuppliersContext';

export const useSuppliersActionsContext = () => {
  const context = useContext(OkpdKtruSuppliersActionsContext);

  if (!context) {
    throw new Error(
      'OkpdKtruSuppliersActionsContext must be used within a OkpdKtruSuppliersProvider',
    );
  }

  return context;
};
