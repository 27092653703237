import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import styles from './SkpPurchaseMethodSelect.module.scss';
import VIcon from 'public/icons/v.svg';
import { Select } from 'antd';
import { Option } from '@/types/types';
import { useGetDeadlineCalculation } from '@/api/skpApi/skpApi';
import { DeadlineType } from '@/api/skpApi/skpApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  name?: string;
  activeTab: DeadlineType;
  setName: Dispatch<SetStateAction<string | undefined>>;
};

export const SkpPurchaseMethodSelect = memo(({ name, activeTab, setName }: Props) => {
  const { data } = useGetDeadlineCalculation(activeTab);

  const options: Option[] = useMemo(
    () =>
      data?.map(({ name }) => ({
        value: name,
        label: name,
      })) ?? [],
    [data],
  );

  const handleMethodChange = useCallback(
    (value: string) => {
      setName(value);
      postBillingEvent({
        action: BillingEventType.DEADLINES_CHANGE_PURCHASE_METHOD,
        value,
      });
    },
    [setName],
  );

  return (
    <Select
      value={name}
      className={styles.select}
      options={options}
      suffixIcon={<VIcon className="v-icon" />}
      onChange={handleMethodChange}
    />
  );
});

SkpPurchaseMethodSelect.displayName = 'SkpPurchaseMethodSelect';
