'use client';

import { memo, useCallback, useState } from 'react';
import { Flex, Input, InputProps, Typography } from 'antd';
import styles from './ReportFormModal.module.scss';
import { ReportFormModalProps, ReportFormState } from '@/features';
import { trim } from 'lodash';
import { TextAreaProps } from 'antd/es/input';
import { getUserEmailFromStorage } from '@/utils';
import { AsyncButton, UiModal } from '@/components';

export const ReportFormModal = memo(
  ({ isOpen, onClose, onSubmit, config }: ReportFormModalProps) => {
    const {
      title,
      description,
      emailPlaceholder,
      feedbackPlaceholder,
      submitButtonText,
      requiredFeedback,
    } = config;

    const [formState, setFormState] = useState<ReportFormState>({
      email: getUserEmailFromStorage(),
      feedback: '',
    });

    const handleSubmitButtonClick = useCallback(async () => {
      await onSubmit({ email: trim(formState.email), feedback: trim(formState.feedback) });
    }, [formState.email, formState.feedback, onSubmit]);

    const handleEmailChange = useCallback<Required<InputProps>['onChange']>(
      ({ target: { value } }) => {
        setFormState((prev) => ({
          ...prev,
          email: value,
        }));
      },
      [],
    );

    const handleFeedbackChange = useCallback<Required<TextAreaProps>['onChange']>(
      ({ target: { value } }) => {
        setFormState((prev) => ({
          ...prev,
          feedback: value,
        }));
      },
      [],
    );

    return (
      <UiModal
        title={title}
        open={isOpen}
        bodyColor="white"
        footer={
          <AsyncButton
            type="primary"
            disabled={requiredFeedback && !formState.feedback}
            onClick={handleSubmitButtonClick}
          >
            {submitButtonText}
          </AsyncButton>
        }
        width={804}
        onCancel={onClose}
      >
        <Flex
          vertical
          gap={16}
        >
          <Typography.Text type="secondary">{description}</Typography.Text>
          <Input
            value={formState.email}
            placeholder={emailPlaceholder}
            onChange={handleEmailChange}
          />
          <Input.TextArea
            value={formState.feedback}
            className={styles.textarea}
            placeholder={feedbackPlaceholder}
            onChange={handleFeedbackChange}
          />
        </Flex>
      </UiModal>
    );
  },
);

ReportFormModal.displayName = 'ReportFormModal';
