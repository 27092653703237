import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NmckByIdResponse,
  NmckRow,
  PropositionType,
  UpdatePropositionDTO,
} from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridPropositionInput.module.scss';
import cx from 'classnames';
import { NmckGridPropositionInputSuffix } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/NmckGridPropositionInputSuffix';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { formatPrice, parsePrice } from '@/utils/formatPrice';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { DebounceInput } from '@/components';
import { isNumber } from 'lodash';

type Props = {
  columnIndex: number;
  row: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridPropositionInput = memo(({ row, columnIndex, nmckId }: Props) => {
  const proposition = row.propositions[columnIndex];
  const { updatePropositionWithFallback } = useNmckFallbackDataHandler();

  const isEis = useMemo(() => proposition.type === PropositionType.EIS, [proposition.type]);
  const [price, setPrice] = useState(formatPrice(String(proposition.price)));

  const handleUpdateProposition = useCallback(
    async (
      updateData: Partial<Pick<UpdatePropositionDTO, 'price' | 'description' | 'forAllRows'>>,
    ) => {
      const { price, description, forAllRows } = updateData;

      sendEventGTM(eventsGTM.nmckRowPriceChange);

      const updatedProposition: UpdatePropositionDTO = {
        type: PropositionType.KP,
        contractId: null,
        price: price === undefined ? proposition.price : price,
        description: description ?? proposition.description,
        forAllRows: forAllRows ?? false,
      };

      await updatePropositionWithFallback(nmckId, row.id, proposition, updatedProposition);
    },
    [nmckId, proposition, row.id, updatePropositionWithFallback],
  );

  const handleValueChange = useCallback(
    async (value: string) => {
      const formattedPrice = formatPrice(value);
      const parsedPrice = parsePrice(formattedPrice);
      setPrice(formattedPrice);

      if (parsedPrice !== proposition.price && !formattedPrice.endsWith(',')) {
        await handleUpdateProposition({ price: parsedPrice });
      }
    },
    [handleUpdateProposition, proposition.price],
  );

  useEffect(() => {
    setPrice(formatPrice(String(proposition.price)));
  }, [proposition.price]);

  return (
    <DebounceInput
      value={price}
      disabled={isEis}
      truncate
      onDebouncedChange={handleValueChange}
      className={cx(styles.input, {
        [styles.inputWithSuffix]: proposition.description,
      })}
      status={proposition.warning ? 'warning' : ''}
      placeholder="Цена"
      suffix={
        isNumber(proposition.price) ? (
          <NmckGridPropositionInputSuffix
            rowId={row.id}
            proposition={proposition}
            updateProposition={handleUpdateProposition}
          />
        ) : (
          <span />
        )
      }
    />
  );
});

NmckGridPropositionInput.displayName = 'NmckGridPropositionInput';
