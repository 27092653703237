import { memo, useEffect } from 'react';
import { OkpdKtruSuppliersTable } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/OkpdKtruSuppliersTable';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { OkpdKtruSuppliersFilter } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';

type Props = {
  okpdCode?: string;
  ktruCode?: string;
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

export const OkpdKtruSuppliers = memo(({ okpdCode, ktruCode, okpdKtruDetailData }: Props) => {
  useEffect(() => {
    sendEventGTM(eventsGTM.suppliersTabView, { value: okpdCode ?? ktruCode });
  }, [ktruCode, okpdCode]);

  return (
    <div>
      <OkpdKtruSuppliersFilter />
      <OkpdKtruSuppliersTable okpdKtruDetailData={okpdKtruDetailData} />
    </div>
  );
});

OkpdKtruSuppliers.displayName = 'OkpdKtruSuppliers';
