import { memo } from 'react';
import { Button, ButtonProps, Tooltip } from 'antd';
import BugIcon from 'public/icons/bug.svg';
import { useClientRender, useMediaQuery } from '@/hooks';

type Props = Pick<ButtonProps, 'onClick'>;

export const BugReportButton = memo(({ onClick }: Props) => {
  const { maxMD } = useMediaQuery();
  const { isClientRender } = useClientRender();

  return (
    isClientRender && (
      <Tooltip
        title={maxMD && 'Сообщить об ошибке'}
        placement="left"
      >
        <Button
          type="link"
          danger
          size="small"
          className="button-with-icon"
          icon={<BugIcon />}
          onClick={onClick}
        >
          {!maxMD && 'Сообщить об ошибке'}
        </Button>
      </Tooltip>
    )
  );
});

BugReportButton.displayName = 'BugReportButton';
