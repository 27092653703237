import { Dispatch, Key, memo, SetStateAction, useCallback, useEffect } from 'react';
import {
  createCalculationFromTru,
  deleteTruCompatibilityList,
  deleteTruCompatibilityMedDeviceSelection,
  deleteTruCompatibilityRow,
  postTruCompatibilityMedDeviceSelection,
  updateTruCompatibilityCell,
  useGetTruCompatibility,
} from '@/api/okpdApi/okpdApi';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import { KeyedMutator } from 'swr';
import { TruCountResponse } from '@/api/okpdApi/okpdApiTypes';
import { CompatibilityTableProps } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable.types';
import { Compatibility } from '@/components/Compatibility/Compatibility';
import { AsyncButton, UiModal } from '@/components';

type Props = {
  modalKey?: Key;
  refreshCount: KeyedMutator<TruCountResponse>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const OkpdKtruDetailCompatibilityModal = memo(
  ({ isOpen, setIsOpen, modalKey, refreshCount }: Props) => {
    const router = useRouter();
    const { data, isLoading, mutate } = useGetTruCompatibility();

    useEffect(() => {
      if (modalKey) {
        mutate();
      }
    }, [modalKey, mutate]);

    const handleCloseModal = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleClearListButtonClick = useCallback(async () => {
      await deleteTruCompatibilityList();
      await refreshCount();
      await mutate();
      handleCloseModal();
    }, [handleCloseModal, mutate, refreshCount]);

    const handleRemoveCompatibilityRow: Required<CompatibilityTableProps>['onDeleteRow'] =
      useCallback(
        async (id) => {
          await deleteTruCompatibilityRow(id);
          const data = await mutate();
          await refreshCount();

          if (data?.rows.length === 0) {
            handleCloseModal();
          }
        },
        [handleCloseModal, mutate, refreshCount],
      );

    const handleCreateCalculationButtonClick = useCallback(async () => {
      await createCalculationFromTru();
      await mutate();
      await refreshCount();
      router.push(pageRoutes.nmckHistory);
    }, [mutate, refreshCount, router]);

    const modalTitleButtons = (
      <>
        <AsyncButton
          type="primary"
          onClick={handleCreateCalculationButtonClick}
        >
          Создать расчет
        </AsyncButton>
        <AsyncButton onClick={handleClearListButtonClick}>Очистить список</AsyncButton>
      </>
    );

    return (
      <UiModal
        open={isOpen}
        title="Список позиций ОКПД2/КТРУ"
        width="95%"
        titleButtons={modalTitleButtons}
        onCancel={handleCloseModal}
      >
        <Compatibility
          id={0}
          data={data}
          warning={!!data && !data.result}
          isLoading={isLoading}
          refreshTableData={mutate}
          onDeleteRow={handleRemoveCompatibilityRow}
          onUpdateCell={updateTruCompatibilityCell}
          onCreateMedDeviceSelection={postTruCompatibilityMedDeviceSelection}
          onDeleteMedDeviceSelection={deleteTruCompatibilityMedDeviceSelection}
          withVerticalScroll
        />
      </UiModal>
    );
  },
);

OkpdKtruDetailCompatibilityModal.displayName = 'OkpdKtruDetailCompatibilityModal';
