import { useContext } from 'react';
import { NmckHistoryActionsContext } from '@/widgets/NmckHistory/context/NmckHistoryContext';

export const useNmckHistoryActionsContext = () => {
  const context = useContext(NmckHistoryActionsContext);

  if (!context) {
    throw new Error('NmckHistoryActionsContext must be used within a NmckHistoryProvider');
  }

  return context;
};
