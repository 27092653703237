import { useMemo, useState } from 'react';
import { CompatibilityResponse } from '@/api/compatibilityApi/compatibilityApiTypes';

type Props = {
  data?: CompatibilityResponse;
};

const filterByPurchaseNumber = (
  data: CompatibilityResponse | undefined,
  purchaseNumber: number,
): CompatibilityResponse | undefined => {
  if (!data) return undefined;
  if (purchaseNumber === 0) {
    return data;
  }

  return {
    ...data,
    rows: data.rows.filter((row) => row.purchaseNumber === purchaseNumber),
  };
};

export const useCompatibility = ({ data }: Props) => {
  const [activeTab, setActiveTab] = useState('0');

  const filteredData = useMemo(
    () => filterByPurchaseNumber(data, parseInt(activeTab)),
    [activeTab, data],
  );

  const rows = useMemo(() => filteredData?.rows ?? [], [filteredData]);

  return {
    rows,
    rawRows: data?.rows ?? [],

    activeTab,
    setActiveTab,
  };
};
