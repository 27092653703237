import {
  CompatibilityCell,
  CompatibilityNPACellType,
  CompatibilityRow,
} from '@/api/compatibilityApi/compatibilityApiTypes';
import { memo, useCallback } from 'react';
import { Flex, Typography } from 'antd';
import cx from 'classnames';
import VIcon from 'public/icons/v.svg';
import CheckRoundIcon from 'public/icons/checkRoundNotFilled.svg';
import styles from './CompatibilityTableCell.module.scss';
import { NPAChips } from '@/widgets/OkpdKtru/components/NPACard/components/NPAChips/NPAChips';
import { CompatibilityProps } from '@/components/Compatibility/Compatibility.types';

type Props = {
  cell: CompatibilityCell;
  row: CompatibilityRow;
  onClick: (record: CompatibilityRow, cell: CompatibilityCell) => void;
  currentExpandRowId?: number;
  currentExpandCell?: CompatibilityCell;
} & Pick<CompatibilityProps, 'isDrugs'>;

export const CompatibilityTableCell = memo(
  ({ cell, row, onClick, currentExpandRowId, isDrugs, currentExpandCell }: Props) => {
    const { type, items, textLine1, textLine2, otherNpas } = cell;

    const isSelectType = type === CompatibilityNPACellType.SELECT;
    const isAppliesType = type === CompatibilityNPACellType.APPLIES;
    const isCellHasExpandContent = !!items.length;
    const isCellAppliedWithoutItems = isAppliesType && !items.length;
    const isNpaListExist = !!otherNpas.length;
    const active =
      currentExpandRowId === row.id && currentExpandCell?.columnCode === cell.columnCode;

    const handleActionClick = useCallback(() => {
      onClick(row, cell);
    }, [row, cell, onClick]);

    const text = (
      <>
        {textLine1 && <span>{textLine1}</span>}
        {textLine2 && <span>{textLine2}</span>}
      </>
    );

    if (isDrugs) {
      return isAppliesType && <CheckRoundIcon className={styles.checkIcon} />;
    }

    if (isCellHasExpandContent) {
      return (
        <Typography.Link
          onClick={handleActionClick}
          className={cx(styles.action, {
            [styles.actionActive]: active,
          })}
        >
          <Flex vertical>
            {!isSelectType && text}
            {isSelectType && <span>Уточнить объект закупки</span>}
          </Flex>

          <VIcon
            className={cx('v-icon', styles.vIcon, {
              [styles.vIconActive]: active,
            })}
          />
        </Typography.Link>
      );
    }

    if (isCellAppliedWithoutItems) {
      return text;
    }

    if (isNpaListExist) {
      return (
        <Flex
          className={styles.npaList}
          wrap
        >
          {otherNpas.map((npa) => (
            <NPAChips
              key={npa}
              npa={{ code: npa, applicable: true }}
            />
          ))}
        </Flex>
      );
    }
  },
);

CompatibilityTableCell.displayName = 'CompatibilityTableCell';
