import { postData } from '@/api/commonApi/commonApi';
import { BillingEvent } from '@/api/billingApi/billingApiTypes';

export const postBillingEvent = async (eventBody: BillingEvent) => {
  try {
    await postData<void>('/billing/event', eventBody);
  } catch (error) {
    console.error(error);
  }
};
