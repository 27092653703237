import { memo, ReactNode } from 'react';
import styles from './CompatibilityWarning.module.scss';
import WarningIcon from 'public/icons/warning.svg';
import cx from 'classnames';
import { Flex } from 'antd';
import WarningNotFilledIcon from 'public/icons/warningNotFilled.svg';

type Props = {
  hasCompatibility: boolean;
  hasRestrictions?: boolean;
  content?: ReactNode;
  extraContent?: ReactNode;
  onlyVerticalBorder?: boolean;
  className?: string;
};

export const CompatibilityWarning = memo(
  ({
    hasCompatibility,
    hasRestrictions,
    content = 'В закупке содержатся несовместимые коды ОКПД2/КТРУ. Необходимо разделить закупку',
    extraContent,
    onlyVerticalBorder,
    className,
  }: Props) => {
    const icon = hasCompatibility ? <WarningIcon /> : <WarningNotFilledIcon />;

    return (
      (hasCompatibility || hasRestrictions) && (
        <Flex
          align="center"
          justify="center"
          gap={16}
          className={cx(styles.warning, className, {
            [styles.compatibility]: hasCompatibility,
            [styles.verticalBorder]: hasCompatibility && onlyVerticalBorder,
          })}
        >
          <Flex
            align="center"
            justify="center"
            gap={8}
            className={styles.text}
          >
            {icon}
            <div>{content}</div>
          </Flex>
          {extraContent}
        </Flex>
      )
    );
  },
);

CompatibilityWarning.displayName = 'CompatibilityWarning';
