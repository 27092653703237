import { memo } from 'react';
import cx from 'classnames';
import styles from './NPAChips.module.scss';
import { Typography } from 'antd';
import { NPAItem } from '@/api/okpdApi/okpdApiTypes';

type Props = {
  npa: NPAItem;
};

export const NPAChips = memo(({ npa }: Props) => {
  const isRedNPA = ['Запрет', 'Запрет*'].includes(npa.code);
  const isOrangeNPA = ['Ограничение', 'Ограничение*'].includes(npa.code);
  const isGreenNPA = ['Преимущество'].includes(npa.code);

  return (
    <div
      className={cx(styles.chips, {
        [styles.applicable]: npa.applicable,
        [styles.red]: isRedNPA,
        [styles.orange]: isOrangeNPA,
        [styles.green]: isGreenNPA,
      })}
    >
      <Typography.Paragraph
        className={styles.chipsText}
        ellipsis={{ rows: 1, tooltip: npa.code }}
      >
        {npa.code}
      </Typography.Paragraph>
    </div>
  );
});

NPAChips.displayName = 'NPAChips';
