import { InputNumber, InputNumberProps } from 'antd';
import { forwardRef, memo } from 'react';
import { InputNumberRef } from 'rc-input-number';

type Props = InputNumberProps<number>;

export const UiInputNumber = memo(
  forwardRef<InputNumberRef, Props>((props: Props, ref) => {
    return (
      <InputNumber<number>
        decimalSeparator=","
        ref={ref}
        {...props}
      />
    );
  }),
);

UiInputNumber.displayName = 'UiInputNumber';
