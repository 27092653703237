import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import styles from './SuppliersFilterRegionsField.module.scss';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';

export const SuppliersFilterRegionsField = memo(() => {
  const { setFilter } = useSuppliersActionsContext();

  const handleRegionsChange = useCallback(
    (regions: number[]) => {
      setFilter((prev) => ({ ...prev, region: regions }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Регион поставщика"
      rowHeight={40}
      field={
        <RegionsTreeSelect
          className={styles.select}
          placeholder="Не указан"
          onRegionsChange={handleRegionsChange}
        />
      }
    />
  );
});

SuppliersFilterRegionsField.displayName = 'SuppliersFilterRegionsField';
