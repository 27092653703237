import { memo, useCallback } from 'react';
import { Popover } from 'antd';
import { increasingFactorInfoContentPopOver } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableIncreasingFactorSwitch/ContractsTableIncreasingFactorSwitch.constants';
import Question from '/public/icons/questionRound.svg';
import { UiSwitch } from '@/components';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

export const ContractsTableIncreasingFactorSwitch = memo(() => {
  const { priceIncreasingFactor } = useNmckGridContractsContext();
  const { setPriceIncreasingFactor } = useNmckGridContractsActionsContext();

  const handlePriceIncreasingFactorChange = useCallback(() => {
    postBillingEvent({
      action: BillingEventType.NMCK_APPLY_INCREASE_COEFF,
      value: !priceIncreasingFactor,
    });
    setPriceIncreasingFactor((prev) => !prev);
  }, [priceIncreasingFactor, setPriceIncreasingFactor]);

  return (
    <UiSwitch
      value={priceIncreasingFactor}
      onChange={handlePriceIncreasingFactorChange}
    >
      <span>Повышающий коэффициент цен</span>
      <Popover
        arrow={false}
        content={increasingFactorInfoContentPopOver}
      >
        <Question className="hint-icon" />
      </Popover>
    </UiSwitch>
  );
});

ContractsTableIncreasingFactorSwitch.displayName = 'ContractsTableIncreasingFactorSwitch';
