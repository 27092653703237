import useSWR from 'swr';
import { CreateCurrentPurchaseItemBody, PurchaseByIdResponse } from './planningCurrentApiTypes';
import {
  deleteData,
  downloadFile,
  fetcher,
  getData,
  postData,
  putData,
} from '@/api/commonApi/commonApi';
import { Key } from 'react';
import {
  CompatibilityResponse,
  CompatibilityUpdateBody,
} from '@/api/compatibilityApi/compatibilityApiTypes';

export const useGetCurrentPurchaseById = (id: PurchaseByIdResponse['id']) => {
  return useSWR(`/purchase/${id}`, getData<PurchaseByIdResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const useGetCurrentPurchaseCompatibility = (id: PurchaseByIdResponse['id']) => {
  const params = new URLSearchParams();
  params.append('npaV2', 'true');

  return useSWR(`/purchase/${id}/compatibility?${params}`, getData<CompatibilityResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const updatePurchaseCompatibilityCell = (
  purchaseId: PurchaseByIdResponse['id'],
  rowId: number,
  body: CompatibilityUpdateBody,
) => {
  return postData(`/purchase/${purchaseId}/row/${rowId}/compatibility-user-selection`, body);
};

export const deleteCurrentPurchaseItem = (body: Key[], id: string | string[]) => {
  return deleteData(`/purchase/${id}/item`, body);
};

export const createCurrentPurchaseItem = (
  body: CreateCurrentPurchaseItemBody,
  purchaseId: PurchaseByIdResponse['id'],
) => {
  return postData<CreateCurrentPurchaseItemBody>(
    `/purchase/${purchaseId}/item`,
    body,
  ) as unknown as Promise<PurchaseByIdResponse>;
};

export const updateCurrentPurchaseItem = (
  id: string | string[],
  itemId: number,
  body: CreateCurrentPurchaseItemBody,
) => {
  return putData<CreateCurrentPurchaseItemBody>(
    `/purchase/${id}/item/${itemId}`,
    body,
  ) as unknown as Promise<PurchaseByIdResponse>;
};

export const getCurrentPurchaseXlsx = async (id: string | string[]) => {
  const response = await fetcher(`/purchase/${id}/xlsx`);
  await downloadFile(response, 'Описание объекта закупки');
};
