import { NMCK_ANALYSIS_BY_ID_FALLBACK } from '@/widgets/NmckAnalysis/constants';
import {
  MethodUsedType,
  NmckDrugsByIdResponse,
  NmckDrugsCalculationMethod,
} from '@/api/nmckDrugsApi/nmckDrugsApiTypes';
import {
  BadgeType,
  CalculationMethod,
  CalculationPrecision,
  NmckType,
  VariationColor,
} from '@/api/nmckApi/nmckApiTypes';

export const NMCK_DRUGS_BY_ID_FALLBACK: NmckDrugsByIdResponse = {
  ...NMCK_ANALYSIS_BY_ID_FALLBACK,
  calculationMethod: CalculationMethod.MIN,
  calculationPrecision: CalculationPrecision.CENTS,
  type: NmckType.DRUGS,
  badge: BadgeType.NO_BADGE,
  rows: [
    {
      id: 0,
      rowNumber: 1,
      smnnUUID: null,
      klpUUID: null,
      mnn: null,
      tn: null,
      esklpCode: null,
      isZnvlp: null,
      dosageFullForm: null,
      drugForm: null,
      interchangeSmnnUuids: [],
      unit: '',
      unitCode: null,
      amount: 0,
      priceLimits: [],
      contracts: [],
      kps: [],
      averageContracts: [],
      directContracts: [],
      nds: 10,
      wholesaleAddition: null,
      nmck: 0,
      limitCalculationMethod: NmckDrugsCalculationMethod.MINIMAL,
      contractCalculationMethod: NmckDrugsCalculationMethod.MINIMAL,
      unitPrice: 0,
      priceLimitMinPrice: null,
      priceLimitAvgPrice: null,
      contractsPriceVariation: 0,
      contractsMinPrice: null,
      contractsAvgPrice: null,
      averagePrice: null,
      variationColor: VariationColor.GREEN,
      methodUsed: MethodUsedType.NOTHING,
      limitPriceMessage: null,
      limitPriceDisabled: false,
      marketPriceMessage: null,
      marketPriceDisabled: false,
      avgPriceMessage: null,
      avgPriceDisabled: false,
      maxPriceLimit: null,
    },
  ],
};
