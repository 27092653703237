import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, TableProps } from 'antd';
import { Key, SelectionSelectFn, TableRowSelection } from 'antd/es/table/interface';
import { nmckContractsTableColumns } from './constants';
import styles from './NmckGridContractsTable.module.scss';
import { ContractItem, ContractItemsResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import {
  postProposition,
  updateProposition,
  useGetContractsInfinite,
  useGetNmckById,
} from '@/api/nmckApi/nmckApi';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { eventsGTM, sendEventGTM } from '@/metrika';
import {
  getEmptyPropositionUpdateBody,
  getFirstEmptyProposition,
  getNewPropositionUpdateBody,
  rowClassName,
} from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useInfiniteList } from '@/hooks';
import { NmckContractsRequestParams } from '@/components/NmckGrid/NmckGridContracts/types';
import { TableFooter } from '@/components/Table/TableFooter/TableFooter';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import { ContractsTableIncreasingFactorSwitch } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableIncreasingFactorSwitch/ContractsTableIncreasingFactorSwitch';
import { ContractsTableSortSelect } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableSortSelect/ContractsTableSortSelect';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';

type Props = {
  record: NmckRow;
};

export const NmckGridContractsTable = memo(({ record }: Props) => {
  const { requestFilter, pageSize } = useNmckGridContractsContext();
  const { changePageSize } = useNmckGridContractsActionsContext();
  const { list, isLoading, isMoreValidating, hasMoreResults, loadMore } = useInfiniteList<
    NmckContractsRequestParams,
    ContractItem,
    ContractItemsResponse
  >(useGetContractsInfinite, requestFilter);
  const { updatePropositionWithFallback } = useNmckFallbackDataHandler();
  const { nmckId } = useNmckGridContext();

  const { mutate: refreshCalculation } = useGetNmckById(nmckId);

  const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
    useSortableTableColumns<ContractItem>({ columns: nmckContractsTableColumns });

  const initialSelectedRowKeys = useMemo(
    () => record.propositions.map(({ contractId }) => contractId ?? 0).filter(Boolean),
    [record.propositions],
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(initialSelectedRowKeys);

  useEffect(() => {
    setSelectedRowKeys(initialSelectedRowKeys);
  }, [initialSelectedRowKeys]);

  const handleAddContract = useCallback(
    async (contract: ContractItem) => {
      setSelectedRowKeys((prev) => [...prev, contract.eisId]);
      const firstEmptyProposition = getFirstEmptyProposition(record);

      if (firstEmptyProposition) {
        await updatePropositionWithFallback(
          nmckId,
          record.id,
          firstEmptyProposition,
          getNewPropositionUpdateBody(contract, firstEmptyProposition),
        );
      } else {
        // Этая ветка выполнится уже с существующим nmckId
        const updatedNmck = await postProposition(nmckId);
        const currentRowFromUpdatedNmck = updatedNmck.rows.find(({ id }) => id === record.id);

        const firstEmptyPropositionFromUpdatedNmck =
          getFirstEmptyProposition(currentRowFromUpdatedNmck);

        if (firstEmptyPropositionFromUpdatedNmck) {
          await updateProposition(
            nmckId,
            record.id,
            firstEmptyPropositionFromUpdatedNmck,
            getNewPropositionUpdateBody(contract, firstEmptyPropositionFromUpdatedNmck),
          );
          await refreshCalculation();
        }
      }
    },
    [nmckId, record, refreshCalculation, updatePropositionWithFallback],
  );

  const handleRemoveContract = useCallback(
    async (contract: ContractItem) => {
      setSelectedRowKeys((prev) => prev.filter((key) => key !== contract.eisId));
      const propositionByContract = record.propositions.find(
        ({ contractId }) => contractId === contract.eisId,
      );

      if (propositionByContract) {
        await updateProposition(
          nmckId,
          record.id,
          propositionByContract,
          getEmptyPropositionUpdateBody(),
        );
        await refreshCalculation();
      }
    },
    [nmckId, record.id, record.propositions, refreshCalculation],
  );

  const onRowSelect = useCallback<SelectionSelectFn<ContractItem>>(
    async (contract: ContractItem, selected: boolean) => {
      sendEventGTM(eventsGTM.nmckContractSelect);
      if (selected) {
        await handleAddContract(contract);
      } else {
        await handleRemoveContract(contract);
      }
    },
    [handleAddContract, handleRemoveContract],
  );
  const rowSelection: TableRowSelection<ContractItem> = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: onRowSelect,
      hideSelectAll: true,
    }),
    [selectedRowKeys, onRowSelect],
  );

  const onRowClick = useCallback(
    async (contract: ContractItem) => {
      sendEventGTM(eventsGTM.nmckContractSelect);
      if (selectedRowKeys.includes(contract.eisId)) {
        setSelectedRowKeys((prev) => prev.filter((key) => key !== contract.eisId));
        await handleRemoveContract(contract);
      } else {
        await handleAddContract(contract);
      }
    },
    [handleAddContract, handleRemoveContract, selectedRowKeys],
  );

  const onRow: TableProps['onRow'] = useCallback(
    (record: ContractItem) => ({
      onClick: () => onRowClick(record),
    }),
    [onRowClick],
  );

  const tableFooter = useCallback(
    () => (
      <TableFooter
        tokenBasedPagination={{
          hasMoreResults,
          pageSize,
          onLoadMore: loadMore,
          onPageSizeChange: changePageSize,
          isDataLoading: isMoreValidating,
        }}
        wrapperClassName={styles.tableFooter}
      />
    ),
    [changePageSize, hasMoreResults, isMoreValidating, loadMore, pageSize],
  );

  return (
    <div className={styles.wrapper}>
      <Flex
        align="center"
        justify="space-between"
        className={styles.settings}
      >
        <Flex
          align="center"
          gap={24}
        >
          <ContractsTableSortSelect />
          <ContractsTableIncreasingFactorSwitch />
        </Flex>
        <TableColumnsSettings
          orderedColumnMap={orderedColumnsMap}
          setOrderedColumnMap={setOrderedColumnsMap}
          columnsData={orderedColumns}
        />
      </Flex>
      <AutoScrollTable
        loading={isLoading}
        rowKey="eisId"
        rowSelection={rowSelection}
        onRow={onRow}
        rowClassName={rowClassName}
        className={styles.table}
        columns={orderedColumns}
        dataSource={list}
        pagination={false}
        footer={tableFooter}
      />
    </div>
  );
});

NmckGridContractsTable.displayName = 'NmckGridContractsTable';
