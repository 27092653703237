import { Flex, TableProps, Tooltip } from 'antd';
import { PurchaseInProcess } from '@/api/currentApi/currentApiTypes';
import Link from 'next/link';
import styles from '@/widgets/CurrentPage/components/ProcessTable/ProcessTable.module.scss';
import { formatPrice } from '@/utils/formatPrice';
import cx from 'classnames';
import Question from 'public/icons/questionRound.svg';
import { currentProcessTableFormatString } from '@/widgets/CurrentPage/components/ProcessTable/ProcessTable.utils';
import { ProcessTableRegNumLink } from '@/widgets/CurrentPage/components/ProcessTable/components/ProcessTableRegNumLink/ProcessTableRegNumLink';

export const currentProcessTableColumns: TableProps<PurchaseInProcess>['columns'] = [
  {
    title: 'Наименование объекта закупки',
    dataIndex: 'title',
    key: 'title',
    width: 762,
    render: (_, row: PurchaseInProcess) => <ProcessTableRegNumLink row={row} />,
  },
  {
    title: 'НЦМК, ₽',
    dataIndex: 'nmck',
    key: 'nmck',
    width: 130,
    className: styles.nmckRow,
    render: (_, { nmck }) => formatPrice(nmck),
  },
  {
    title: 'Способ закупки',
    dataIndex: 'placingWay',
    key: 'placingWay',
    width: 195,
    render: (_, { placingWay }) => currentProcessTableFormatString(placingWay),
  },
  {
    title: 'Следующий шаг',
    dataIndex: 'nextStep',
    key: 'nextStep',
    width: 300,
    render: (_, { nextStep }) => currentProcessTableFormatString(nextStep),
  },
  {
    title: 'Срок',
    dataIndex: 'dates',
    key: 'dates',
    className: styles.datesRow,
    render: (_, { dates, datesTipText, datesTipLinkText, datesTipLinkUrl, expired }) => {
      return (
        <Flex
          align="center"
          gap={8}
        >
          <span className={cx({ [styles.expired]: expired })}>{dates}</span>
          <Tooltip
            title={
              <p className={styles.tooltipText}>
                {datesTipText}
                <br />
                {datesTipLinkText &&
                  (datesTipLinkUrl ? (
                    <Link href={datesTipLinkUrl}>({datesTipLinkText})</Link>
                  ) : (
                    <p>({datesTipLinkText})</p>
                  ))}
              </p>
            }
            placement="bottom"
          >
            <Question className="hint-icon" />
          </Tooltip>
        </Flex>
      );
    },
  },
];
