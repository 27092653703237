import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterUnitMeasure.module.scss';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';
import { OptionWithNumberValue } from '@/types/types';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';

export const ContractsFilterUnitMeasure = memo(() => {
  const { nmckId } = useNmckGridContext();
  const { row } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleUnitChange = useCallback(
    (option?: OptionWithNumberValue) => {
      setFilter((prev) => ({
        ...prev,
        unitMeasure: option?.value,
      }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Единица измерения"
      field={
        <UnitMeasureSelect
          allowClear
          nmckId={nmckId}
          rowId={row.id}
          className={styles.select}
          placeholder="Выберите единицу измерения"
          onChange={handleUnitChange}
        />
      }
    />
  );
});

ContractsFilterUnitMeasure.displayName = 'ContractsFilterUnitMeasure';
