import { memo, useCallback, useState, MouseEvent } from 'react';
import { isNumber } from 'lodash';
import { Button, Tooltip } from 'antd';
import CrossIcon from '/public/icons/cross.svg';
import { NmckRow, Proposition } from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridPropositionClearButton.module.scss';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { getEmptyPropositionUpdateBody } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';

type Props = {
  rowId: NmckRow['id'];
  proposition: Proposition;
};

export const NmckGridPropositionClearButton = memo(({ proposition, rowId }: Props) => {
  const { nmckId } = useNmckGridContext();
  const { updatePropositionWithFallback } = useNmckFallbackDataHandler();
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);

  const handleDeleteButtonClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      setIsDeleteButtonLoading(true);
      try {
        await updatePropositionWithFallback(
          nmckId,
          rowId,
          proposition,
          getEmptyPropositionUpdateBody(),
        );
        setIsDeleteButtonLoading(false);
      } catch (err: unknown) {
        setIsDeleteButtonLoading(false);
      }
    },
    [nmckId, proposition, rowId, updatePropositionWithFallback],
  );

  return (
    isNumber(proposition.price) && (
      <Tooltip title="Очистить цену">
        <Button
          className={styles.button}
          loading={isDeleteButtonLoading}
          size="small"
          type="link"
          icon={<CrossIcon />}
          onClick={handleDeleteButtonClick}
        />
      </Tooltip>
    )
  );
});

NmckGridPropositionClearButton.displayName = 'NmckGridPropositionClearButton';
