import useSWR from 'swr';
import {
  AverageContractPrice,
  AverageContractPriceResponse,
  ContractPrice,
  ContractPriceResponse,
  Drug,
  KPPrice,
  LimitPrice,
  LimitPriceResponse,
  NmckDrugsByIdResponse,
  NmckDrugsRow,
} from '@/api/nmckDrugsApi/nmckDrugsApiTypes';
import {
  deleteData,
  downloadFile,
  fetcher,
  getData,
  postData,
  putData,
} from '@/api/commonApi/commonApi';
import { DictionaryFilter } from '@/widgets/NmckDrugs/components/DrugDictionaryModal/types';
import { isMoment } from 'moment';
import { formatDate } from '@/utils';
import { isNil, pick } from 'lodash';
import {
  AverageContractPriceId,
  AveragePriceRequestFilter,
} from '@/widgets/NmckDrugs/components/DrugsGridExpandableTabs/components/tabs/AveragePriceTab/types';
import {
  DirectContract,
  DirectContractUpdateBody,
} from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { BDUIFilterParam } from '@/components/BDUIFilter/types';
import { PaginationV1 } from '@/components/Table/TablePagination/types';
import { setURLParamsByBDUIFilters } from '@/utils/setURLParamsByBDUIFilters';
import {
  LimitPriceAdditionalFilter,
  LimitPriceSort,
} from '@/widgets/NmckDrugs/components/DrugsGridExpandableTabs/components/tabs/LimitPriceTab/types';
import useSWRImmutable from 'swr/immutable';
import { NMCK_DRUGS_BY_ID_FALLBACK } from '@/widgets/NmckDrugs/constants';
import { dateFormat } from '@/constants';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';
import { getSWRInfinitePaginationToken } from '@/utils/getSWRInfinitePaginationToken';
import { ContractPriceRequestFilter } from '@/widgets/NmckDrugs/components/DrugsGridExpandableTabs/components/tabs/ContractPriceTab/types';
import {
  DRUGS_DOCX_REPORT_NAME,
  DRUGS_XLSX_REPORT_NAME,
} from '@/api/nmckDrugsApi/nmckDrugsApi.constants';
import { CompatibilityResponse } from '@/api/compatibilityApi/compatibilityApiTypes';

export const useGetNmckDrugsById = (nmckId: NmckDrugsByIdResponse['id']) => {
  const url = nmckId ? `/nmck/drugs/${nmckId}` : null;
  const fallbackData = nmckId ? undefined : NMCK_DRUGS_BY_ID_FALLBACK;

  return useSWRImmutable(url, getData<NmckDrugsByIdResponse>, {
    fallbackData,
  });
};

export const useGetDrugsAutocompleteList = (prefix: string) => {
  const url = prefix ? `/nmck-drugs/autocomplete?prefix=${prefix}` : null;
  return useSWR(url, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugs = ({ mnn, drugForm, dosage }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnName', mnn.value);
  if (drugForm?.value) params.append('drugForm', drugForm.value);
  if (dosage?.value) params.append('dosage', dosage.value);

  return useSWR(mnn ? `/nmck-drugs/get-drugs?${params}` : null, getData<Drug[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugsDosage = ({ mnn, drugForm }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnTnName', mnn.value);
  if (drugForm?.value) params.append('drugForm', drugForm.value);

  return useSWR(mnn ? `/nmck-drugs/autocomplete-dosage?${params}` : null, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugsDrugForm = ({ mnn, dosage }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnTnName', mnn.value);
  if (dosage?.value) params.append('dosage', dosage.value);

  return useSWR(mnn ? `/nmck-drugs/autocomplete-drug-form?${params}` : null, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetNmckDrugsCompatibility = (nmckId: NmckDrugsByIdResponse['id']) => {
  return useSWRImmutable(
    nmckId ? `/nmck/drugs/${nmckId}/compatibility` : null,
    getData<CompatibilityResponse>,
  );
};

export const useGetLimitPrices = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  filters: BDUIFilterParam[],
  { source }: LimitPriceAdditionalFilter,
  { pageNumber, pageSize }: PaginationV1,
  sort: LimitPriceSort,
) => {
  const params = new URLSearchParams();

  params.append('source', source);
  params.append('pageNumber', String(pageNumber));
  params.append('pageSize', String(pageSize));
  params.append('sort', sort);
  setURLParamsByBDUIFilters(params, filters);

  const url = nmckId && rowId ? `/nmck/drugs/${nmckId}/row/${rowId}/price-limits?${params}` : null;

  return useSWRImmutable(url, getData<LimitPriceResponse>, {
    keepPreviousData: true,
  });
};

export const useGetContractsInfinite = ({
  rowId,
  bduiFilters,
  pageSize,
  sort,
}: ContractPriceRequestFilter): SWRInfiniteResponse<ContractPriceResponse> => {
  return useSWRInfinite<ContractPriceResponse>(
    (index: number, previousPageData: ContractPriceResponse) => {
      const params = new URLSearchParams();
      const token = getSWRInfinitePaginationToken(index, previousPageData);

      token && params.append('token', token);
      params.append('sort', sort);
      params.append('rowId', String(rowId));
      params.append('pageSize', String(pageSize));
      setURLParamsByBDUIFilters(params, bduiFilters);

      return rowId ? `/nmck-drugs/search-contracts?${params}` : null;
    },
    getData<ContractPriceResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
      errorRetryCount: 1,
    },
  );
};

export const useGetAverageContractsInfinite = ({
  filter,
  pageSize,
  sort,
}: AveragePriceRequestFilter): SWRInfiniteResponse<AverageContractPriceResponse> => {
  return useSWRInfinite(
    (index: number, previousPageData: AverageContractPriceResponse) => {
      const params = new URLSearchParams();
      const token = getSWRInfinitePaginationToken(index, previousPageData);

      token && params.append('token', String(token));
      Object.entries({ ...filter, sort, pageSize }).forEach(([key, value]) => {
        if (!isNil(value)) {
          if (isMoment(value)) {
            params.append(key, formatDate(value, dateFormat.serverFormat));
          } else {
            params.append(key, String(value));
          }
        }
      });

      return `/nmck-drugs/search-self-contracts?${params}`;
    },
    getData<AverageContractPriceResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
      errorRetryCount: 1,
    },
  );
};

export const postNewNmckDrugs = (body: {
  copyFromId: NmckDrugsByIdResponse['id'] | null;
}): Promise<NmckDrugsByIdResponse> => {
  return postData('/nmck/drugs', body);
};

export const postNewNmckDrugRow = (nmckId: NmckDrugsByIdResponse['id']) => {
  return postData<NmckDrugsByIdResponse>(`/nmck/drugs/${nmckId}/row`);
};

export const updateNmckDrugsRow = (nmckId: NmckDrugsByIdResponse['id'], row: NmckDrugsRow) =>
  putData<NmckDrugsRow>(`/nmck/drugs/${nmckId}/row/${row.id}`, {
    amount: row.amount,
    unit: row.unit,
    unitCode: row.unitCode,
    klpUUID: row.klpUUID,
    smnnUUID: row.smnnUUID,
    interchangeSmnnUuids: row.interchangeSmnnUuids,
    nds: row.nds,
    wholesaleAddition: row.wholesaleAddition,
  } as NmckDrugsRow);

export const updateNmckDrugs = (nmck: NmckDrugsByIdResponse) => {
  const updateBody = pick(nmck, [
    'versionNumber',
    'name',
    'responsible',
    'calculationPrecision',
    'calculationMethod',
  ]);

  return putData<NmckDrugsByIdResponse, typeof updateBody>(`/nmck/drugs/${nmck.id}`, updateBody);
};

export const deleteNmckDrugRow = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}`);
};

export const postLimitPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  { priceLimitId, consumerTotal }: LimitPrice,
) => {
  return postData(`/nmck/drugs/${nmckId}/row/${rowId}/limit`, {
    priceLimitId,
    consumerTotal,
  });
};

export const deleteLimitPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  priceLimitId: LimitPrice['priceLimitId'],
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/limit/${priceLimitId}`);
};

export const postContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  contractPrice: ContractPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/contract`,
    pick<ContractPrice, keyof ContractPrice>(contractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'price',
      'nds',
      'wholesaleAddition',
      'maxIncreasePriceRatioCoeff',
      'maxIncreasePricePeriodRatioCoeff',
      'dosageUnits',
    ]),
  );
};

export const updateContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  contractPrice: ContractPrice,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/contract/${contractPrice.id}`,
    pick<ContractPrice, keyof ContractPrice>(contractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'price',
      'nds',
      'wholesaleAddition',
      'maxIncreasePriceRatioCoeff',
      'maxIncreasePricePeriodRatioCoeff',
      'dosageUnits',
    ]),
  );
};

export const deleteContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  contractId: ContractPrice['id'],
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/contract/${contractId}`);
};

export const postKPPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  kpPrice: KPPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/kp`,
    pick<KPPrice, keyof KPPrice>(kpPrice, [
      'supplier',
      'pricePerPackage',
      'nds',
      'wholesaleAddition',
      'amountInPackage',
      'dosageUnits',
    ]),
  );
};

export const updateKPPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  kpPrice: KPPrice,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/kp/${kpPrice.nmckDrugsMarketKpId}`,
    pick<KPPrice, keyof KPPrice>(kpPrice, [
      'supplier',
      'pricePerPackage',
      'nds',
      'wholesaleAddition',
      'amountInPackage',
      'dosageUnits',
    ]),
  );
};

export const deleteKPPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  kpId: KPPrice['nmckDrugsMarketKpId'],
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/kp/${kpId}`);
};

export const postAverageContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  averageContractPrice: AverageContractPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/average`,
    pick<AverageContractPrice, keyof AverageContractPrice>(averageContractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'nds',
      'wholesaleAddition',
      'dosageUnits',
      'countPackaged',
    ]),
  );
};

export const updateAverageContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  averageContractPrice: AverageContractPrice,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/average/${averageContractPrice.id}`,
    pick<AverageContractPrice, keyof AverageContractPrice>(averageContractPrice, [
      'nds',
      'wholesaleAddition',
      'dosageUnits',
      'countPackaged',
    ]),
  );
};

export const deleteAverageContractPrice = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  averageContractId: AverageContractPriceId,
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/average/${averageContractId}`);
};

export const postDrugDirectContract = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  directContract: DirectContractUpdateBody,
) => {
  return postData(`/nmck/drugs/${nmckId}/row/${rowId}/direct-contract`, directContract);
};

export const postExistDrugDirectContract = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  averageContract: AverageContractPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${averageContract.directContractId}`,
  );
};

export const updateDrugDirectContract = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  directContractId: DirectContract['id'],
  directContract: DirectContractUpdateBody,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${directContractId}`,
    directContract,
  );
};

export const deleteDrugDirectContract = (
  nmckId: NmckDrugsByIdResponse['id'],
  rowId: NmckDrugsRow['id'],
  directContractId: DirectContract['id'],
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${directContractId}`);
};

export const updateLimitPriceGO = (priceRegistrationDecisionNumber: string) =>
  postData<{
    refreshed: boolean;
  }>('/price-limits-refresh', { priceRegistrationDecisionNumber });

export const getNmckDrugReportXlsx = async (
  nmckId: NmckDrugsByIdResponse['id'],
  fileName: string,
) => {
  const response = await fetcher(`/nmck/drugs/${nmckId}/xlsx`);
  const reportName = fileName ? `${DRUGS_XLSX_REPORT_NAME}_${fileName}` : DRUGS_XLSX_REPORT_NAME;

  await downloadFile(response, reportName.trim());
};

export const getNmckDrugReportDocx = async (
  nmckId: NmckDrugsByIdResponse['id'],
  fileName: string,
) => {
  const response = await fetcher(`/nmck-drugs/${nmckId}/docx`);
  const reportName = fileName ? `${DRUGS_DOCX_REPORT_NAME}_${fileName}` : DRUGS_DOCX_REPORT_NAME;

  await downloadFile(response, reportName.trim(), 'docx');
};

export const postRepeatedDrugCalculation = (nmckId: NmckDrugsByIdResponse['id']) =>
  postData<NmckDrugsByIdResponse>(`/nmck/drugs/${nmckId}/recurrency`);

export const splitNmckDrugsById = async (
  nmckId: NmckDrugsByIdResponse['id'],
): Promise<NmckDrugsByIdResponse['id'][]> => {
  return await postData<NmckDrugsByIdResponse['id'][]>(`/nmck/drugs/${nmckId}/split`);
};
