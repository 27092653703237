import { useCallback, useEffect, useMemo, useState } from 'react';
import { SWRInfiniteResponse } from 'swr/infinite';
import { TokenBasedPageableResponse } from '@/api/commonApi/commonApiTypes';

export type UseInfiniteListResult<
  TEntity,
  TResponse extends TokenBasedPageableResponse<TEntity> = TokenBasedPageableResponse<TEntity>,
> = {
  list?: TEntity[];
  firstResponse?: TResponse;
  size: number;
  isLoading: boolean;
  isMoreValidating: boolean;
  hasMoreResults: boolean;
  loadMore: () => Promise<TResponse[] | undefined>;
};

export const useInfiniteList = <
  TFilter,
  TEntity,
  TResponse extends TokenBasedPageableResponse<TEntity> = TokenBasedPageableResponse<TEntity>,
>(
  useGetSWRInfiniteData: (filter: TFilter) => SWRInfiniteResponse<TResponse>,
  externalFilter: TFilter,
): UseInfiniteListResult<TEntity, TResponse> => {
  const [filter, setFilter] = useState(externalFilter);
  const { data, size, setSize, isValidating, isLoading } = useGetSWRInfiniteData(filter);

  const list = useMemo(() => data?.flatMap((page) => page.results), [data]);
  const firstResponse = useMemo(() => data?.at(0), [data]);
  const hasMoreResults = !!data?.at(-1)?.hasMoreResults;
  const isMoreValidating = isValidating && !!data?.length && !!size;

  const loadMore = useCallback(() => setSize((prev) => ++prev), [setSize]);

  useEffect(() => {
    setFilter(externalFilter);
  }, [externalFilter, setSize]);

  return {
    list,
    firstResponse,
    size,
    isLoading,
    isMoreValidating,
    hasMoreResults,
    loadMore,
  };
};
