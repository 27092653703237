import { TreeSelectDataResponse } from '@/api/nmckApi/nmckApiTypes';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

export const getTreeDataMap = (data: TreeSelectDataResponse): DefaultOptionType[] => {
  return data.map((item) => ({
    ...item,
    label: item.name,
    value: item.id,
    ...(item.children ? { children: getTreeDataMap(item.children) } : {}),
  }));
};
