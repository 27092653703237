import { memo, useCallback } from 'react';
import { AsyncButton } from '@/components';
import TrashIcon from 'public/icons/trash.svg';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { deleteNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { Tooltip } from 'antd';
import styles from './NmckGridDeleteButton.module.scss';

type Props = {
  row: NmckRow;
};

export const NmckGridDeleteButton = memo(({ row }: Props) => {
  const { nmckId } = useNmckGridContext();
  const { data, mutate } = useGetNmckById(nmckId);

  const handleButtonClick = useCallback(async () => {
    await deleteNmckRow(nmckId, row.id);
    await mutate();
  }, [mutate, nmckId, row.id]);

  return (
    (data?.rows ?? []).length > 1 && (
      <Tooltip
        title="Удалить позицию"
        placement="left"
      >
        <AsyncButton
          size="small"
          type="link"
          icon={<TrashIcon />}
          className={styles.button}
          onClick={handleButtonClick}
        />
      </Tooltip>
    )
  );
});

NmckGridDeleteButton.displayName = 'NmckGridDeleteButton';
