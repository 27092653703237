import { memo, useCallback } from 'react';
import styles from './DocsDocumentCellLink.module.scss';
import { Flex } from 'antd';
import ExportIcon from 'public/icons/export.svg';
import Link from 'next/link';
import { Doc } from '@/api/docsApi/docsApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  doc: Doc;
};

export const DocsDocumentCellLink = memo(({ doc }: Props) => {
  const handleLinkClick = useCallback(() => {
    postBillingEvent({
      action: BillingEventType.DOCS_DOWNLOAD_DOC,
      value: doc.documentationName,
    });
  }, [doc.documentationName]);

  return (
    <Link
      href={doc.documentationLink}
      className={styles.documentationText}
      onClick={handleLinkClick}
    >
      <Flex
        gap={8}
        align="center"
      >
        <ExportIcon className="basic-icon" />
        {doc.documentationName}
      </Flex>
    </Link>
  );
});

DocsDocumentCellLink.displayName = 'DocsDocumentCellLink';
