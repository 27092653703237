import { memo, useCallback } from 'react';
import styles from './PlannedTableIkzLink.module.scss';
import ExportDiagonalIcon from 'public/icons/exportDiagonal.svg';
import Link from 'next/link';
import { PurchasePlanItem } from '@/api/currentApi/currentApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  plannedPurchase: PurchasePlanItem;
};

export const PlannedTableIkzLink = memo(({ plannedPurchase: { ikz, ikzHref } }: Props) => {
  const handleLinkClick = useCallback(() => {
    postBillingEvent({ action: BillingEventType.CURRENT_PURCHASES_GO_TO_EIS, value: ikzHref });
  }, [ikzHref]);

  return (
    <Link
      href={ikzHref}
      key={ikz}
      className={styles.ikz}
      target="_blank"
      onClick={handleLinkClick}
    >
      <ExportDiagonalIcon className="basic-icon" />
      <span className={styles.ikzText}>{ikz}</span>
    </Link>
  );
});

PlannedTableIkzLink.displayName = 'PlannedTableIkzLink';
