import { memo, useCallback } from 'react';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';
import { Tooltip } from 'antd';
import Link from 'next/link';
import QuestionIcon from 'public/icons/questionRound.svg';
import { UiSwitch } from '@/components';
import { SwitchChangeEventHandler } from 'antd/es/switch';

export const SuppliersFilterPenaltyField = memo(() => {
  const { filter } = useSuppliersContext();
  const { setFilter } = useSuppliersActionsContext();

  const handlePenaltyChange = useCallback<SwitchChangeEventHandler>(
    (checked) => {
      setFilter((prev) => ({ ...prev, hasPenalty: checked }));
    },
    [setFilter],
  );

  return (
    <UiSwitch
      value={filter.hasPenalty}
      onChange={handlePenaltyChange}
    >
      <span>Контракты исполнены без неустоек</span>
      <Tooltip
        title={
          <div>
            Согласно{' '}
            <Link
              href="https://www.garant.ru/products/ipo/prime/doc/70373958/"
              target="_blank"
            >
              п. 3.9 Метод. рекомендаций (утв. приказом Минэкономразвития РФ от 02.10.2013 №567)
            </Link>{' '}
            запрос о предоставлении КП рекомендуется направлять поставщикам, исполнявшим в течение
            последних 3 лет аналогичные контракты без применения к поставщику неустоек (штрафов,
            пеней).
          </div>
        }
      >
        <QuestionIcon className="hint-icon" />
      </Tooltip>
    </UiSwitch>
  );
});

SuppliersFilterPenaltyField.displayName = 'SuppliersFilterPenaltyField';
