import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';
import styles from './ContractsFilterRegion.module.scss';
import { Flex, Radio, RadioChangeEvent } from 'antd';
import { regionTypeOptions } from '@/components/NmckGrid/NmckGridContracts/constants';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import { RegionType } from '@/components/NmckGrid/NmckGridContracts/types';

export const ContractsFilterRegion = memo(() => {
  const { filter } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleRegionsChange = useCallback(
    (values: number[]) => {
      setFilter((prev) => ({ ...prev, regionIds: values }));
    },
    [setFilter],
  );

  const handleRegionTypeChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      setFilter((prev) => ({ ...prev, regionType: value as RegionType }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Регион"
      field={
        <Flex
          gap={16}
          align="center"
        >
          <RegionsTreeSelect
            value={filter.regionIds}
            className={styles.select}
            onRegionsChange={handleRegionsChange}
          />
          <Radio.Group
            value={filter.regionType}
            name="regionType"
            onChange={handleRegionTypeChange}
            options={regionTypeOptions}
          />
        </Flex>
      }
    />
  );
});

ContractsFilterRegion.displayName = 'ContractsFilterRegion';
