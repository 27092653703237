import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CompatibilityTable.module.scss';
import { TableProps, Typography } from 'antd';
import {
  CompatibilityCell,
  CompatibilityRow,
  CompatibilityCellItem,
} from '@/api/compatibilityApi/compatibilityApiTypes';
import { CompatibilityTableCell } from '@/components/Compatibility/CompatibilityTable/components/CompatibilityTableCell/CompatibilityTableCell';
import cx from 'classnames';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';
import { CompatibilityTableRemoveCell } from '@/components/Compatibility/CompatibilityTable/components/CompatibilityTableRemoveCell/CompatibilityTableRemoveCell';
import { CompatibilityTableProps } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable.types';
import { CompatibilityTableExpandedRow } from '@/components/Compatibility/CompatibilityTable/components/CompatibilityTableExpandedRow/CompatibilityTableExpandedRow';
import { CompatibilityTableMedDeviceCheckboxCell } from '@/components/Compatibility/CompatibilityTable/components/CompatibilityTableMedDeviceCheckboxCell/CompatibilityTableMedDeviceCheckboxCell';

export const CompatibilityTable = memo(
  ({
    id,
    rows,
    header,
    isLoading,
    withVerticalScroll,
    isDrugs,

    onUpdateCell,
    onDeleteRow,
    refreshTableData,
    onCreateMedDeviceSelection,
    onDeleteMedDeviceSelection,
  }: CompatibilityTableProps) => {
    const mappedRows = useMemo<CompatibilityRow[]>(
      () =>
        rows
          .map((row) => ({
            ...row,
            cells: row.cells.map((cell, index) => ({
              ...cell,
              columnCode: row.id + header[index].caption,
            })),
          }))
          .sort((a, b) => a.purchaseNumber - b.purchaseNumber)
          .reduce<CompatibilityRow[]>((acc, row, index, sortedRows) => {
            if (index === 0 || sortedRows[index - 1].purchaseNumber !== row.purchaseNumber) {
              acc.push({
                ...row,
                id: row.id + 0.5,
                calculationMethod: `Закупка ${row.purchaseNumber}`,
                isGroup: true,
              });
            }
            acc.push(row);
            return acc;
          }, []),
      [header, rows],
    );

    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [currentExpandableCell, setCurrentExpandableCell] = useState<
      CompatibilityCell | undefined
    >();

    const expandReset = useCallback(() => {
      setCurrentExpandableCell(undefined);
      setExpandedRowKeys([]);
    }, []);

    const handleExpandClick = useCallback(
      (record: CompatibilityRow, cell: CompatibilityCell) => {
        const isSameCell =
          expandedRowKeys[0] === record.id && currentExpandableCell?.columnCode === cell.columnCode;

        if (isSameCell) {
          expandReset();
        } else {
          setCurrentExpandableCell(cell);
          setExpandedRowKeys([record.id]);
        }
      },
      [currentExpandableCell?.columnCode, expandReset, expandedRowKeys],
    );

    const columns: TableProps<CompatibilityRow>['columns'] = useMemo(
      () => [
        {
          title: 'Метод',
          dataIndex: 'calculationMethod',
          key: 'calculationMethod',
          hidden: isDrugs,
          width: 160,
        },
        {
          title: 'ОКПД2',
          dataIndex: 'code',
          key: 'code',
          width: 140,
          hidden: isDrugs,
          render: (_, { isGroup, code }) => !isGroup && code,
        },
        {
          title: isDrugs ? 'Лекарственный препарат' : 'Наименование',
          dataIndex: 'name',
          key: 'name',
          render: (_, { name, calculationMethod, isGroup }) => {
            if (!isGroup) {
              return (
                <Typography.Paragraph
                  ellipsis={{ rows: 2, tooltip: true }}
                  className={styles.name}
                >
                  {name || '—'}
                </Typography.Paragraph>
              );
            }

            return isDrugs && calculationMethod;
          },
        },
        {
          title: 'Мед. изделие',
          dataIndex: 'possiblyMedDevice',
          key: 'possiblyMedDevice',
          align: 'center',
          hidden: isDrugs,
          render: (_, row: CompatibilityRow) =>
            !row.isGroup && (
              <CompatibilityTableMedDeviceCheckboxCell
                id={id}
                row={row}
                onCreateMedDeviceSelection={onCreateMedDeviceSelection}
                onDeleteMedDeviceSelection={onDeleteMedDeviceSelection}
                refreshTableData={refreshTableData}
              />
            ),
        },
        ...header.map((item, headerIndex) => ({
          title: item.caption,
          render: (_: unknown, row: CompatibilityRow) => {
            const cell = row.cells[headerIndex];

            return (
              !row.isGroup && (
                <CompatibilityTableCell
                  cell={cell}
                  row={row}
                  isDrugs={isDrugs}
                  onClick={handleExpandClick}
                  currentExpandRowId={expandedRowKeys[0]}
                  currentExpandCell={currentExpandableCell}
                />
              )
            );
          },
        })),
        {
          title: '',
          dataIndex: 'purchaseNumber',
          key: 'purchaseNumber',
          hidden: !onDeleteRow,
          render: (_, row: CompatibilityRow) =>
            onDeleteRow &&
            !row.isGroup && (
              <CompatibilityTableRemoveCell
                row={row}
                onDeleteRow={onDeleteRow}
              />
            ),
        },
      ],
      [
        currentExpandableCell,
        expandedRowKeys,
        handleExpandClick,
        header,
        id,
        isDrugs,
        onCreateMedDeviceSelection,
        onDeleteMedDeviceSelection,
        onDeleteRow,
        refreshTableData,
      ],
    );

    const handleUpdateCompatibilityCell = useCallback(
      async (selectedItemId: CompatibilityCellItem['id']) => {
        if (expandedRowKeys[0] && onUpdateCell) {
          await onUpdateCell(id, expandedRowKeys[0], {
            selectedId: selectedItemId,
          });
          expandReset();
          await refreshTableData();
        }
      },
      [expandReset, expandedRowKeys, id, refreshTableData, onUpdateCell],
    );

    const expandedRowRender = useCallback(
      () => (
        <CompatibilityTableExpandedRow
          updateCompatibilityCell={handleUpdateCompatibilityCell}
          expandableCell={currentExpandableCell}
        />
      ),
      [currentExpandableCell, handleUpdateCompatibilityCell],
    );

    const rowClassName = useCallback(
      ({ id, isGroup }: CompatibilityRow) =>
        cx({
          [styles.purchaseNumberRow]: isGroup,
          [styles.expandedRow]: expandedRowKeys.includes(id),
        }),
      [expandedRowKeys],
    );

    const tableExpandable: TableProps['expandable'] = useMemo(
      () => ({
        expandedRowKeys,
        expandedRowRender,
        showExpandColumn: false,
      }),
      [expandedRowKeys, expandedRowRender],
    );

    useEffect(() => {
      expandReset();
    }, [mappedRows, expandReset]);

    return (
      <AutoScrollTable
        sliceHeaderHeight={false}
        className={cx(styles.table, {
          [styles.tableWithVerticalScroll]: withVerticalScroll,
        })}
        rowClassName={rowClassName}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={mappedRows}
        loading={isLoading}
        expandable={tableExpandable}
      />
    );
  },
);

CompatibilityTable.displayName = 'CompatibilityTable';
