export const pageRoutes = {
  etpSignIn: '/app/etpsignin',

  okpd2: '/okpd2',
  ktru: '/ktru',

  skp: '/skp',

  docs: '/purchases-docs',

  current: '/current',

  nmck: '/nmck',
  nmckDrugs: '/nmck/drugs',
  nmckGuard: '/nmck/guard',
  nmckAnalysis: '/nmck/analysis',
  nmckMedDevices: '/nmck/med-devices',
  nmckHistory: '/nmck/history',

  planning: '/planning',
  planningCurrent: '/planning/current',
  planningAll: '/planning/all',

  reestr: '/reestr',
  reestrAll: '/reestr/all',
  reestrDirect: '/reestr/direct',
  reestrSmpReport: '/reestr/smpreport',
  reestrSummary: '/reestr/summary',
  reestrPenaltiesCalc: '/reestr/penalties-calc',
} as const;

export type PageRouteValuesType = (typeof pageRoutes)[keyof typeof pageRoutes];
