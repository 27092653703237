import { memo } from 'react';
import styles from './NmckHistoryListColumns.module.scss';
import { Flex } from 'antd';

export const NmckHistoryListColumns = memo(() => {
  return (
    <Flex
      align="center"
      gap={16}
      className={styles.listHeader}
    >
      <div className={styles.columnDate}>Дата</div>
      <div className={styles.columnName}>Название расчёта</div>
      <div className={styles.columnMethod}>Метод закупки</div>
      <div className={styles.columnPrice}>Итого</div>
    </Flex>
  );
});

NmckHistoryListColumns.displayName = 'NmckHistoryListColumns';
