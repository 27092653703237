import { ReportFormModalProps } from '@/features';

export const BUG_REPORT_MODAL_CONFIG: ReportFormModalProps['config'] = {
  title: 'Опишите проблему или предложение по улучшению',
  description:
    'Укажите подробности, которые помогут быстрее разобраться в проблеме и исправить ее. Например: ссылка на расчет НМЦК или код ОКПД',
  emailPlaceholder: 'E-mail для ответа',
  feedbackPlaceholder: 'Описание проблемы или предложение по улучшению',
  submitButtonText: 'Отправить',
  requiredFeedback: true,
};
