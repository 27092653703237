'use client';

import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, ConfigProvider, Tabs } from 'antd';
import Print from '/public/icons/print.svg';
import { Contracts, Procedures } from './components';
import { PrintModal } from './components/Procedures/components/PrintModal/PrintModal';
import { useGetDeadlineCalculation } from '@/api/skpApi/skpApi';
import {
  DeadlineCalculation,
  DeadlineCalculationResponse,
  DeadlineType,
} from '@/api/skpApi/skpApiTypes';
import styles from './SkpPage.module.scss';
import { LoadingWrapper } from '@/components/Ui';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';
import { SkpPurchaseMethodSelect } from '@/widgets/Skp/components/SkpPurchaseMethodSelect/SkpPurchaseMethodSelect';
import { skpPageTabs } from '@/widgets/Skp/SkpPage.constants';

const theme = {
  components: {
    Tabs: {
      horizontalMargin: '0',
    },
  },
};

export const SkpPage = memo(() => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<DeadlineType>(DeadlineType.PROCEDURES44);
  const [name, setName] = useState<string>();
  const [currentCalculation, setCurrentCalculation] = useState<DeadlineCalculation | undefined>();
  const [deadlineCalculationData, setDeadlineCalculationData] =
    useState<DeadlineCalculationResponse>([]);
  const initialCalculationData = useRef<DeadlineCalculationResponse>();

  const { data: deadlineCalculation, isLoading } = useGetDeadlineCalculation(activeTab);

  useEffect(() => {
    if (deadlineCalculation) {
      setName(deadlineCalculation[0].name);
      setDeadlineCalculationData(deadlineCalculation);

      if (!initialCalculationData.current) {
        initialCalculationData.current = deadlineCalculation;
      }
    }
  }, [deadlineCalculation, initialCalculationData]);

  useEffect(() => {
    if (name) {
      setCurrentCalculation(deadlineCalculationData.find((item) => item.name === name));
    }
  }, [name, deadlineCalculationData]);

  useEffect(() => {
    postBillingEvent({
      action: BillingEventType.DEADLINES_OPEN,
    });
  }, []);

  const onChange = useCallback((key: string) => {
    setActiveTab(key as DeadlineType);
  }, []);

  const handleOpenPreviewModal = useCallback(() => {
    setOpen(true);
    postBillingEvent({
      action: BillingEventType.DEADLINES_VIEW,
    });
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <div className={styles.headerWrapper}>
        <h1 className={styles.title}>Расчёт сроков </h1>
        <Tabs
          defaultActiveKey="1"
          items={skpPageTabs}
          className={styles.tabs}
          onChange={onChange}
        />
        {activeTab !== DeadlineType.CONTRACTS44 && (
          <>
            <SkpPurchaseMethodSelect
              name={name}
              activeTab={activeTab}
              setName={setName}
            />
            <Button
              type="primary"
              icon={<Print />}
              className="button-with-icon"
              onClick={handleOpenPreviewModal}
            >
              Печать/просмотр
            </Button>
          </>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {(activeTab === DeadlineType.PROCEDURES44 || activeTab === DeadlineType.PROCEDURES223) && (
          <LoadingWrapper isLoading={isLoading}>
            {currentCalculation && (
              <Procedures
                currentCalculation={currentCalculation}
                setDeadlineCalculationData={setDeadlineCalculationData}
                initialCalculationData={initialCalculationData.current}
              />
            )}
          </LoadingWrapper>
        )}
        {activeTab === DeadlineType.CONTRACTS44 && <Contracts />}
      </div>
      {currentCalculation && (
        <PrintModal
          currentCalculation={currentCalculation}
          open={open}
          onCancel={() => setOpen(false)}
        />
      )}
    </ConfigProvider>
  );
});
SkpPage.displayName = 'SkpPage';
