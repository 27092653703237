import {
  KTRU_CODE_REGEX,
  OKPD_CODE_REGEX,
} from '@/layouts/OkpdLayout/components/OkpdLayoutSearch/OkpdLayoutSearch.constants';

export const isValidOkpdCode = (searchString: string) => {
  return !!searchString && OKPD_CODE_REGEX.test(searchString);
};

export const isValidKtruCode = (searchString: string) => {
  return !!searchString && KTRU_CODE_REGEX.test(searchString);
};
