import { memo } from 'react';
import { KtruDetail } from '@/api/okpdApi/okpdApiTypes';
import styles from './KtruDetailTabsGeneralInfo.module.scss';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { KtruInfos } from '@/features';

type Props = {
  ktru: KtruDetail;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruDetailTabsGeneralInfo = memo(({ ktru, setModalPageParams }: Props) => {
  return (
    ktru.ktruInfos && (
      <div className={styles.list}>
        <KtruInfos
          infos={ktru.ktruInfos}
          setModalPageParams={setModalPageParams}
        />
      </div>
    )
  );
});

KtruDetailTabsGeneralInfo.displayName = 'KtruDetailTabsGeneralInfo';
