import { Key, memo, useCallback, useState } from 'react';
import { Button, Flex } from 'antd';
import ListIcon from 'public/icons/list.svg';
import styles from './OkpdKtruDetailTRU.module.scss';
import {
  postOkpdKtruToTRUList,
  useGetKtruDetail,
  useGetOkpdDetail,
  useGetTruCount,
} from '@/api/okpdApi/okpdApi';
import { AsyncButton, Badge } from '@/components';
import { OkpdKtruDetailCompatibilityModal } from '@/components/OkpdKtruDetail/OkpdKtruDetailCompatibilityModal/OkpdKtruDetailCompatibilityModal';
import { getFormattedNkmi } from '@/widgets/OkpdKtru/utils';
import { useOkpdRoute } from '@/widgets/OkpdKtru/hooks/useOkpdRoute';
import { OkpdKtruModalPageParams } from '@/components/OkpdFlowModal/types';
import cx from 'classnames';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
};

export const OkpdKtruDetailTRU = memo(({ modalPageParams }: Props) => {
  const { code, isOkpdPage, isKtruPage } = useOkpdRoute({ modalPageParams });
  const { data: okpd } = useGetOkpdDetail(isOkpdPage ? code : '');
  const { data: ktru } = useGetKtruDetail(isKtruPage ? code : '');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState<Key>();
  const { data: { count } = {}, mutate: refreshCount } = useGetTruCount();

  const isActionsVisible = !!okpd || !!ktru || !!count;

  const handleAddToListButtonClick = useCallback(async () => {
    await postOkpdKtruToTRUList({
      okpdCode: okpd?.code,
      ktruCode: ktru?.code,
      nkmiId: getFormattedNkmi(ktru?.nkmis ?? []),
    });
    await refreshCount();
    setModalKey(Math.random());
  }, [ktru?.code, ktru?.nkmis, okpd?.code, refreshCount]);

  const handleShowListButtonClick = useCallback(() => setIsModalOpen(true), []);

  return (
    isActionsVisible && (
      <>
        <Flex
          align="center"
          gap={20}
        >
          {(!!okpd || !!ktru) && (
            <AsyncButton
              type="link"
              size="small"
              icon={<ListIcon />}
              className="button-with-icon"
              onClick={handleAddToListButtonClick}
            >
              Добавить к списку
            </AsyncButton>
          )}
          {!!count && (
            <Button
              type="link"
              size="small"
              className={cx('button-with-icon', styles.listButton)}
              disabled={!count}
              onClick={handleShowListButtonClick}
            >
              <span>Показать список</span>
              <Badge count={count} />
            </Button>
          )}
        </Flex>
        {!!count && (
          <OkpdKtruDetailCompatibilityModal
            modalKey={modalKey}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            refreshCount={refreshCount}
          />
        )}
      </>
    )
  );
});

OkpdKtruDetailTRU.displayName = 'OkpdKtruDetailTRU';
