import { memo } from 'react';
import { Button, ButtonProps, Tooltip } from 'antd';
import UndoIcon from 'public/icons/undo.svg';
import { useClientRender, useMediaQuery } from '@/hooks';

type Props = Pick<ButtonProps, 'onClick'>;

export const OldVersionReportButton = memo(({ onClick }: Props) => {
  const { maxMD } = useMediaQuery();
  const { isClientRender } = useClientRender();

  return (
    isClientRender && (
      <Tooltip
        title={maxMD && 'Старая версия'}
        placement="left"
      >
        <Button
          type="link"
          size="small"
          className="button-with-icon"
          icon={<UndoIcon />}
          onClick={onClick}
        >
          {!maxMD && 'Старая версия'}
        </Button>
      </Tooltip>
    )
  );
});

OldVersionReportButton.displayName = 'OldVersionReportButton';
