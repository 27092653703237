import { memo, useCallback } from 'react';
import { regNumberLink } from '@/widgets/CurrentPage/CurrentPage.constants';
import styles from './ProcessTableRegNumLink.module.scss';
import ExportDiagonalIcon from 'public/icons/exportDiagonal.svg';
import { Typography } from 'antd';
import Link from 'next/link';
import { PurchaseInProcess } from '@/api/currentApi/currentApiTypes';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

type Props = {
  row: PurchaseInProcess;
};

export const ProcessTableRegNumLink = memo(({ row: { regNum, title } }: Props) => {
  const handleLinkClick = useCallback(() => {
    postBillingEvent({
      action: BillingEventType.CURRENT_PURCHASES_GO_TO_EIS,
      value: regNumberLink + regNum,
    });
  }, [regNum]);

  return (
    <Link
      href={regNumberLink + regNum}
      className={styles.title}
      target="_blank"
      onClick={handleLinkClick}
    >
      <ExportDiagonalIcon className="basic-icon" />
      <Typography.Paragraph
        ellipsis={{ rows: 2, tooltip: title }}
        className={styles.titleText}
      >
        {title}
      </Typography.Paragraph>
    </Link>
  );
});

ProcessTableRegNumLink.displayName = 'ProcessTableRegNumLink';
