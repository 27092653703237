import { memo, useCallback, useMemo, useState } from 'react';
import { List, Select } from 'antd';
import moment, { Moment } from 'moment';
import { DeadlineCalculationContracts, Step } from '@/api/skpApi/skpApiTypes';
import { Option } from '@/types/types';
import { DebounceInputNumber, DebounceInputNumberValueType, UiDatePicker } from '@/components';
import { dateFormat } from '@/constants';
import { formatDate } from '@/utils';
import { updateDeadlineCalculationContracts } from '@/api/skpApi/skpApi';
import Vicon from '/public/icons/v.svg';
import Calendar from '/public/icons/calendar.svg';
import styles from './StageItem.module.scss';
import { postBillingEvent } from '@/api/billingApi/billingApi';
import { BillingEventType } from '@/api/billingApi/billingApiTypes';

enum DaysOptionsType {
  EXACT_DATE = 'EXACT_DATE',
  WORKING_DAYS = 'WORKING_DAYS',
  CALENDAR_DAYS = 'CALENDAR_DAYS',
}

const allDaysOptions: Option[] = [
  { label: 'Точная дата', value: DaysOptionsType.EXACT_DATE },
  { label: 'Рабочие дни', value: DaysOptionsType.WORKING_DAYS },
  { label: 'Календарные дни', value: DaysOptionsType.CALENDAR_DAYS },
];

type StageItemProps = {
  step: Step;
  stage: DeadlineCalculationContracts;
};

export const StageItem = memo(({ step, stage }: StageItemProps) => {
  const filteredDaysOptions = useMemo(
    () => allDaysOptions.filter((option) => step.dateCalculateMethodChoices.includes(option.value)),
    [step.dateCalculateMethodChoices],
  );
  const [currentDaysType, setCurrentDaysType] = useState(filteredDaysOptions[0].value);
  const [deadlineValue, setDeadlineValue] = useState(step.termInDaysCount);
  const [deadlineDate, setDeadlineDate] = useState<Moment | null>(moment(step.executionDate));

  const daysChange = useCallback(
    async (value: string) => {
      setCurrentDaysType(value);
      const updatedStep = {
        ...step,
        dateCalculateMethod: value,
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [step, stage],
  );

  const handleDeadlineChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      setDeadlineValue(value || 0);
      const updatedStep = {
        ...step,
        termInDaysCount: value || 0,
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [stage, step],
  );

  const handleDeadlineDateChange = useCallback(
    async (value: Moment) => {
      setDeadlineDate(value);
      const date = formatDate(value, dateFormat.serverFormat);
      const updatedStep = {
        ...step,
        executionDate: date,
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      postBillingEvent({
        action: BillingEventType.DEADLINES_CHANGE_DATE,
        value: date,
      });
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [stage, step],
  );

  return (
    <List.Item className={styles.listItem}>
      <div className={styles.grid}>
        <div className={styles.name}>{step.name}</div>
        <div>{step.law}</div>
        <div>
          <Select
            value={currentDaysType}
            options={filteredDaysOptions}
            suffixIcon={<Vicon className="v-icon" />}
            className={styles.select}
            onChange={daysChange}
          />
        </div>
        <div>
          {step.dateCalculateMethod === DaysOptionsType.EXACT_DATE ? (
            <UiDatePicker
              value={moment(deadlineDate)}
              onChange={handleDeadlineDateChange}
              className={styles.datePicker}
              suffixIcon={<Calendar />}
              allowClear={false}
            />
          ) : (
            <DebounceInputNumber
              min={0}
              className={styles.dealineInput}
              onDebouncedChange={handleDeadlineChange}
              value={deadlineValue}
            />
          )}
        </div>
        <div>{formatDate(deadlineDate || '', dateFormat.frontFormat)}</div>
      </div>
    </List.Item>
  );
});

StageItem.displayName = 'StageItem';
