import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { NmckContractsCountry } from '@/components/NmckGrid/NmckGridContracts/types';
import { nmckContractsCountriesOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterCountry/constants';
import styles from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterCountry/ContractsFilterCountry.module.scss';
import VIcon from 'public/icons/v.svg';
import { Select } from 'antd';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';

export const SuppliersFilterCountryField = memo(() => {
  const { filter } = useSuppliersContext();
  const { setFilter } = useSuppliersActionsContext();

  const handleUnitChange = useCallback(
    (value?: NmckContractsCountry) => {
      setFilter((prev) => ({
        ...prev,
        country: value,
      }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Произведено"
      rowHeight={40}
      field={
        <Select<NmckContractsCountry>
          value={filter.country}
          options={nmckContractsCountriesOptions}
          allowClear
          className={styles.select}
          filterOption={false}
          suffixIcon={<VIcon className="v-icon" />}
          placeholder="Не указано (все страны)"
          onChange={handleUnitChange}
        />
      }
    />
  );
});

SuppliersFilterCountryField.displayName = 'SuppliersFilterCountryField';
