import { memo, useMemo } from 'react';
import styles from './CompatibilityTableExpandedRow.module.scss';
import {
  CompatibilityCell,
  CompatibilityCellItem,
} from '@/api/compatibilityApi/compatibilityApiTypes';
import { CompatibilityTableExpandedRowRadioGroup } from '@/components/Compatibility/CompatibilityTable/components/CompatibilityTableExpandedRow/components/CompatibilityTableExpandedRowRadioGroup/CompatibilityTableExpandedRowRadioGroup';

type Props = {
  expandableCell?: CompatibilityCell;
  updateCompatibilityCell: (selectedItemId: CompatibilityCellItem['id']) => Promise<void>;
};

export const CompatibilityTableExpandedRow = memo(
  ({ expandableCell, updateCompatibilityCell }: Props) => {
    const canViewRadioGroupForm = useMemo(
      () => !!expandableCell?.items.length,
      [expandableCell?.items.length],
    );

    return (
      expandableCell &&
      canViewRadioGroupForm && (
        <div className={styles.expandableContent}>
          <div className={styles.title}>Выберите подходящую позицию из списка</div>

          <CompatibilityTableExpandedRowRadioGroup
            itemsList={expandableCell.items}
            expandableCell={expandableCell}
            updateCompatibilityCell={updateCompatibilityCell}
          />
        </div>
      )
    );
  },
);

CompatibilityTableExpandedRow.displayName = 'CompatibilityTableExpandedRow';
