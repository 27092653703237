import { getData } from '@/api/commonApi/commonApi';
import { ContractDocument } from './docsApiTypes';
import { DocsRequestParams } from '@/widgets/Docs/DocsPage.types';
import { formatDate } from '@/utils';
import { dateFormat } from '@/constants';
import useSWRImmutable from 'swr/immutable';

export const useGetContractDocuments = ({
  filter: { search, publishedFromDate, publishedToDate, regionIds, purchaseMethodIds },
}: DocsRequestParams) => {
  const params = new URLSearchParams();

  params.append('pageSize', '100');
  if (search) params.append('code', search);
  if (publishedFromDate)
    params.append('publishedFrom', formatDate(publishedFromDate, dateFormat.serverFormat));
  if (publishedToDate)
    params.append('publishedTo', formatDate(publishedToDate, dateFormat.serverFormat));
  purchaseMethodIds.forEach((id) => params.append('purchaseMethodId', String(id)));
  regionIds.forEach((id) => params.append('regionId', String(id)));

  return useSWRImmutable(
    search && `/contract-items-with-docs?${params.toString()}`,
    getData<ContractDocument[]>,
  );
};
