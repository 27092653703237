import { memo, useCallback } from 'react';
import { FormFieldRow } from '@/components/Ui/FormFieldRow/FormFieldRow';
import { Radio, RadioChangeEvent } from 'antd';
import { sourceFieldRadioOptions } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/components/SuppliersFilterSourceField/SuppliersFilterSourceField.constants';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';
import { SuppliersSourceEnum } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';

export const SuppliersFilterSourceField = memo(() => {
  const { filter } = useSuppliersContext();
  const { setFilter } = useSuppliersActionsContext();

  const handleSourceChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      setFilter((prev) => ({
        ...prev,
        source: value as SuppliersSourceEnum,
      }));
    },
    [setFilter],
  );

  return (
    <FormFieldRow
      label="Источник"
      rowHeight={40}
      field={
        <Radio.Group
          value={filter.source}
          options={sourceFieldRadioOptions}
          onChange={handleSourceChange}
        />
      }
    />
  );
});

SuppliersFilterSourceField.displayName = 'SuppliersFilterSourceField';
