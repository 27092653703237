import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, Tooltip, TooltipProps } from 'antd';
import { NmckGridPropositionForm } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/components/NmckGridPropositionForm/NmckGridPropositionForm';
import Link from 'next/link';
import styles from './NmckGridPropositionDescription.module.scss';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import { Proposition, PropositionType, UpdatePropositionDTO } from '@/api/nmckApi/nmckApiTypes';

type Props = {
  proposition: Proposition;
  updateProposition: (
    body: Partial<Pick<UpdatePropositionDTO, 'price' | 'description' | 'forAllRows'>>,
  ) => Promise<void>;
};

export const NmckGridPropositionDescription = memo(({ proposition, updateProposition }: Props) => {
  const isEis = useMemo(() => proposition.type === PropositionType.EIS, [proposition.type]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [propositionForm, setPropositionForm] = useState<
    Pick<UpdatePropositionDTO, 'description' | 'forAllRows'>
  >({
    description: proposition.description,
    forAllRows: proposition.forAllRows,
  });
  const formattedDescription = proposition.description || `КП ${proposition.columnNumber}`;
  const eisLinkURL = `https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${
    proposition.contractNumber
  }`;

  const tooltipOverlayInnerStyle = useMemo(
    (): Required<TooltipProps>['overlayInnerStyle'] => ({
      width: !proposition.description ? 290 : 'auto',
    }),
    [proposition.description],
  );

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      setIsPopupOpen(open);

      const isFormChanged =
        propositionForm.description !== proposition.description ||
        propositionForm.forAllRows !== proposition.forAllRows;

      if (!open && isFormChanged) {
        await updateProposition(propositionForm);
      }
    },
    [propositionForm, proposition.description, proposition.forAllRows, updateProposition],
  );

  useEffect(() => {
    setPropositionForm({
      description: proposition.description,
      forAllRows: proposition.forAllRows,
    });
  }, [proposition]);

  return (
    <Tooltip
      title={
        proposition.description ||
        'Нажмите, чтобы ввести наименование поставщика / номер входящего КП'
      }
      overlayInnerStyle={tooltipOverlayInnerStyle}
      placement="bottom"
      arrow={false}
    >
      {isEis ? (
        <Link
          href={eisLinkURL}
          target="_blank"
          className={styles.supplier}
        >
          {proposition.description}
        </Link>
      ) : (
        <Popover
          arrow={false}
          placement="bottom"
          open={isPopupOpen}
          onOpenChange={handleOpenChange}
          trigger="click"
          content={
            <NmckGridPropositionForm
              proposition={proposition}
              propositionForm={propositionForm}
              setPropositionForm={setPropositionForm}
              togglePopoverVisible={handleOpenChange}
            />
          }
        >
          <div
            className={styles.supplier}
            onClick={preventEventPropagation}
          >
            {formattedDescription}
          </div>
        </Popover>
      )}
    </Tooltip>
  );
});

NmckGridPropositionDescription.displayName = 'NmckGridPropositionDescription';
