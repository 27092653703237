import { memo, useCallback } from 'react';
import { UiSwitch } from '@/components';
import { useSuppliersContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersContext';
import { useSuppliersActionsContext } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/context/useSuppliersActionsContext';
import { SwitchChangeEventHandler } from 'antd/es/switch';

export const SuppliersFilterSmpField = memo(() => {
  const { filter } = useSuppliersContext();
  const { setFilter } = useSuppliersActionsContext();

  const handleSmpChange = useCallback<SwitchChangeEventHandler>(
    (checked) => {
      setFilter((prev) => ({ ...prev, smp: checked }));
    },
    [setFilter],
  );

  return (
    <UiSwitch
      value={filter.smp}
      onChange={handleSmpChange}
    >
      <span>Только в реестре СМП</span>
    </UiSwitch>
  );
});

SuppliersFilterSmpField.displayName = 'SuppliersFilterSmpField';
