import { CSSProperties } from 'react';

export const printModalStyleSheetTag = `
<style>
  @media print {
    body {
      font-family: 'Arial', sans-serif;
    }
    thead {
    font-weight: bold;
    }
    td:nth-child(2) {
      width: 100px;
    }
    td, th {
      vertical-align: top;
      border-bottom: 1px solid #9ba0a7;
      padding: 8px;
    }
    thead {
      text-align: left;
    }
  }
</style>
`;

export const printModalIFrameStyles: CSSProperties = { width: 0, height: 0, border: 0 };
