import { TableProps, Typography } from 'antd';
import { ContractExecution } from '@/api/currentApi/currentApiTypes';
import styles from '@/widgets/CurrentPage/components/ContractsTable/ContractsTable.module.scss';
import moment from 'moment/moment';
import { dateFormat } from '@/constants';
import { formatPrice } from '@/utils/formatPrice';
import cx from 'classnames';
import { ContractsTableRegNumLink } from '@/widgets/CurrentPage/components/ContractsTable/components/ContractsTableRegNumLink/ContractsTableRegNumLink';

export const currentContractsTableColumns: TableProps<ContractExecution>['columns'] = [
  {
    title: 'Предмет контракта',
    dataIndex: 'predmet',
    key: 'predmet',
    width: 762,
    render: (_, row: ContractExecution) => <ContractsTableRegNumLink row={row} />,
  },
  {
    title: 'Дата заключения',
    dataIndex: 'signDate',
    key: 'signDate',
    width: 155,
    render: (_, { signDate }) => moment(signDate).format(dateFormat.frontFormat),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    width: 252,
    render: (_, { supplier }) => (
      <Typography.Paragraph
        ellipsis={{ rows: 2, tooltip: supplier }}
        className={styles.ellipsisParagraph}
      >
        {supplier}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Цена контракта, ₽',
    dataIndex: 'contractPrice',
    key: 'contractPrice',
    width: 160,
    className: styles.priceCol,
    render: (_, { contractPrice }) => formatPrice(contractPrice),
  },
  {
    title: 'Окончание этапа',
    dataIndex: 'stageEndDate',
    key: 'stageEndDate',
    width: 160,
    render: (_, { stageEndDate, expired }) => {
      return (
        <p className={cx({ [styles.expired]: expired })}>
          {moment(stageEndDate).format(dateFormat.frontFormat)}
        </p>
      );
    },
  },
  {
    title: 'Оплачено на сумму, ₽',
    dataIndex: 'paidSum',
    key: 'paidSum',
    width: 180,
    className: styles.priceCol,
    render: (_, { paidSum }) => formatPrice(paidSum),
  },
  {},
];
