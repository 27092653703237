import { memo, useCallback, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import {
  deleteNmckCompatibilityMedDeviceSelection,
  postNmckCompatibilityMedDeviceSelection,
  splitNmckById,
  updateCompatibilityCell,
  useGetNmckById,
  useGetNmckCompatibility,
} from '@/api/nmckApi/nmckApi';
import { BadgeType, NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { pageRoutes } from '@/app/pageRoutes';
import DivideIcon from '/public/icons/divide.svg';
import { Compatibility } from '@/components/Compatibility/Compatibility';
import { AsyncButton, UiModal } from '@/components';

type IncompatibleModalProps = {
  nmckId: NmckByIdResponse['id'];
  name: string;
  open: boolean;
  onCancel: () => void;
};

export const NmckCompatibilityModal = memo(({ open, onCancel, nmckId }: IncompatibleModalProps) => {
  const router = useRouter();
  const { data: nmckData } = useGetNmckById(nmckId);
  const { data, isLoading, mutate } = useGetNmckCompatibility(nmckId);

  const hasIncompatibleCode = nmckData?.badge === BadgeType.HAS_INCOMPATIBLE_CODES;
  const title = hasIncompatibleCode ? 'Несовместимые коды ОКПД2/КТРУ' : 'Ограничения и запреты';

  useEffect(() => {
    if (open) {
      mutate();
    }
  }, [mutate, open]);

  const handleSplit = useCallback(async () => {
    await splitNmckById(nmckId);
    router.push(pageRoutes.nmckHistory);
  }, [nmckId, router]);

  return (
    <UiModal
      title={title}
      open={open}
      onCancel={onCancel}
      width="95%"
      titleButtons={
        <AsyncButton
          type="primary"
          icon={<DivideIcon />}
          className="button-with-icon"
          onClick={handleSplit}
          disabled={!hasIncompatibleCode}
        >
          Разделить закупку
        </AsyncButton>
      }
    >
      <Compatibility
        id={nmckId}
        data={data}
        warning={hasIncompatibleCode}
        isLoading={isLoading}
        refreshTableData={mutate}
        onUpdateCell={updateCompatibilityCell}
        onCreateMedDeviceSelection={postNmckCompatibilityMedDeviceSelection}
        onDeleteMedDeviceSelection={deleteNmckCompatibilityMedDeviceSelection}
        withVerticalScroll
      />
    </UiModal>
  );
});

NmckCompatibilityModal.displayName = 'NmckCompatibilityModal';
