import { memo, useCallback, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import styles from './CompatibilityTableRemoveCell.module.scss';
import { CompatibilityRow } from '@/api/compatibilityApi/compatibilityApiTypes';
import TrashIcon from 'public/icons/trash.svg';
import { tooltipOverlayInnerStyleForShortTitle } from '@/constants/ui';
import { CompatibilityTableProps } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable.types';

type Props = {
  row: CompatibilityRow;
} & Required<Pick<CompatibilityTableProps, 'onDeleteRow'>>;

export const CompatibilityTableRemoveCell = memo(({ row, onDeleteRow }: Props) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const handleDeleteButtonClick = useCallback(async () => {
    setIsDeleteLoading(true);
    try {
      await onDeleteRow(row.id);
    } finally {
      setIsDeleteLoading(false);
    }
  }, [onDeleteRow, row.id]);

  return (
    <Flex
      align="center"
      gap={8}
      justify="end"
    >
      <Tooltip
        title="Удалить позицию"
        overlayInnerStyle={tooltipOverlayInnerStyleForShortTitle}
        placement="bottom"
      >
        <Button
          icon={<TrashIcon />}
          className={styles.removeButton}
          type="link"
          size="small"
          loading={isDeleteLoading}
          onClick={handleDeleteButtonClick}
        />
      </Tooltip>
    </Flex>
  );
});

CompatibilityTableRemoveCell.displayName = 'CompatibilityTableRemoveCell';
