import { memo, useCallback } from 'react';
import { ReportFormModal, ReportFormModalProps } from '@/features';
import { postUserFeedback } from '@/api/userApi/userApi';
import { OLD_VERSION_REPORT_MODAL_CONFIG } from '@/features/OldVersionReport/components/OldVersionReportModal/OldVersionReportModal.constants';
import { redirectToOldVersion } from '@/features/OldVersionReport/OldVersionReport.utils';

type Props = Pick<ReportFormModalProps, 'isOpen' | 'onClose'>;

export const OldVersionReportModal = memo(({ isOpen, onClose }: Props) => {
  const handleSubmit = useCallback<ReportFormModalProps['onSubmit']>(
    async ({ email, feedback }) => {
      await postUserFeedback({
        email,
        feedback,
        oldVersionTransition: true,
      });
      redirectToOldVersion();
      onClose();
    },
    [onClose],
  );

  return (
    <ReportFormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      config={OLD_VERSION_REPORT_MODAL_CONFIG}
    />
  );
});

OldVersionReportModal.displayName = 'OldVersionReportModal';
