import { Button, Flex, Input, Select } from 'antd';
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  ChangeEvent,
} from 'react';
import moment, { Moment } from 'moment';
import Vicon from 'public/icons/v.svg';
import Calendar from 'public/icons/calendar.svg';
import { dateFormat } from '@/constants';
import styles from './NewPurchaseModal.module.scss';
import { formatDate } from '@/utils';
import { Option } from '@/types/types';
import { AsyncButton, UiDatePicker, UiInputNumber, UiModal } from '@/components';
import { createNewPlanningPurchase } from '@/api/planningAllApi/planningAllApi';
import { NewPurchaseBody } from '@/api/planningAllApi/planningAllApiTypes';
import { pick } from 'lodash';

type NewPurchaseModalProps = {
  isOpenModal: boolean;
  closeModal: (e: MouseEvent) => void;
  refreshPurchases?: () => void;
  setNewPurchaseId?: Dispatch<SetStateAction<number | undefined>>;
};

const selectStatusOptions: Option[] = [
  { label: 'Новая', value: 'NEW' },
  { label: 'В работе', value: 'IN_PROGRESS' },
];

const initialState: NewPurchaseBody = {
  number: null,
  status: 'NEW',
  supplyDate: null,
  amount: null,
  financeSource: '',
  divisionName: '',
  responsible: '',
  name: '',
  comment: '',
  totalSum: null, // TODO: выпилить после бэковой задачи SARK-415
};

export const NewPurchaseModal = memo(
  ({ isOpenModal, closeModal, refreshPurchases, setNewPurchaseId }: NewPurchaseModalProps) => {
    const [modalState, setModalState] = useState(initialState);

    useEffect(() => {
      if (isOpenModal) {
        setModalState(initialState);
      }
    }, [isOpenModal]);

    const handleInputChange = useCallback((field: keyof typeof modalState, value: string) => {
      setModalState((prev) => ({ ...prev, [field]: value || null }));
    }, []);

    const inputNumberChangeHandlers = useMemo(() => {
      const fieldsWithNumberValue = pick(modalState, ['number', 'amount']);
      return Object.keys(fieldsWithNumberValue).reduce(
        (acc, key) => {
          acc[key as keyof typeof modalState] = (value: number | null) =>
            setModalState((prev) => ({
              ...prev,
              [key]: value,
            }));
          return acc;
        },
        {} as { [key in keyof NewPurchaseBody]: (value: number | null) => void },
      );
    }, [modalState]);

    const inputChangeHandlers = useMemo(
      () =>
        Object.keys(modalState).reduce(
          (acc, key) => {
            acc[key as keyof typeof modalState] = (e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(key as keyof typeof modalState, e.target.value);
            return acc;
          },
          {} as {
            [key in keyof NewPurchaseBody]: (e: ChangeEvent<HTMLInputElement>) => void;
          },
        ),
      [modalState, handleInputChange],
    );

    const handleStatusChange = useCallback((value: string) => {
      setModalState((prev) => ({ ...prev, status: value }));
    }, []);

    const supplyDateChange = useCallback((value: Moment | null) => {
      if (value) {
        setModalState((prev) => ({
          ...prev,
          supplyDate: formatDate(value, dateFormat.serverFormat),
        }));
      } else {
        setModalState((prev) => ({
          ...prev,
          supplyDate: null,
        }));
      }
    }, []);

    const save = useCallback(
      async (e: MouseEvent) => {
        const newPurchase = await createNewPlanningPurchase(modalState);
        refreshPurchases && refreshPurchases();
        setNewPurchaseId && setNewPurchaseId(newPurchase.id);
        closeModal(e);
      },
      [closeModal, modalState, refreshPurchases, setNewPurchaseId],
    );

    return (
      <UiModal
        open={isOpenModal}
        width="90%"
        title="Новая заявка"
        footer={
          <>
            <Button
              type="text"
              onClick={closeModal}
            >
              Отмена
            </Button>
            <AsyncButton
              type="primary"
              onClick={save}
            >
              Сохранить
            </AsyncButton>
          </>
        }
        onCancel={closeModal}
      >
        <div className={styles.content}>
          <Flex
            align="center"
            gap={64}
          >
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Номер заявки:</div>
              <UiInputNumber
                value={modalState.number}
                onChange={inputNumberChangeHandlers['number']}
                className={styles.input}
                placeholder="Не указан"
              />
            </Flex>
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.textLong}>Источник финансирования:</div>
              <Input
                value={modalState.financeSource}
                onChange={inputChangeHandlers['financeSource']}
                className={styles.inputLong}
                placeholder="Не указан"
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={64}
          >
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Статус:</div>
              <Select
                labelInValue
                defaultValue={selectStatusOptions[0].value}
                onChange={handleStatusChange}
                className={styles.select}
                placeholder="Не выбран"
                options={selectStatusOptions}
                suffixIcon={<Vicon className="v-icon" />}
              />
            </Flex>
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.textLong}>Наименование подразделения:</div>
              <Input
                value={modalState.divisionName}
                onChange={inputChangeHandlers['divisionName']}
                className={styles.inputLong}
                placeholder="Не указано"
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={64}
          >
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Срок поставки:</div>
              <UiDatePicker
                value={modalState.supplyDate ? moment(modalState.supplyDate) : null}
                onChange={supplyDateChange}
                className={styles.datePicker}
                placeholder="Не выбран"
                suffixIcon={<Calendar />}
              />
            </Flex>
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.textLong}>Исполнитель:</div>
              <Input
                value={modalState.responsible}
                onChange={inputChangeHandlers['responsible']}
                className={styles.inputLong}
                placeholder="Не указан"
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={64}
          >
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Сумма по заявке, ₽:</div>
              <UiInputNumber
                value={modalState.amount}
                onChange={inputNumberChangeHandlers['amount']}
                className={styles.input}
                placeholder="Не указана"
              />
            </Flex>
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.textLong}>Наименование товара, работы, услуги:</div>
              <Input
                value={modalState.name}
                onChange={inputChangeHandlers['name']}
                className={styles.inputLong}
                placeholder="Не указан"
              />
            </Flex>
          </Flex>

          <Flex
            align="center"
            gap={32}
          >
            <div className={styles.text}>Примечание:</div>
            <Input
              value={modalState.comment}
              onChange={inputChangeHandlers['comment']}
              className={styles.inputLong}
              placeholder="Не указано"
            />
          </Flex>
        </div>
      </UiModal>
    );
  },
);
NewPurchaseModal.displayName = 'NewPurchaseModal';
