'use client';

import { memo, useContext } from 'react';
import { ReestrDirectPageHeader, ReestrDirectPageResults } from '@/widgets/ReestrDirect/components';
import { useGetDirectContracts } from '@/api/reestrDirectApi/reestrDirectApi';
import { ReestrDirectContext } from './context/context';

export const ReestrDirectPage = memo(() => {
  const { state } = useContext(ReestrDirectContext);
  const {
    data: contracts,
    isLoading: isContractsLoading,
    mutate: refreshContracts,
  } = useGetDirectContracts(state); // TODO: Лучше перенести вызов хука внутрь дочерних компонентов

  return (
    <>
      <ReestrDirectPageHeader
        filtersResponse={contracts?.filters}
        isContractsLoading={isContractsLoading}
        refreshContracts={refreshContracts}
      />
      <ReestrDirectPageResults
        contracts={contracts}
        isContractsLoading={isContractsLoading}
        refreshContracts={refreshContracts}
      />
    </>
  );
});
ReestrDirectPage.displayName = 'ReestrDirectPage';
