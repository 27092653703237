import { GpbTokenUpdateBody, TokenUpdateResponse, User } from '@/api/userApi/userApiTypes';
import { getData, postData } from '@/api/commonApi/commonApi';
import { saveGPBToken, saveUserIdToStorage } from '@/api/userApi/userApiUtils';
import { saveLogMetricsFlagToStorage } from '@/metrika';
import { ReportFormState } from '@/features';
import useSWRImmutable from 'swr/immutable';
import { usePathname } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';

export const useGetUserInfo = () => {
  const pathname = usePathname();
  const shouldFetch = !pathname.startsWith(pageRoutes.etpSignIn);

  return useSWRImmutable(shouldFetch ? '/current-user-info' : null, getData<User>);
};

export const updateToken = async (body: GpbTokenUpdateBody) => {
  const { token, logMetrics, userId } = await postData<TokenUpdateResponse>(
    '/token-from-gpb-creds',
    body,
  );
  saveGPBToken(token);
  saveLogMetricsFlagToStorage(logMetrics);
  saveUserIdToStorage(userId);
};

export const postUserFeedback = ({
  email,
  feedback,
  oldVersionTransition,
}: ReportFormState & { oldVersionTransition?: boolean }) => {
  return postData<void>('/user-feedback', {
    email,
    feedback,
    oldVersionTransition,
  });
};
